import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// import GetStartedField from './GetStartedField'
import GoogleSignInButton from '../buttons/GoogleSignInButton'
import FacebookSignInButton from '../buttons/FacebookSignInButton'
import AppleSignInButton from '../buttons/AppleSignInButton'
import Cookies from 'universal-cookie'
import ReactPixel from 'react-facebook-pixel'
import { config } from '../../config.js'

const cookies = new Cookies();

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type || 'signup',
      submittedSigninDialog: false,
      finishedRegistration: false,

      // signin / signup details
      email: "",
      firstName: "",
      lastName: "",
      promotion_code: "",

      passcode: "",
      error: null,
      locationFrom: (this.props.locationFrom ? this.props.locationFrom.replace(/,/g, ', ').replace(/-/g, ' ') : 'Current Location'),
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        type: this.props.type,
      })
    }
    if (prevProps.locationFrom !== this.props.locationFrom) {
      this.setState({
        locationFrom: (this.props.locationFrom ? this.props.locationFrom.replace(/,/g, ', ').replace(/-/g, ' ') : 'Current Location'),
      })
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value, error: null});
  }

  handleSignup = () => {
    if (this.isValidEmail()) {
      let user = {
        email: this.state.email.toLowerCase(),
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        currency: (cookies.get('curr') ? cookies.get('curr') : 'USD'),
      }

      if (this.props.user && this.props.user.travel_preference) {
        user["travel_preference_attributes"] = this.props.user.travel_preference
      }

      fetch(config.api.TRAVEL_URL + '/users', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        dataType: 'json',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({
          user: user
        })
      }).then(response => response.json())
      .then(data => {
        window.gtag('event', 'sign_up')
        ReactPixel.track('CompleteRegistration', { content_name: 'signup' })

        this.setState({
          submittedSigninDialog: true,
          // finishedRegistration: true,
        });

        // sign in user after sign up
        this.requestOneTimePassword()
      })
    }
  }

  requestOneTimePassword = () => {
    if (this.isValidEmail()) {
      fetch(config.api.TRAVEL_URL + '/users/auth/otp/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        dataType: 'json',
        body: JSON.stringify({
          user: {
            email: this.state.email.toLowerCase(),
          }
        })
      })
      .then(response => response.json())
      .then(data => {
        if(data.next_step === "signin") {
          this.setState({
            submittedSigninDialog: true,
            type: 'signin',
          });
        } else if (data.next_step === "signup") {
          this.setState({
            submittedSigninDialog: true,
            type: 'signup',
          });
        }
      });
    } else {
      this.setState({error: "Enter valid email"})
    }
  }

  handleSignin = () => {
    this.setState({isSubmitting: true})
    fetch(config.api.TRAVEL_URL + '/users/sign_in', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      dataType: 'json',
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify({
        user: {
          email: this.state.email.toLowerCase(),
          password: this.state.passcode,
        }
      })
    })
    .then(response => {
      if (response.status === 401) {
        cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
        this.setState({error: "Invalid passcode"})
      }
      return response.json()
    })
    .then(data => {

      if (data.jwt) {
        cookies.set('jwt', data.jwt, {
          path: '/',
          domain: config.api.DOMAIN,
          expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        });

        if (this.props.onSignin !== undefined) {
          this.changeType()
          this.props.onSignin(data.user)
        }

        this.setState({isSubmitting: false})

      } else {
        cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
        this.setState({isSubmitting: false})
      }
    })
  }

  handleThirdPartySignin = () => {
    let jwt = cookies.get('jwt')

    if (jwt) {
      fetch(config.api.TRAVEL_URL + '/users/me', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        dataType: 'json'})
      .then(response => {
        if (response.status === 401) {
          cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
        }
        return response.json()
      })
      .then(data => {
        if (this.props.onSignin !== undefined) {
          // after signup
          if (data.new_user) {
            window.gtag('event', 'sign_up')
            ReactPixel.track('CompleteRegistration')
          }

          this.changeType()
          this.props.onSignin(data)
        }
      })
    }
  }

  changeType = (type = 'signup') => {
    this.setState({
      submittedSigninDialog: false,
      finishedRegistration: false,
      type: type,
      error: null,
      email: "",
      passcode: "",
    });
  };

  isValidEmail = () => {
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(this.state.email) ) {
      return true
    }
    else {
      return false
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>

        {this.state.type === 'signup' &&
          <Fragment>
            {!this.state.submittedSigninDialog ?
              <Fragment>
                {!this.props.hideHeader && <h2 className={classes.headline}>
                  <p style={{marginBottom: 10}}>{this.props.title ? this.props.title : "Sign up or Log in"}</p>
                  <small className={classes.subtitle} style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'0px', display: 'block', lineHeight: 2}}>
                  {this.props.subtitle ? this.props.subtitle : "Welcome to Travelities"}<br/>
                  </small>
                  {/* <small className={classes.subtitle} style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'12px 0px 0px0px', display: 'block', lineHeight: 2}}>
                    <Grid container>
                      <Grid item xs={1} style={{padding: '5px 0px 5px 0px'}}><Icon className={classes.discoverIcon}>done</Icon></Grid><Grid item xs={11} style={{padding: '5px 0px 5px 7px'}}> <span>Access self guided tours</span></Grid>
                      <Grid item xs={1} style={{padding: '5px 0px 5px 0px'}}><Icon className={classes.discoverIcon}>done</Icon></Grid><Grid item xs={11} style={{padding: '5px 0px 5px 7px'}}> <span>Discover destinations that match your travel style</span></Grid>
                      <Grid item xs={1} style={{padding: '5px 0px 5px 0px'}}><Icon className={classes.discoverIcon}>done</Icon></Grid><Grid item xs={11} style={{padding: '5px 0px 5px 7px'}}> <span>Use advanced filters while exploring</span></Grid>
                    </Grid>
                  </small> */}
                </h2>}
                {/* <GetStartedField
                  locationFrom={(this.props.locationFrom ? this.props.locationFrom.replace(/,/g, ', ').replace(/-/g, ' ') : 'Current Location')}
                  campaign={'discover-weekly'}
                  align={'center'}
                  onSubmit={this.handleSignup}
                /> */}
                <div style={{textAlign: 'center'}}>
                  <GoogleSignInButton onSignin={this.handleThirdPartySignin} />
                  {/* <FacebookSignInButton onSignin={this.handleThirdPartySignin} /> */}
                  {/* <AppleSignInButton onSignin={this.handleThirdPartySignin} /> */}
                  <Divider style={{margin: '40px auto 0px auto', width: '90%', maxWidth: 393}}/>
                  <div style={{margin: '-21px auto 0px auto', padding: 10, textAlign: 'center', color: '#999', background: '#fff', width: 30, fontSize: '15px'}}>OR</div>
                </div>
                <div style={{textAlign: 'center'}}>
                  <TextField
                    autoFocus={false}
                    name="email"
                    label=""
                    placeholder="Enter email address"
                    helperText=""
                    variant="outlined"
                    margin="dense"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className={classes.textfield}
                  />

                  {this.state.error && <Typography style={{fontSize: '12px', color: 'red', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'left'}}>
                    {this.state.error}
                  </Typography>}
                  <Button variant="contained" color="primary" onClick={this.requestOneTimePassword} disableElevation className={classes.button} alt="Send Button" aria-label="Send Button">
                    Continue
                  </Button>
                </div>


                {/* <Typography component="p" style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'left'}}>
                  Do you already have an account ? <Button variant="text" color="primary" className={classes.btnLink} onClick={this.changeType.bind(this, 'signin')}>Sign in</Button>
                </Typography> */}

              </Fragment>
            :
              !this.state.finishedRegistration ? <Fragment>
                  {!this.props.hideHeader && <h2 className={classes.headline}>
                    <p style={{marginBottom: 0}}>Finish sign up</p>
                    <small className={classes.subtitle} style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'0px', display: 'block', lineHeight: 2}}>
                      Enter your first name, last name and email<br/>
                    </small>
                  </h2>}
                  <Grid container spacing={2} style={{maxWidth: 414, textAlign: 'center', margin: 'auto'}}>
                    <Grid item xs={6} style={{marginLeft: -10}}>
                      <TextField
                        autoFocus={false}
                        name="firstName"
                        label=""
                        placeholder="First name"
                        helperText=""
                        variant="outlined"
                        margin="dense"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus={false}
                        name="lastName"
                        label=""
                        placeholder="Last name"
                        helperText=""
                        variant="outlined"
                        margin="dense"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        className={classes.textfield}
                        style={{width: '87%'}}
                      />
                    </Grid>
                    <Grid item xs={12} style={{marginLeft: -10}}>
                      <TextField
                        autoFocus={false}
                        name="email"
                        label=""
                        placeholder="Enter email address"
                        helperText=""
                        variant="outlined"
                        margin="dense"
                        value={this.state.email}
                        onChange={this.handleChange}
                        className={classes.textfield}
                      />
                    </Grid>

                    {/* <Divider className={classes.divider}/>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus={false}
                        name="promotion_code"
                        label=""
                        placeholder="Use Promotion Code"
                        helperText=""
                        variant="outlined"
                        margin="dense"
                        value={this.state.promotion_code}
                        onChange={this.handleChange}
                        className={classes.textfield}
                      />
                    </Grid>
                    <Grid item xs={6} style={{padding: '5px 0px 5px 7px'}}> <strong>Premium</strong></Grid>
                    <Grid item xs={6} style={{padding: '5px 0px 5px 7px'}}> <strong>$ 19</strong> <span>a month</span></Grid>
                    <Grid item xs={12} style={{padding: '5px 0px 5px 7px'}}> <Icon className={classes.discoverIcon}>done</Icon><span>Less than price of 1 itinerary (typically $ 30)</span></Grid>
                    <Grid item xs={12} style={{padding: '5px 0px 5px 7px'}}> <Icon className={classes.discoverIcon}>done</Icon><span>7-day money back guarantee, cancel anytime</span></Grid> */}

                  </Grid>
                  <div style={{textAlign: 'center'}}>
                    {this.state.error && <Typography style={{fontSize: '12px', color: 'red', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'center'}}>
                      {this.state.error}
                    </Typography>}
                    <Button variant="contained" color="primary" onClick={this.handleSignup} disableElevation className={classes.button} alt="Send Button" aria-label="Send Button">
                      Continue
                    </Button>
                  </div>
                </Fragment>
              :
              <div style={{margin: '150px 0px 0px 0px'}}>
                <div className={classes.thankyouIcon}><img src="/check-icon.svg" width="26" alt="icon check"/></div>
                <h2 className={classes.headline} style={{textAlign: 'center', margin: '12px'}}>
                  <div>
                    Welcome to Travelities
                  </div>
                  <small className={classes.subtitle}>
                    Now you have successfully joined
                  </small>
                  <Button variant="contained" color="primary" size="large" className={classes.btnBook} onClick={this.changeType.bind(this, 'signin')}>Sign in</Button>
                </h2>
              </div>
            }
          </Fragment>
        }

        {this.state.type === 'signin' &&
          <Fragment>
            {!this.state.submittedSigninDialog ?
              <Fragment>
                {!this.props.hideHeader && <h2 className={classes.headline}>
                  <p style={{marginBottom: 0}}>Welcome back</p>
                  <small className={classes.subtitle} style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'0px', display: 'block', lineHeight: 2}}>
                    Sign in to your account<br/>
                  </small>
                </h2>}
                {/* <GetStartedField
                  locationFrom={(this.props.locationFrom ? this.props.locationFrom.replace(/,/g, ', ').replace(/-/g, ' ') : 'Current Location')}
                  campaign={'discover-weekly'}
                  align={'center'}
                  onSubmit={this.handleSignup}
                /> */}
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus={false}
                      name="email"
                      label=""
                      placeholder="Enter email address"
                      helperText=""
                      variant="outlined"
                      margin="dense"
                      value={this.state.email}
                      onChange={this.handleChange}
                      className={classes.textfield}
                    />
                    {this.state.error && <Typography style={{fontSize: '12px', color: 'red', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'left'}}>
                      {this.state.error}
                    </Typography>}
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={this.requestOneTimePassword} disableElevation className={classes.button} alt="Send Button" aria-label="Send Button">
                      Continue
                    </Button>
                  </Grid>
                </Fragment>
                {/* <Typography style={{fontSize: '12px', color: 'rgba(130, 130, 130, 0.9)', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'left'}}>
                  Don't you have an account ? <Button variant="text" color="primary" className={classes.btnLink} onClick={this.changeType.bind(this, 'signup')}>Create account</Button>
                </Typography> */}
              </Fragment>
            :
              <Fragment>
                {!this.props.hideHeader && <h2 className={classes.headline}>
                  <p style={{marginBottom: 0}}>Sign in</p>
                  <small className={classes.subtitle} style={{fontSize: '12px', color: '#333', margin:'0px', display: 'block', lineHeight: 2}}>
                    We sent you 6-digit passcode to your email address: <strong style={{fontWeight: '800'}}>{this.state.email}</strong><br/>
                  </small>
                </h2>}
                <Fragment>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    {this.props.hideHeader && <small className={classes.subtitle} style={{fontSize: '12px', color: '#333', margin:'0px', display: 'block', lineHeight: 2}}>
                      We sent you 6-digit passcode to your email address: <strong style={{fontWeight: '800'}}>{this.state.email}</strong><br/>
                    </small>}
                    <Button className={classes.buttonChange} style={{textDecoration: 'underline', display: 'block', margin: '-20px auto 10px auto'}} onClick={() => this.changeType()}>Change email</Button>
                    <TextField
                      autoFocus={false}
                      autoComplete='off'
                      name="passcode"
                      label=""
                      placeholder="000000"
                      helperText=""
                      variant="outlined"
                      margin="dense"
                      inputProps={{ maxLength: 6 }}
                      value={this.state.passcode}
                      onChange={this.handleChange}
                      className={`${classes.textfield} ${classes.passcode}`}
                    />
                    {this.state.error && <Typography style={{fontSize: '12px', color: 'red', margin:'10px 0px 0px 10px', display: 'block', lineHeight: 2, fontWeight: 600, textAlign: 'center'}}>
                      {this.state.error}
                    </Typography>}
                  </Grid>
                  <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button disabled={this.state.passcode.length !== 6 || this.state.isSubmitting} variant="contained" color="primary" onClick={this.handleSignin} disableElevation className={classes.button} alt="Send Button" aria-label="Send Button">
                      {this.state.isSubmitting ?
                        <Fragment>Signing in...</Fragment>
                        :
                        <Fragment>Continue</Fragment>
                      }
                    </Button>
                  </Grid>
                </Fragment>
              </Fragment>
              // <div style={{margin: '150px 0px 0px 0px'}}>
              //   <p className={classes.thankyouIcon}><img src="/check-icon.svg" width="26" alt="icon check"/></p>
              //   <h2 className={classes.headline} style={{textAlign: 'center', margin: '12px'}}>
              //     <p>
              //       Check you inbox!
              //     </p>
              //     <small className={classes.subtitle}>
              //       We sent you sign in link to your email address.
              //     </small>
              //   </h2>
              // </div>
            }
          </Fragment>
        }

      </Fragment>
    )
  }
}

const styles = theme => ({
  dialogRoot: {
    backgroundColor: '#fafafa',
    // backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  dialogTitle: {
    float: 'left',
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 'inherit',
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      width: '87%',
    },
  },
  dialogContentText: {
    paddingLeft: 50,
    paddingBottom: 30,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: 30,
      overflowX: 'hidden',
    },

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      overflowX: 'hidden',
    },
  },
  closeButton: {
    float: 'right',
  },
  dialogImage: {
    backgroundImage: 'url(/green-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    margin: '-9px -24px -31px 0px',
    padding: '94px 30px 19px 30px',

    '& > img': {
      margin: 'auto',
      display: 'block',
      verticalAlign: 'middle',
      position: 'relative',
      maxWidth: 500,
    },

    [theme.breakpoints.down('sm')]: {
      margin: '0px -24px -50px -49px',
      padding: '10px 30px 10px 70px',
      backgroundImage: 'none',
      display: 'none'
    },
  },
  headline: {
    textAlign: 'left',
    lineHeight: 1.67,
    color: '#303030',
    fontSize: '27px',
    fontWeight: 800,

    [theme.breakpoints.up('md')]: {
      margin: '31px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-40px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-31px 0px 30px 0px',
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '14px !important',
    // fontSize: '12px',
    color: '#797979',
    display: 'block',
    marginBottom: '20px !important',
  },
  discoverIcon: {
    verticalAlign: 'middle',
    color: '#02c39a',
    marginRight: 8,
  },
  thankyouIcon: {
    verticalAlign: 'middle',
    padding: '10px',
    backgroundColor: '#02c39a',
    width: 36,
    height: 36,
    borderRadius: 50,
    margin: '5px auto 5px auto',

    '& > img': {
      margin: '6px 0px 0px 3px',
    },
  },
  btnLink: {
    borderRadius: 25,
    color: '#02c39a',
    fontWeight: 600,
    marginTop: -3,
  },
  btnBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px 0px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '13px 35px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  textfield: {
    backgroundColor: '#fff',
    marginTop: 5,
    width: '94%',
    maxWidth: 375,
    borderRadius: 50,
    borderWidth: 1,
    padding: 9,
    boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: 500,
    border: '1px solid #ddd',

    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
      fontWeight: 500,
    },

    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
  },
  passcode: {
    '& input': {
      letterSpacing: 40,
      marginLeft: 20,
      border: 0,
      backgroundPosition: 'bottom',
      backgroundSize: '50px 1px',
      backgroundRepeat: 'repeat-x',
      backgroundPositionX: 35,
      fontWeight: 700,
    },
  },
  button: {
    width: '100%',
    maxWidth: 393,
    borderRadius: 50,
    borderWidth: 1,
    padding: 12,
    color: '#fff',
    backgroundColor: '#02c39a',
    marginTop: 14,
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: 15,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
  buttonChange: {
    zIndex: 2,
    margin: '0px 0px 0px 0px',
    textTransform: 'none',
    borderRadius: 25,
    padding: '10px 12px',
    fontWeight: 600,

    '&:hover': {
      boxShadow: 'none',
    }
  },
  buttonIcon: {
    fontSize: 16,
  },
  divider: {
    width: '100%',
    margin: '10px 0px 6px 0px',
    backgroundColor: '#eee',
  },
})

export default withRouter(withStyles(styles)(withWidth()(SignupForm)))
