import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { startCase } from 'lodash'
import {
  AppBar,
  Avatar,
  Box,
  BottomNavigationAction,
  BottomNavigation,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Icon,
  IconButton,
  InputBase,
  Badge,
  Drawer,
  Toolbar,
  Select,
  Snackbar,
  withStyles,
  withWidth,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
// import GetStartedField from './forms/GetStartedField'
// import SignupFloatingBar from './forms/SignupFloatingBar'
import SignupDialog from './forms/SignupDialog'
import Cookies from 'universal-cookie'
import { config } from '../config.js'
import { CURRENCIES } from '../utils/currency.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

function LinkTab(props) {
  return (
    <Tab
      component="a"
      classes={props.classes}
      {...props}
    />
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

class AppTopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      tripCount: 0,
      dialogType: 'signup',
      openedSigninDialog: false,
      openedMobileMenu: false,
      notification: null,
      isProfileMenuOpen: false,
      anchorEl: null,
    }
  }

  handleHomeRedirect = () => {
    // this.props.onLocationCleared()
    // this.setState({
    // }, this.props.history.push('/'))

    this.props.history.push('/')
  }

  componentDidMount() {
    if (!this.state.isLoading) {
      // this.fetchDestination(this.props.locationTo)
      // this.fetchTripsCount()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openedSigninDialog !== this.props.openedSigninDialog) {
      this.setState({
        openedSigninDialog: this.props.openedSigninDialog,
      })
    }
    if (prevProps.signinDialogType !== this.props.signinDialogType) {
      this.setState({
        dialogType: this.props.signinDialogType,
      })
    }
  }

  fetchTripsCount() {
    this.setState({isLoading: true})

    fetch(API + `/trips/count`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
      }
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        tripCount: data.count,
        isLoading: false,
      })
    })
  }

  handleMenuOpen = () => {
    this.setState({openedMobileMenu: true});
  }

  handleMenuClose = () => {
    this.setState({openedMobileMenu: false});
  }

  handleClickBack = () => {
    if (this.props.backButtonPath) {
      this.props.history.push(this.props.backButtonPath)
      // } else if (document.referrer === undefined || document.referrer === "") {
      //   this.props.history.push('/')
      // } else if (document.referrer.length > 0 && (document.referrer.startsWith('https://app.travelities.com') || document.referrer.startsWith('https://www.travelities.com') || document.referrer.startsWith('http://localhost'))) {
      //   this.props.history.goBack()
    } else {
      this.props.history.goBack()
    }
  }

  handleSignupOpen = (dialogType) => {
    // console.log(dialogType)
    // console.log(this.state.dialogType)

    this.setState({
      dialogType: dialogType,
      openedSigninDialog: true
    });
  };

  handleSignupClose = (state) => {
    this.setState({
      openedSigninDialog: state
    });

    if (this.props.onSigninModalChanged !== undefined) {
      this.props.onSigninModalChanged(state, 'signup')
    }
  };

  handleSignOut = () => {
    fetch(config.api.TRAVEL_URL + '/users/sign_out', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
      },
    })

    cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
    this.setState({
      notification: "You've been signed out",
    })

    if (this.props.onSignin !== undefined) {
      this.props.onSignin({user: { is_registered: false}})
      this.handleHomeRedirect()
    }
  };

  handleProfileMenuClose = (event) => {
    this.setState({isProfileMenuOpen: false, anchorEl: null})
  }

  handleProfileMenuOpen = (event) => {
    this.setState({isProfileMenuOpen: true, anchorEl: event.currentTarget})
  }

  handleProfileMenuTest = (event) => {
    this.setState({isProfileMenuOpen: false, anchorEl: null})
    this.props.history.push('/user/preferences')
  }

  handleSignin = (user) => {
    this.setState({notification: "You've been signed in"})
    this.props.onSignin(user)
  }

  handleCurrencyChange = (user, event) => {
    user.currency = event.target.value
    this.props.onSignin(user)

    cookies.set('curr', user.currency, {
      path: '/',
      domain: config.api.DOMAIN,
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    })

    if (this.props.onCurrencyChange !== undefined) {
      this.props.onCurrencyChange()
    }

    if (cookies.get('jwt') !== undefined && cookies.get('jwt') !== null) {
      fetch(config.api.TRAVEL_URL + '/users/me', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookies.get('jwt')}`,
        },
        dataType: 'json',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({
          currency: event.target.value
        })
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { isProfileMenuOpen } = this.state
    const profileMenuId = 'profile-menu';

    return (
      <div className="layout">
        <AppBar position="static" className={classes.appbar}>
          <Toolbar variant="dense" className={classes.toolbar}>
            <Box className={classes.logo}>
              {this.props.showBackButton && <IconButton aria-label="Go back" onClick={this.handleClickBack} className={classes.backIcon}><Icon className="notranslate">chevron_left</Icon></IconButton>}
              {!this.props.hideLogo && <span style={{cursor: 'pointer'}} className="notranslate" onClick={this.handleHomeRedirect}>
                {/* <img src="/apple-touch-icon.png" height="32" width="32" style={{verticalAlign: 'middle', marginRight: 10}}/> */}
                Travelities
              </span>}
            </Box>

            {this.props.showCloseButton ?
              <Fragment>
                <Button variant="contained" className={classes.buttonClose} onClick={() => this.props.history.push('/trips') }>
                  <Icon className="material-icons-outlined notranslate" style={{marginRight: 5}}>close</Icon>
                  Close
                </Button>
              </Fragment>
            :
              <Fragment>
              {!this.props.hideMenu && <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={this.handleMenuOpen} className={classes.menuIcon}>
                  <Icon  className="notranslate">menu</Icon>
                </IconButton>}
              {!this.props.user.is_registered ?
                <Fragment>
                  <Button variant="text" color="primary" disableElevation className={classes.btnSignin} onClick={this.handleSignupOpen.bind(this, 'signup')}>
                    <Icon className="material-icons-outlined notranslate" style={{marginRight: 5}}>account_circle</Icon>
                    Sign in
                  </Button>
                  {/* <Button variant="text" color="primary" disableElevation className={classes.btnSignin} onClick={this.handleSignupOpen.bind(this, 'signin')}>Sign in</Button> */}
                </Fragment>
                :
                <Fragment>
                  {/* <Button variant="contained" color="default" disableElevation className={classes.btnSignout} onClick={this.handleSignOut}>Sign Out</Button> */}
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={profileMenuId}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    onClose={this.handleProfileMenuClose}
                    className={classes.profileBox}
                    color="inherit">
                      <Icon className={`${classes.profileMenuIcon} material-icons-outlined notranslate`}>menu</Icon>
                      <Avatar alt="Profile" src={this.props.user.image} width={32} height={32} style={{width: 32, height: 32, marginRight: -5}}/>
                  </IconButton>
                  <Menu
                    anchorEl={this.state.anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    id={profileMenuId}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={isProfileMenuOpen}
                    onClose={this.handleProfileMenuClose}
                    classes={{paper: classes.profileMenu}}
                  >
                    <MenuItem disabled>
                      <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">account_circle</Icon></ListItemIcon>
                      <ListItemText primary={this.props.user.first_name ? `${this.props.user.first_name}` : 'Account'} secondary={this.props.user.email} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
                    </MenuItem>
                    {/* <MenuItem onClick={(event) => {this.handleProfileMenuClose(event); this.props.history.push('/trips')}}>
                      <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">luggage</Icon></ListItemIcon>
                      <ListItemText primary={'Trips'} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}/>
                    </MenuItem> */}
                    {/* <MenuItem component="a" href={'https://billing.stripe.com/p/login/dR6eV3h198NZ5QQ9AA'} target="_blank" onClick={(event) => {this.handleProfileMenuClose(event)}}>
                      <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">payment</Icon></ListItemIcon>
                      <ListItemText primary={'Billing'} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}/>
                    </MenuItem>
                    {this.props.user.subscription && this.props.user.subscription.plan === "free" ?
                      <MenuItem onClick={(event) => { this.handleProfileMenuClose(event); this.props.history.push('/pricing') }}>
                        <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">monetization_on</Icon></ListItemIcon>
                        <ListItemText
                          primary={`${startCase(this.props.user.subscription.plan === "free" ? 'No' : this.props.user.subscription.plan)} plan`}
                          secondary={`No subscription plan. Pay per trip.`}
                          classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}
                        />
                      </MenuItem>
                    :
                      this.props.user.subscription && <MenuItem component="a" href={'https://billing.stripe.com/p/login/dR6eV3h198NZ5QQ9AA'} target="_blank" onClick={(event) => {this.handleProfileMenuClose(event)}}>
                        <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">monetization_on</Icon></ListItemIcon>
                        <ListItemText
                          primary={`${startCase(this.props.user.subscription.plan === "free" ? 'No' : this.props.user.subscription.plan)} plan`}
                          secondary={this.props.user.subscription.plan === "free" ? 'No subscription plan. Pay per trip.' : `Planned ${this.props.user.subscription.usage_monthly.trips} / ${this.props.user.subscription.usage_cap.trips} trips per month`}
                          classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}
                        />
                      </MenuItem>
                    }

                    {this.props.user.subscription && this.props.user.subscription.plan === "free" &&
                      <MenuItem disableRipple onClick={(event) => { this.handleProfileMenuClose(event); this.props.history.push('/pricing') }}>
                        <Button key={'subscription'} variant="contained" color="primary" disableElevation className={classes.btnSignupMenu} style={{padding: '5px 10px', width: '100%'}}>
                          {this.props.user.subscription.plan === "free" ? 'Upgrade' : 'Change plan'}
                        </Button>
                      </MenuItem>
                    } */}
                    <MenuItem component="a" href={'https://forms.gle/wDpEqv3ufk4YLAzDA'} target="_blank" onClick={(event) => {this.handleProfileMenuClose(event)}}>
                      <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">mail</Icon></ListItemIcon>
                      <ListItemText primary={'Contact us'} disableTypography className={classes.listItemText} />
                    </MenuItem>
                    <Divider style={{margin: '5px 0px', backgroundColor: '#f2f2f2'}} />
                    <MenuItem onClick={(event) => {this.handleProfileMenuClose(event); this.handleSignOut()}}>
                      <ListItemIcon className={classes.listItemIcon}><Icon className="material-icons-outlined notranslate">logout</Icon></ListItemIcon>
                      <ListItemText primary={'Sign Out'} disableTypography className={classes.listItemText} />
                    </MenuItem>
                  </Menu>


                </Fragment>
              }
              </Fragment>
            }

            {!this.props.hideCurrency && <Select
              id="currency_select"
              value={this.props.user.currency || 'USD'}
              onChange={this.handleCurrencyChange.bind(this, this.props.user)}
              input={<InputBase classes={{root: classes.currRoot, input: classes.currInput}}/>}
              renderValue={(selected) => selected}
            >
              {Object.entries(CURRENCIES).sort((a, b) => a[1].name.toUpperCase() > b[1].name.toUpperCase() ? 1 : -1).map(([code, currency]) => {
                return <MenuItem key={code} style={{fontSize: 14, fontWeight: 500}} value={code}>{`${currency.name} • ${currency.symbol}`}</MenuItem>
              })}
            </Select>}

            {/* <Button variant="contained" color="primary" disableElevation className={classes.btnSignup} style={{float: 'right'}} onClick={(event) => { this.handleProfileMenuClose(event); this.props.history.push('/planner')}}>
              <Icon className="material-icons-outlined notranslate" style={{marginRight: 10}}>auto_awesome</Icon>
              Plan a trip
            </Button> */}

            {!this.props.hideMenu && <Tabs
              variant="standard"
              value={this.props.tabSelected || false}
              onChange={(event) => { }}
              aria-label="nav tabs"
              indicatorColor="primary"
              // classes={{flexContainer: (this.props.user.is_registered ? classes.tabFlexContainer : classes.tabCenterContainer) }}
              classes={{flexContainer: classes.tabCenterContainer, root: classes.tabs}}
            >
              {/* {!this.props.user.is_registered && <LinkTab id="home" value="home" role="tab" style={{display: (!this.props.user.is_registered ? 'inline-flex' : 'inline-flex')}} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} label={"Plan a trip"} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href='/' {...a11yProps(0)} />} */}
              {this.props.user.is_registered && <LinkTab id="plan" value="plan" role="tab" aria-selected={this.props.tabSelected === "plan"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} label={"Plan a trip"} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href="/planner" {...a11yProps(2)} />}
              {!this.props.user.is_registered && <LinkTab id="assistant" value="assistant" role="tab" aria-selected={this.props.tabSelected === "assistant"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} label={"Assistant"} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href="/ai-travel-assistant" {...a11yProps(2)} /> }
              <LinkTab id="explore" role="tab" aria-selected={this.props.tabSelected === "explore"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} value="explore" label={"Explore"} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href={`/explore/recently${this.props.locationFrom && this.props.locationFrom !== 'nearby' ? '/from/' + this.props.locationFrom : ''}`} {...a11yProps(2)} />
              {/* {!this.props.user.is_registered && <LinkTab role="tab" aria-selected={this.props.tabSelected === "personalize"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} value="personalize" label={<Badge variant="standard" badgeContent={'new'} color="secondary" classes={{badge: classes.menuBadge}}>Personality Test</Badge>} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href={`/user/personalize`} {...a11yProps(3)} />} */}
              <LinkTab id="trips" role="tab" aria-selected={this.props.tabSelected === "trips"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} value="trips" label={<Badge variant="dot" badgeContent={this.state.tripCount > 3 ? "4+" : this.state.tripCount} color="secondary" max={9}>My trips</Badge>} classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href="/trips" {...a11yProps(5)} />
              {/* {!this.props.user.is_registered && <LinkTab id="how-it-works" role="tab" aria-selected={this.props.tabSelected === "how-it-works"} icon={<Icon className="material-icons-outlined notranslate">blank</Icon>} value="how-it-works" label="How it works" classes={{root: classes.tabRoot, wrapper: classes.tabDesktopWrapper, labelIcon: classes.labelDesktopIcon}} href="/pages/how-it-works" {...a11yProps(1)} />} */}
            </Tabs>}
          </Toolbar>
        </AppBar>

        <Drawer anchor="bottom" open={this.state.openedMobileMenu} onClose={this.handleMenuClose} style={{widht: '100vw'}}>
          <List className={classes.mobileMenu}>
            <ListItem button component="a" key={'logo'} href="/">
              <span className={classes.logo} style={{}}>
                Travelities
              </span>
            </ListItem>
            <br/>
            <ListItem button component="a" key={'explore'} href={`/explore${this.props.locationFrom && this.props.locationFrom !== 'nearby' ? '/from/' + this.props.locationFrom : ''}`}>
              <ListItemIcon><Icon className="material-icons-outlined notranslate">travel_explore</Icon></ListItemIcon>
              <ListItemText primary={'Explore'} disableTypography className={classes.listItemText} />
            </ListItem>
            <ListItem button component="a" key={'trips'} href="/trips">
              <ListItemIcon><Icon className="material-icons-outlined notranslate">luggage</Icon></ListItemIcon>
              <ListItemText primary={'Trips'} disableTypography className={classes.listItemText} />
            </ListItem>
            {!this.props.user.is_registered && <ListItem button component="a" key={'how-it-works'} href="/pages/how-it-works">
              <ListItemIcon><Icon className="material-icons-outlined notranslate">info</Icon></ListItemIcon>
              <ListItemText primary={'How it works'} disableTypography className={classes.listItemText} />
            </ListItem>}
            <ListItem button component="a" key={'support-me'} href="https://www.buymeacoffee.com/vladimeeer" target="_blank">
              <ListItemIcon><Icon className="material-icons-outlined notranslate">local_cafe</Icon></ListItemIcon>
              <ListItemText primary={'Buy me a coffee'} disableTypography className={classes.listItemText} />
            </ListItem>
          </List>
          <Divider style={{margin: '10px 0px'}} />
          <List className={classes.mobileMenu}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader" style={{textAlign: 'left'}}>
                    Profile
                  </ListSubheader>
                }
          >
            {this.props.user.is_registered && <ListItem disabled>
              <ListItemIcon><Icon className="material-icons-outlined notranslate">account_circle</Icon></ListItemIcon>
              <ListItemText primary={this.props.user.first_name ? this.props.user.first_name : 'Account'} secondary={this.props.user.email || ''} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
            </ListItem>}
            {this.props.user.is_registered && <ListItem button component="a" key={'personalize'} href="/user/preferences">
              <ListItemIcon><Icon className="material-icons-outlined notranslate">tune</Icon></ListItemIcon>
              <ListItemText primary={'Travel style'} secondary={'Update your preferences'} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
            </ListItem>}
            {this.props.user.is_registered && <ListItem button component="a" key={'discover-weekly'} href={`/discover-weekly`}>
              <ListItemIcon><Icon className="material-icons-outlined notranslate">next_week</Icon></ListItemIcon>
              <ListItemText primary={'Discover weekly'} disableTypography className={classes.listItemText} />
            </ListItem>}
            {!this.props.user.is_registered && <ListItem button component="a" key={'signin'} onClick={this.handleSignupOpen.bind(this, 'signup')}>
              <ListItemIcon><Icon className="material-icons-outlined notranslate">login</Icon></ListItemIcon>
              <ListItemText primary={'Sign in'} disableTypography className={classes.listItemText} />
            </ListItem>}
            {!this.props.user.is_registered && <Divider style={{margin: '10px 0px'}} />}
            {!this.props.user.is_registered && <ListItem component="div" key={'personalize'}>
              <Button key={'personalize'} variant="contained" color="primary" disableElevation className={classes.btnSignupMenu} component="a" href="/user/personalize">Start with a quiz</Button>
            </ListItem>}
            {this.props.user.is_registered && <Divider style={{margin: '10px 0px'}} />}
            {this.props.user.is_registered && <ListItem button component="a" key={'signout'} onClick={this.handleSignOut}>
              <ListItemIcon><Icon className="material-icons-outlined notranslate">logout</Icon></ListItemIcon>
              <ListItemText primary={'Sign out'} disableTypography className={classes.listItemText} />
            </ListItem>}
          </List>
        </Drawer>

        {!this.props.user.is_registered && <SignupDialog
          dialogType={this.state.dialogType}
          openedSigninDialog={this.state.openedSigninDialog}
          onSigninModalChanged={this.handleSignupClose}
          onSignin={this.handleSignin}
          user={this.props.user}
          title={this.props.signinDialogTitle}
          subtitle={this.props.signinDialogSubtitle}
          hideCloseBtn={this.props.signinDialogForce}
        />}
        {/* {!this.state.signedUpAlready && !this.props.user.is_registered && <SignupFloatingBar onSigninClick={this.handleSignupOpen.bind(this, 'signup')}/>} */}

        {/* Bottom navigation */}
        {!this.props.hideBottomMenu &&<BottomNavigation
          className={classes.bottomNav}
          showLabels={true}
          value={this.props.tabSelected}
        >
          {/* {!this.props.user.is_registered && <BottomNavigationAction classes={{root: classes.bottomNavRoot, selected: classes.bottomNavSelected, label: classes.bottomNavLabel}} value="home" label="Plan a trip" icon={<Icon className="material-icons-outlined notranslate">auto_fix_high</Icon>} onClick={() => { this.props.user.is_registered ? this.props.history.push(`/`) : this.props.history.push(`/`)} } /> } */}
          <BottomNavigationAction classes={{root: classes.bottomNavRoot, label: classes.bottomNavLabel, selected: classes.bottomNavSelected}} value="explore" label="Explore" icon={<Icon className="material-icons-outlined notranslate">travel_explore</Icon>} onClick={() => this.props.history.push(`/explore/recently`)} />
          <BottomNavigationAction classes={{root: classes.bottomNavRootTop, selected: classes.bottomNavSelected, label: classes.bottomNavLabel}} value="request" label="Plan a trip" icon={<Icon className="material-icons-outlined notranslate">add_circle</Icon>} onClick={() => { this.props.history.push({pathname: '/planner'}) }} /> {/* this.props.history.push({pathname: '/planner', state: {step: 'occasion'}}) */}
          {this.props.user.is_registered && <BottomNavigationAction classes={{root: classes.bottomNavRoot, label: classes.bottomNavLabel, selected: classes.bottomNavSelected}} value="trips" label="My trips" icon={<Icon className="material-icons-outlined notranslate">luggage</Icon>} onClick={() => { this.props.history.push(`/trips`) }} /> }
          {!this.props.user.is_registered && <BottomNavigationAction classes={{root: classes.bottomNavRoot, label: classes.bottomNavLabel, selected: classes.bottomNavSelected}} value="profile" label={this.props.user.is_registered ? "Profile" : "Sign in"} icon={<Icon className="material-icons-outlined notranslate">account_circle</Icon>} onClick={(this.props.user.is_registered ? () => this.props.history.push(`/user/menu`) : this.handleSignupOpen.bind(this, 'signup'))} />}
        </BottomNavigation>}

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.notification !== null}
          autoHideDuration={6000}
          onClose={() => { this.setState({notification: null}) }}
          key={'bottom center'}
        >
          <Alert onClose={() => { this.setState({notification: null}) }} severity="success" elevation={5} className={classes.notification}>
            {this.state.notification && this.state.notification}
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

const styles = theme => ({
  discoverImage: {
    backgroundImage: 'url(/green-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    margin: '-14px -24px -31px 0px',
    padding: '50px 30px 0px 30px',

    '& > img': {
      margin: 'auto',
      display: 'block',
      verticalAlign: 'middle',
      position: 'relative',
      maxWidth: 500,
    },

    [theme.breakpoints.down('sm')]: {
      margin: '50px -24px -50px -49px',
    },
  },
  headline: {
    textAlign: 'left',
    lineHeight: 1.67,
    color: '#303030',
    fontSize: '27px',
    fontWeight: 800,

    [theme.breakpoints.up('md')]: {
      margin: '31px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-20px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-31px 0px 30px 0px',
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '14px !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginBottom: '20px !important',
  },
  discoverIcon: {
    verticalAlign: 'middle',
    color: '#02c39a',
    marginRight: 8,
  },
  thankyouIcon: {
    verticalAlign: 'middle',
    padding: '10px',
    backgroundColor: '#02c39a',
    width: 36,
    height: 36,
    borderRadius: 50,
    margin: '5px auto 5px auto',

    '& > img': {
      margin: '6px 0px 0px 3px',
    },
  },
  logo: {
    fontFamily: 'Lalezar, cursive',
    fontSize: '25px',
    textDecoration: 'none',
    color: '#02c39a',
    position: 'relative',
    zIndex: 1,
    margin: 0,
    float: 'left',
    left: '-5px',
    top: 14,
    bottom: '0px',
    padding: '0px 5px',
    backgroundColor: 'transparent',

    [theme.breakpoints.down('xs')]: {
      top: 16,
      fontSize: 20,
      // marginBottom: 25,
    },
  },
  appbar: {
    zIndex: 1,
    display: 'block',
    boxShadow: 'none',
    width: '100%',
    position: 'relative',
    minHeight: 65,
    backgroundColor: 'transparent !important',
    // backgroundColor: 'rgb(250, 250, 250) !important',
    // boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.2), 0px -18px 2px 0px rgba(0,0,0,0.14), 0px -4px 1px -2px rgba(0,0,0,0.12)',
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',
  },
  progressPrimary: {
    backgroundColor: '#e0e0e0',
    height: '2px'
  },
  progressBarColor: {
    backgroundColor: '#02c39a'
  },
  progress: {
    flexGrow: 1,
    width: '100%',
    float: 'left',
    clear: 'both',
    margin: '0px',
  },
  toolbar: {
    maxWidth: 1119,
    width: 'calc(100vw - 26px) !important',
    padding: '4px 13px 0px 13px !important',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: '20px',
    fontWeight: 400,
  },
  menuItem: {
    flexGrow: 1,
    padding: '10px 10px',
    margin: '10px 0px 10px 10px',
    float: 'right',
  },
  tabs: {
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    left: 0,
  },
  tabRoot: {
    minWidth: 110,
    fontWeight: 600,
    color: '#333333',
    opacity: 1,
  },
  tabWrapper: {
    alignItems: 'center',
    flexDirection: 'row',

    '& > *:first-child': {
      marginBottom: '6px',
      marginRight: '6px',
      width: 0,
    }
  },
  tabDesktopWrapper: {
    alignItems: 'center',
    flexDirection: 'row',

    '& > *:first-child': {
      marginBottom: '6px',
      // marginRight: '6px',
      width: 0,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  labelDesktopIcon: {
    minHeight: '55px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tabFlexContainer: {
    justifyContent: 'flex-end',
  },
  tabCenterContainer: {
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  labelIcon: {
    minHeight: '55px',
    width: 0,
  },
  menuIcon: {
    display: 'none',
    float: 'right',
    margin: '10px 5px 10px 10px',
    padding: '8px',
    zIndex: 10,

    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  mobileMenu: {
    width: 250,
  },
  listItemIcon: {
    minWidth: '40px !important',
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
  },
  listItemTextBold: {
    fontSize: 14,
    fontWeight: 700,
  },
  listItemTextSecondary: {
    fontSize: 11,
    fontWeight: 500,
    color: '#777',
  },
  listItemTextPlan: {
    fontSize: 14,
    fontWeight: 700,
    color: '#01a683',
    borderRadius: 25,
  },
  btnSignup: {
    color: '#fff',
    borderRadius: 25,
    float: 'right',
    margin: '7px 5px 0px 0px',
    padding: '10px 25px',
    zIndex: 1,
    fontWeight: 600,

    [theme.breakpoints.down('sm')]: {
      padding: '13px 25px',
      display: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '10px 17px',
      display: 'none',
    },
  },
  btnSignupMenu: {
    color: '#fff',
    borderRadius: 25,
    float: 'right',
    margin: '0px auto 0px auto',
    padding: '13px 35px',
    zIndex: 1,
    fontWeight: 800,
  },
  btnSignin: {
    color: '#333',
    fontWeight: 600,
    borderRadius: 25,
    float: 'right',
    height: 40,
    margin: '7px 5px 0px 0px',
    padding: '23px 20px',
    zIndex: 1,
    border: '1px solid #ccc',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btnSignout: {
    color: '#000',
    borderRadius: 25,
    float: 'right',
    height: 40,
    margin: '10px 5px 0px 0px',
    zIndex: 1,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btnLink: {
    borderRadius: 25,
    color: '#02c39a',
    fontWeight: 600,
    marginTop: -3,
  },
  backIcon: {
    borderRadius: 25,
    // background: '#fff',
    padding: '7px',
    color: '#25082f',
    fontSize: '14px',
    fontWeight: 500,
    position: 'relative',
    verticalAlign: 'middle',
    margin: '-11px 10px -10px -3px',
    backgroundColor: '#fff',
    boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',

    '&:hover': {
      background: '#f2f2f2',
    },
  },
  notification: {
    borderRadius: 25,
    minWidth: 100,
    fontWeight: 600,
    backgroundColor: '#fff',
  },
  menuBadge: {
    top: -4,
    right: 7,
  },
  profileBox: {
    color: '#000',
    borderRadius: 25,
    backgroundColor: '#fff',
    // boxShadow: '0 5px 10px 0 rgb(0 0 0 / 5%)',
    float: 'right',
    height: 40,
    margin: '10px 0px 0px 0px',
    zIndex: 1,

    '&:hover': {
      backgroundColor: '#f2f2f2',
    },

    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },

  },
  profileMenu: {
    margin: '10px 0px',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    // border: '1px solid #f5f5f5',
    // borderRadius: 25,
  },
  profileMenuIcon: {
    fontSize: 15,
    marginRight: 5,
  },
  currRoot: {
    float: 'right',
    margin: 10,
    zIndex: 100,
    textAlign: 'left',

    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  currInput: {
    borderRadius: 8,
    position: 'relative',
    // border: '1px solid #ced4da',
    fontSize: 14,
    fontWeight: 600,
    padding: '10px 26px 10px 12px',

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
  bottomNav: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: '#fff',
    boxShadow: 'rgb(0 0 0 / 10%) 0px -10px 20px 0px',
    borderTop: '1px solid #eee',
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
    },
  },
  bottomNavRoot: {
    fontWeight: 500,
    fontSize: 11,
  },
  bottomNavLabel: {
    width: 80,
    fontSize: 11,
  },
  bottomNavRootTop: {
    fontWeight: 500,
    margin: '0px -4px 0px 2px',

    '& .material-icons': {
      marginTop: -25,
      background: '#fff',
      fontSize: 50,
      color: '#00c399',
      borderRadius: 50,
      padding: '0px 0px 0px 0.1px',
      zIndex: 100,
    },
  },
  bottomNavSelected: {
    fontWeight: 800,
    fontSize: '11px !important',
  },
  buttonClose: {
    color: '#000',
    borderRadius: 25,
    float: 'right',
    height: 40,
    margin: '10px 0px 0px 0px',
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',

    '&:hover': {
      background: '#f2f2f2',
      boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    },
  }
})

export default withStyles(styles)(withRouter(withWidth()(AppTopBar)));