import React, { Component, Fragment } from 'react'
// import { APIProvider, Map } from '@vis.gl/react-google-maps';
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { config } from '../config.js'
import {
  Button,
  Box,
  Icon,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  CardMedia,
  TextareaAutosize,
} from '@material-ui/core'
import {
  TimelineSeparator,
  TimelineDot,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { startCase } from 'lodash'
import ReactPixel from 'react-facebook-pixel'
import AppTopBar from '../components/AppTopBar.js'
import SearchPanel from '../components/SearchPanel.js'
import Itinerary from '../components/itinerary/Itinerary.js'
import TripItinerary from '../components/trip/TripItinerary.js'
import TravelersFilter from '../components/filters/TravelersFilter.js'
import PlaceField from '../components/forms/PlaceField.js'

const GOOGLE_MAPS_API_KEY = config.ui.GOOGLE_MAPS_API_KEY

class Assistant extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openedSigninDialog: false,
      openedSigninDialogType: 'signup',
      showHeader: true,

      travel: {
        travelers: {
          adults: 1,
          children: 0,
          infants: 0,
        },
        from: [],
        description: '',
      },
      suggestions: [
        // {
        //   type: 'flight',
        //   title: 'Direct Flight to London',
        //   description: 'Non-stop flight for a quick getaway.',
        // },
        // {
        //   type: 'stay',
        //   title: 'Luxury Hotel in Paris',
        //   description: 'Enjoy a comfortable stay with Eiffel Tower view.',
        // },
        // {
        //   type: 'place',
        //   title: 'Explore Ancient Rome',
        //   description: 'Visit the Colosseum and Roman Forum.',
        // },
      ],
      isLoading: false,
      error: null
    }
  }

  componentDidMount() {
    window.gtag('event', 'assistant');
    ReactPixel.pageView()
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }

  handleChange = (event, index = false) => {
    if (index !== false) {
      let state = this.state[event.target.name]
      state[index] = merge(event.target.value, {id: state[index].id})
      this.setState({[event.target.name]: state});
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  handleDescriptionChange = (event) => {
    this.setState({
      travel: {
        ...this.state.travel,
        description: event.target.value,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true, error: null });

    try {
      const response = await fetch(`${config.api.TRAVEL_URL}/ai/planner`, { // Assuming you have an endpoint for AI planning
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': cookies.get('jwt') ? `Bearer ${cookies.get('jwt')}` : undefined, // Conditionally add JWT
        },
        body: JSON.stringify({
          ...this.state.travel,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.trip_id) {
        this.setState({ tripId: data.trip_id, isLoading: false }, () => {
          this.fetchItinerary(data.trip_id); // Fetch itinerary immediately after trip creation
        });
      } else {
        this.setState({ error: data.error || 'Failed to create trip.', isLoading: false });
      }
    } catch (error) {
      console.error("Error during AI planning:", error);
      this.setState({ error: error.message || 'An error occurred.', isLoading: false });
    }
  };

  fetchItinerary = async (tripId) => {
    try {
      const response = await fetch(`${config.api.TRAVEL_URL}/trips/${tripId}`, {
        headers: {
          'Authorization': cookies.get('jwt') ? `Bearer ${cookies.get('jwt')}` : undefined,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({ itinerary: data, isLoading: false });
    } catch (error) {
      console.error("Error fetching itinerary:", error);
      this.setState({ error: error.message || 'Failed to fetch itinerary.', isLoading: false });
    }
  };

  render() {
    const { classes, user } = this.props;
    const { travelers, from, description } = this.state.travel;
    const { suggestions, isLoading, error, tripId, itinerary } = this.state;

    return (
      <Fragment>
      <Helmet>
        <title>{`Assistant`}</title>
        <link rel="canonical" href={`https://www${config.api.DOMAIN}/assistant`} />
      </Helmet>
      <AppTopBar
        hideMenu={false}
        hideBottomMenu={true}
        hideCurrency={true}
        showBackButton={false}
        backButtonPath={`/`}
        onLocationChanged={this.props.onLocationChanged}
        onLocationCleared={this.props.onLocationCleared}
        onCurrencyChange={this.handleReloadResults}
        onChange={this.props.onFilterChange}
        onSignin={this.props.onSignin}
        user={this.props.user}
        address={startCase(this.props.address)}
        tabSelected={this.props.tabSelected || 'assistant'}
        openedSigninDialog={this.state.signinDialogOpened}
        signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
      />

      <Grid container spacing={2} className={classes.grid} justify="center">

        <Grid item xs={12} md={7} className={classes.searchContainer}>
        <Grid container className={classes.searchWrapper}>
          <Grid item xs={8} md={8} className={classes.fromContainer}>
          <PlaceField
            name="from"
            label="Where from? *"
            autofocus={true}
            placeholder={from && from.length > 0 ? "Where from?" : "Enter location"}
            limitTags={3}
            single={false}
            standalone={true}
            defaultValues={from}
            onChange={(event) => this.handleChange(event)}
            classes={{iconLabel: classes.iconLabel}}
          />
          </Grid>
          <Grid item xs={4} md={4} className={classes.travelersContainer}>
          <TravelersFilter
            name="travelers"
            adults={travelers && travelers.adults}
            children={travelers && travelers.children}
            infants={travelers && travelers.infants}
            onChange={(event) => this.handleChange(event)}
            classes={{iconLabel: classes.iconLabel}}
          />
          </Grid>
        </Grid>

        <Box className={classes.itineraryRail}>
          <div className={classes.suggestions}>
          {error && (
            <Typography color="error" variant="body2" className={classes.error}>
            {error}
            </Typography>
          )}

          {suggestions.map((suggestion, index) => (
            <Paper key={index} className={classes.suggestionItem}>
            <Box display="flex" alignItems="center">
              <Icon className={`${classes.suggestionIcon} material-icons-outlined`}>
              {suggestion.type === 'flight' ? 'flight' :
              suggestion.type === 'stay' ? 'hotel' : 'place'}
              </Icon>
              <Box ml={2}>
              <Typography variant="subtitle1">{suggestion.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {suggestion.description}
              </Typography>
              </Box>
            </Box>
            </Paper>
          ))}
          </div>


          <Box>
            <TimelineSeparator>
              <TimelineDot className={`${classes.itineraryIconRoot} ${classes.itineraryIconWhite}`}>
                {isLoading && <CircularProgress style={{fontWeight: '500', margin: 'auto', position: 'absolute', height: 38, width: 38, top: -2, left: -2}}/>}
                <Icon className={`${classes.icon} material-icons-outlined notranslate`}>
                add_location_alt
                </Icon>
              </TimelineDot>
            </TimelineSeparator>
            <Grid item xs={12} className={`${classes.searchField}`}>
              <div className={classes.destinationInputWrapper}>
                <form onSubmit={this.handleSubmit}>
                <TextareaAutosize
                  id="destination"
                  aria-label="trip_description textarea"
                  placeholder={isLoading ? "Thinking..." : "Where to? Ask anything.."}
                  rowsMin={1}
                  variant="outlined"
                  name="trip_description"
                  className={classes.textfieldArea}
                  value={description}
                  onChange={this.handleDescriptionChange}
                />
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  className={classes.button}
                >
                  {isLoading ? 'Planning...' : 'Get Recommendations'}
                </Button> */}
                </form>
              </div>
            </Grid>
          </Box>
        </Box>

        {/* <div className={classes.itineraryLabel}>Style</div>
        <div className={classes.itineraryLabel}>Theme</div> */}

        {/* Display TripItinerary Component */}
        {tripId && itinerary && (
          <TripItinerary
          trip_id={tripId}
          user={user}
          onSignin={this.props.onSignin}
          search_params={{
            from: from,
            description: description,
            travelers: travelers,
          }}
          />
        )}
        </Grid>

        {/* <Grid item xs={12} md={5}>
        <Box className={classes.sidebarBox}>
          sidebar
        </Box>
        </Grid> */}
      </Grid>

      </Fragment>
    )
  }
}

const styles = theme => ({
  grid: {
    textAlign: 'left',
    maxWidth: 1142,
    margin: '50px auto 0px auto',
    width: 'calc(100% - 20px)',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 10px',
    },
  },
  topPanel: {
    padding: '0px 0px 0px 0px !important',
    margin: '0px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    maxWidth: 1130,
    position: 'relative',
    boxShadow: 'none',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 4,
    },
  },
  headerPaper: {
    padding: '0px 0px 150px 0px',
    margin: '-94px auto 0px auto',
    color: '#000',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: '#fafafa',
    display: 'block',
  },
  headerTitle: {
    maxWidth: 1119,
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: '40px',
    fontWeight: 800,
    textAlign: 'left',
    marginTop: '90px',
    padding: '80px 10px 0px 10px',


    [theme.breakpoints.down('xs')]: {
      padding: '90px 20px 0px 20px',
      fontSize: '30px',
      marginTop: '70px',
    },
  },
  headerMedia: {
    // maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',
    width: '50vw',
    minWidth: '1000px',
    maxWidth: '1000px',
    position: 'absolute',
    left: '500px',
    top: '-112px',
    // top: '-152px',
    // top: '-62px',
    display: 'block',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
      minWidth: 600,
      left: '-70px',
      top: '-120px',
    },
  },
  subtitle: {
    fontWeight: 700,
    fontSize: '45% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 20,
    maxWidth: 490,
    lineHeight: 1.9,

    [theme.breakpoints.down('xs')]: {
      fontSize: '55% !important',
    },
  },
  searchField: {
    boxShadow: "0 5px 10px 0 rgb(0 0 0 / 5%)",
    border: '1px solid #ddd',
    borderRadius: 20,
    marginBottom: 10,
    backgroundColor: '#fff',
    zIndex: 1,
    position: 'relative',
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconLabel: {
    color: '#333',
    fontSize: 13,
    fontWeight: 700,
    marginLeft: 5,
  },
  itineraryRail: {
    textAlign: 'left',

    '&::before': {
      position: 'absolute',
      zIndex: 0,
      left: 37,
      width: 2,
      content: '""',
      top: 30,
      height: 'calc(100% - 90px)',
      background: '#f2f2f2',
    },
  },
  itineraryIconRoot: {
    float: 'left',
    zIndex: 3,
    position: 'absolute',
    left: 18,
    display: 'block',
    marginTop: 10,
    color: '#333',
    backgroundColor: '#c6e8c9',
    boxShadow: 'none',
    borderColor: "#eee",
  },
  itineraryIconWhite: {
    backgroundColor: '#fff',
  },
  icon: {
    verticalAlign: 'middle !important',
    padding: '4px !important',
    fontSize: 18,
  },
  textfieldArea: {
    width: 'calc(98% - 50px)',
    minWidth: 'calc(98% - 50px)',
    maxWidth: 'calc(98% - 50px)',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '2px 0px 0px 50px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 5px',
    border: 'none',
    fontFamily: 'inherit',
    minHeight: 20,
    resize: 'none',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
  searchContainer: {
    marginBottom: 10,
    width: '100%',
    position: 'relative',
  },
  searchWrapper: {
    marginBottom: 30,
    backgroundColor: '#fafafa',
    border: '1px solid #eee',
    borderRadius: 40,
    paddingLeft: 10,
    zIndex: 1,
    position: 'relative',
  },
  travelersContainer: {
    padding: '5px 5px 5px 5px',
    borderLeft: '1px solid #eee',
  },
  fromContainer: {
    paddingTop: 5,
  },
  itineraryLabel: {
    padding: '6px 5px 0px 50px',
  },
  destinationField: {
    boxShadow: "0 5px 10px 0 rgb(0 0 0 / 5%)",
    position: 'sticky',
  },
  destinationInputWrapper: {
    padding: '7px 5px 0px 5px',
  },
  sidebarBox: {
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: 15,
    minHeight: 200,
    boxShadow: "-2px 5px 10px 4px rgb(0 0 0 / 5%)",
  },
  suggestions: {
    marginTop: 20,
    marginLeft: 50,
    marginBottom: 30,
  },
  suggestionItem: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      cursor: 'pointer',
    }
  },
  suggestionIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(1),
  }
})

export default withRouter(withStyles(styles)(withWidth()(Assistant)))
