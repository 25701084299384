import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Icon,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core'
import {
  Alert,
  AlertTitle
} from '@material-ui/lab'
import { startCase } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import TravelersFilter from '../../components/filters/TravelersFilter.js'
import PlaceField from '../../components/forms/PlaceField.js'
import { OCCASIONS } from '../../utils/lists'

class PlannerOccasion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      occasion: props.occasion || 'trip',
      travelers: props.travel && props.travel.travelers ? props.travel.travelers : {
        adults: 1,
        children: 0,
        infants: 0,
      },
      from: props.travel && props.travel.from ? props.travel.from : [],
    }
  }

  emptyPlan = (user) => {
    return (user && user.subscription && user.subscription.has_remaining) && !user.subscription.has_remaining.trips
  }

  handleChange = (event, index = false) => {
    if (index !== false) {
      let state = this.state[event.target.name]
      state[index] = merge(event.target.value, {id: state[index].id})
      this.setState({[event.target.name]: state});
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  submitRequest = () => {
    this.props.onUpdated({ occasion: this.state.occasion, travel: {travelers: this.state.travelers, from: this.state.from} })
  }

  render() {
    const { classes, user } = this.props
    const { travelers, from } = this.state

    return (
      <Fragment>
        <Grid container className={classes.searchWrapper}>
          <Grid item xs={8} md={8} className={classes.fromContainer}>
            <PlaceField
              name="from"
              label="Where from? *"
              autofocus={true}
              placeholder={from && from.length > 0 ? "Where from?" : "Enter location"}
              limitTags={3}
              single={false}
              standalone={true}
              defaultValues={from}
              onChange={(event) => this.handleChange(event)}
              classes={{iconLabel: classes.iconLabel}}
            />
          </Grid>
          <Grid item xs={4} md={4} className={classes.travelersContainer}>
            <TravelersFilter
              name="travelers"
              adults={travelers && travelers.adults}
              children={travelers && travelers.children}
              infants={travelers && travelers.infants}
              onChange={(event) => this.handleChange(event)}
              classes={{iconLabel: classes.iconLabel}}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} style={{marginBottom: 50, maxWidth: 700, width: '100%', border: '1px solid #eee', borderRadius: '25px', padding: '5px 10px', backgroundColor: '#fff', zIndex: 1, position: 'relative', marginTop: 35}}>
          {/* {user && user.is_registered && user.subscription && user.subscription.usage_cap.trips > 0 && <Grid container spacing={2} justify="center" style={{width: '100%'}}>
            <Grid item xs={12} style={{margin: '-10px auto 30px auto', textAlign: 'center'}}>
              <Alert
                icon={<Icon className={`material-icons-outlined notranslate`} style={{color: this.emptyPlan(this.props.user) ? '' : '#07c399'}}>monetization_on</Icon>}
                severity={this.emptyPlan(user) ? 'warning' : 'success'}
                style={{borderRadius: 15, fontWeight: 500, backgroundColor: (this.emptyPlan(user) ? '' : '#07c3991a')}}
              >
                <strong>{`${startCase(user.subscription.plan) || 'Free'} plan: `}</strong>
                <Hidden smUp><br/></Hidden>
                {user.subscription.has_remaining.trips ?
                  `You can still plan ${(user.subscription.usage_cap.trips - user.subscription.usage_monthly.trips)} trips this month`
                :
                  `You have planned ${user.subscription.usage_monthly.trips} out of ${user.subscription.usage_cap.trips} trips included in your monthly subscription. You can still plan trips and pay per plan.`
                }

              </Alert>
            </Grid>
          </Grid>} */}

          {/* <h3 className={classes.priceGuarantee} style={{textAlign: 'center'}}>
            <Icon className="material-icons-outlined notranslate" style={{verticalAlign: 'middle', padding: '0px 5px 0px 10px'}}>workspace_premium</Icon>
            7-day money back guarantee
            <Icon className="material-icons-outlined notranslate" style={{verticalAlign: 'middle', padding: '0px 5px'}}>discount</Icon>
            Limited-time 50% Off
          </h3> */}

          <List className={classes.menu}>
          {OCCASIONS.map((occasion, i) => {
            return ((!occasion.beta || (occasion.beta && user && user.is_admin)) && <ListItem button key={occasion.value}
                    disabled={occasion.disabled}
                    onClick={() => this.setState({occasion: occasion.value})}
                    classes={{root: (this.state.occasion === occasion.value ? classes.listItemSelected : classes.listItem)}}
                    >
                <ListItemIcon><Icon className={`material-icons-outlined notranslate ${classes.listItemIcon}`}>{occasion.icon}</Icon></ListItemIcon>
                <ListItemText primary={occasion.title} secondary={this.state.occasion === occasion.value ? occasion.description : null} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
                <ListItemSecondaryAction onClick={() => !occasion.disabled ? this.setState({occasion: occasion.value}) : null} classes={{root: classes.listItemTextPrice}}>
                  <Box style={{display: 'flex', alignItems: 'center'}}>
                    <Box>
                      {occasion.costs !== undefined && <div style={{maxWidth: 150, display: 'block', whiteSpace: 'normal', textAlign: 'right', fontWeight: 800, float: 'right', verticalAlign: 'top', opacity: (occasion.disabled  ? 0.3 : 1)}}>
                        {new Intl.NumberFormat([navigator.language], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 2, currency: this.state.currency || 'USD'}).format(occasion.costs)}{occasion.pricePer ? ' /' + occasion.pricePer : ''}
                      </div>}
                      {occasion.original_price && this.state.occasion === occasion.value && <div style={{color: 'red', fontSize: '97%', fontWeight: 600, maxWidth: 100, display: 'block', whiteSpace: 'normal', textAlign: 'right', verticalAlign: 'middle', textDecoration: 'line-through'}}>
                        {new Intl.NumberFormat([navigator.language], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 2, currency: this.state.currency || 'USD'}).format(occasion.original_price)}
                      </div>}
                    </Box>
                    <Icon className="material-icons-outlined notranslate">{this.state.occasion === occasion.value ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
                  </Box>
                </ListItemSecondaryAction>
            </ListItem>)
          })}
          </List>
        </Grid>

        <Grid item xs={12} md={8} className={classes.bottomBar}>
          <Button disabled={!(this.state.occasion) || this.state.isSubmitting} onClick={this.submitRequest} variant="contained" color="primary" size="large" className={classes.buttonBook}>
            {this.state.isSubmitting ?
              <Fragment>Sending...</Fragment>
              :
              <Fragment>Continue<Icon className="material-icons-outlined notranslate" style={{marginLeft: 10, marginBottom: -7}}>east</Icon></Fragment>
            }
          </Button>
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(PlannerOccasion))
