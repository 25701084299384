import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Avatar,
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SignupForm from '../../components/forms/SignupForm'


class PlannerCreateAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contact: props.contact || null,
    }
  }

  handleSignin = (user) => {
    this.setState({contact: user})
    this.props.onSignin(user)
    // this.props.onUpdated({contact: user})
  }

  removeUser = () => {
    this.setState({contact: null})
  }

  submitRequest = () => {
    this.props.onUpdated({contact: this.state.contact})
  }

  render() {
    const { user, classes } = this.props
    const { contact } = this.state

    return (
      <Fragment>
        {!this.state.contact ?
          <Grid item xs={12} sm={7} md={6} style={{maxWidth: 393, margin: '0px auto'}}>
            <SignupForm
              type={this.props.dialogType || 'signup'}
              onSignin={this.handleSignin}
              user={user}
              hideHeader={true}
            />
          </Grid>
        :
          <Fragment>
              <Grid item xs={12} sm={7} md={8} style={{margin: '0px auto', maxWidth: 700}}>
                <List className={classes.menu}>
                  <ListItem classes={{root: classes.listItemSelected}}>
                    <ListItemAvatar>
                      <Avatar alt="Profile" src={contact.image} style={{marginRight: -5}}/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={contact.first_name}
                      secondary={contact.email}
                      classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}
                    />
                    <ListItemSecondaryAction onClick={this.removeUser} classes={{root: classes.listItemTextRemove}}>
                      <Icon className="material-icons-outlined notranslate">close</Icon>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} md={8} className={classes.bottomBar}>
                <Button disabled={!(this.state.contact) || this.props.isSubmitting} onClick={this.submitRequest} variant="contained" color="primary" size="large" className={classes.buttonBook}>
                  {this.props.isSubmitting ?
                    <Fragment>Sending...</Fragment>
                    :
                    <Fragment>Continue<Icon className="material-icons-outlined notranslate" style={{marginLeft: 10, marginBottom: -7}}>east</Icon></Fragment>
                  }
                </Button>
              </Grid>
          </Fragment>
        }
      </Fragment>
    )
  }
}

const styles = theme => ({
  listItemIcon: {
    color: '#02c39a',
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(PlannerCreateAccount))
