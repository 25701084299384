import React from 'react'
import ReactDOM from 'react-dom'
import 'whatwg-fetch'
import './index.css'
import App from './containers/App'
// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
import { MuiThemeProvider } from "@material-ui/core/styles"
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from "@sentry/react"
import { theme } from "./theme"
import ReactPixel from 'react-facebook-pixel'
import Helmet from 'react-helmet'
import Cookies from 'universal-cookie'
import { config } from './config.js'

const cookies = new Cookies();

Sentry.init({
  dsn: 'https://74c80bae62d8403d9cbf1aeab3dec7da@sentry.io/1310111',

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
})

// set user cookies
if (cookies.get('_uid') === undefined) {
  cookies.set('_uid', '_' + Math.random().toString(36).substr(2, 9), {
    path: '/',
    domain: config.api.DOMAIN,
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  })
}

if (config.env === 'development') {
  window['ga-disable-G-K4FCPTPH0P'] = true
  window.dataLayer = []
} else {
  ReactPixel.init('263316338622137', {}, {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  });
  ReactPixel.init('457765695951519', {}, {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  });
}


ReactDOM.render((
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <div className="App">
        <div className="app-container">
          <Helmet titleTemplate="Travelities | %s" defaultTitle="Travelities | AI travel assistant">
            <meta charSet="utf-8" />
            <meta property="og:url" content="https://www.travelities.com" />
            <meta property="og:description" content="Plan a trip in minutes with AI travel assistant"/>
          </Helmet>
          <App />
        </div>
      </div>
    </BrowserRouter>
  </MuiThemeProvider>
  ), document.getElementById('root'))

// registerServiceWorker()
unregister()