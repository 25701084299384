import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Link, withRouter } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Divider,
  Typography,
  Icon,
  IconButton,
  Slider,
  Tooltip,
  withStyles
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Skeleton from '@material-ui/lab/Skeleton'
import { startCase, kebabCase } from 'lodash'
import { stringToColor } from '../../utils/helpers'
import Cookies from 'universal-cookie'
import Score from '../trip/Score'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

class DestinationCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visited: this.props.visited || false,
      saved: this.props.saved || false,
      saves: this.props.saves || []
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.saves) !== JSON.stringify(this.props.saves)) {
      this.setState({saves: this.props.saves})
    }
  }

  saveInteraction(action) {
    this.setState(prevState => ({
      [action]: !prevState[action]
    }))

    // add or remove save of user when clicked on it
    if (this.props.members && this.props.members.length > 0 && this.props.member_id) {
      if (this.state.saves.indexOf(this.props.member_id) === -1) {
        this.setState({ saves: [this.props.member_id, ...this.state.saves] })
      } else {
        this.setState({ saves: this.state.saves.filter((item) => {return item !== this.props.member_id}) })
      }
    }

    let path = this.props.link_to_save ? this.props.link_to_save : `/users/${action === 'saved' ? 'save_destination' : 'visited_destination'}`;

    fetch(API + path, {
      method: 'POST',
      dataType: 'json',
      body: JSON.stringify({
        slug: `${kebabCase(this.props.name.split(',')[0])},${kebabCase(this.props.country)}`,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid')
      }
    })
  }

  member = (id, members) => {
    return (members || []).filter((m) => m.id === id)[0]
  }

  openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  render() {
    const {
      id,
      destination,
      classes,
      country,
      name,
      intro,
      images,
      latitude,
      longitude,
      // flight,
      loading,
      locationFrom,
      weather,
      score,
      slug,
      travel,
      trip_id,
      showHeader,
      link,
      link_to_save,
      members,
      group_id,
      travel_plan_id,
      created_at,
      refunded,
      planned,
      checked,
      openInNewWindow,
    } = this.props

    // const rand = (this.props.images && (Math.min(this.props.images.length, 3) - 1) >= 0 ? Math.floor(Math.random() * (Math.min(this.props.images.length, 3) - 1)) : 0)
    const country_link = `/explore/staycations/in/${kebabCase(country)}/from/${kebabCase(locationFrom.split(',')[0])},${kebabCase(locationFrom.split(',')[1])}`
    const trip_link = `${group_id ? '/group/' + group_id : ''}${travel_plan_id ? '/plan/' + travel_plan_id : ''}/trip${locationFrom.includes(",") ? '/' + kebabCase(locationFrom.split(',')[0]) + ',' + kebabCase(locationFrom.split(',')[1]) : (locationFrom === 'nearby' ? '' : '/' + locationFrom)}/to/${slug ? slug : kebabCase(name) + ',' + kebabCase(country)}${trip_id ? '/' + trip_id : ''}`

    return (
      <Grid item xs={12} sm={6} md={travel_plan_id ? 6 : 3} lg={travel_plan_id ? 6 : 3} xl={travel_plan_id ? 6 : 3}>
        <Card className={checked ? classes.cardSelected : classes.card} elevation={0} variant="outlined">
          {(!loading && showHeader) && <CardHeader
            classes={{root: classes.header, action: classes.headerAction}}
            // avatar={score &&
            //   <Fragment>
            //     {score.v1.value >= 3.0 &&
            //     <Tooltip
            //       placement="bottom-start"
            //       title={
            //         <Box style={{width: '300px'}}>
            //           <Typography color="inherit">Things to do:</Typography>
            //           <Box>{score.v1.things_to_do.matched.join(", ")}</Box>
            //           <Box>{score.v1.things_to_do.missing.join(", ")}</Box>
            //           <Typography color="inherit">Vibes:</Typography>
            //           {score.v1.vibes.map((pair, i) => {
            //             return <Box style={{width: 'inherit', display: 'block'}}><SliderOption leftLabel={score.v1.vibe_labels[i][0]} rightLabel={score.v1.vibe_labels[i][1]} defaultValue={pair} marks={[-1.0, -0.5, 0, 0.5, 1.0]} /></Box>
            //           })}
            //         </Box>
            //       }>
            //       <IconButton aria-label={"Accommodation"} className={classes.hotelIcon}>
            //         {score.v1.value.toFixed(1)}
            //       </IconButton>
            //     </Tooltip>}
            //     {score.v1.value < 3.0 &&
            //     <Tooltip title="Matching Score" arrow>
            //       <IconButton aria-label={"Travel"} className={classes.flightIcon}>
            //         {score.v1.value.toFixed(1)}
            //       </IconButton>
            //     </Tooltip>}

            //   </Fragment>
            // }
            avatar={travel &&
              // <Fragment>
              //   {travel.travel_included &&
              //   <Tooltip title="Travel Included" arrow classes={{tooltip: classes.tooltipRoot}}>
              //     <IconButton aria-label={"Travel"} className={classes.flightIcon}>
              //       <img src="/aeroplane-icon.svg" alt="travel icon" width="16" height="16" style={{width: '97%'}}/>
              //     </IconButton>
              //   </Tooltip>}
              //   {travel.hotel_included &&
              //   <Tooltip title="Accommodation Included" arrow classes={{tooltip: classes.tooltipRoot}}>
              //     <IconButton aria-label={"Accommodation"} className={classes.hotelIcon}>
              //       <img src="/bed-icon.svg" alt="accommodation icon" width="16" height="16" style={{width: '97%'}}/>
              //     </IconButton>
              //   </Tooltip>}
              // </Fragment>
              (travel_plan_id && <Tooltip title="Select" arrow>
                <IconButton aria-label="done" className={checked ? classes.doneIconSelected : classes.doneIcon} onClick={() => this.props.onChecked(id)}>
                  {!checked ? (
                    <Fragment>
                      <Icon className="notranslate">check_box_outline_blank</Icon>
                      {/* <small style={{fontSize: '13px', fontWeight: 600, marginLeft: 5}}></small> */}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Icon className="notranslate">check_box</Icon>
                      {/* <small style={{fontSize: '13px', fontWeight: 600, marginLeft: 5}}></small> */}
                    </Fragment>
                  )}
                </IconButton>
              </Tooltip>)
            }
            action={
              <Fragment>
                <AvatarGroup classes={{avatar: classes.avatarSmall, root: classes.avatarGroup}} max={3}>
                  {this.state.saves && this.state.saves.length > 0 && this.state.saves.map((id, i) => {
                    const member = this.member(id, members)
                    return <Avatar key={i} className={classes.avatarSmall} src={i < 3 ? member.image : ''} style={{color: stringToColor(member.first_name)}} alt={member.first_name}/>
                  })}
                </AvatarGroup>
                {/* {!created_at && !refunded && <Fragment>
                  {!this.state.saved ? (
                    <Tooltip title="Save" arrow classes={{tooltip: classes.tooltipRoot}}>
                      <IconButton aria-label="Save" onClick={() => this.saveInteraction("saved")} className={classes.favoriteIcon}><Icon className={`notranslate ${classes.favoriteIconSize}`}>favorite_border</Icon></IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove" arrow classes={{tooltip: classes.tooltipRoot}}>
                      <IconButton aria-label="Remove" onClick={() => this.saveInteraction("saved")} className={classes.favoriteIconSelected}><Icon className={`notranslate ${classes.favoriteIconSize}`}>favorite</Icon></IconButton>
                    </Tooltip>
                  )}
                </Fragment>} */}
                {created_at &&
                  <Tooltip title="Created" arrow classes={{tooltip: classes.tooltipRoot}}>
                    <Box className={classes.favoriteIcon} style={{fontWeight: 600, fontSize: 11}}>{moment(created_at).fromNow()}</Box>
                  </Tooltip>
                }
                {refunded &&
                  <Tooltip title="Refunded" arrow classes={{tooltip: classes.tooltipRoot}}>
                    <Box className={classes.favoriteIcon} style={{fontWeight: 600, fontSize: 11}}>Refunded</Box>
                  </Tooltip>
                }
                {!refunded &&
                  (!planned ?
                    <Tooltip title="Planning.." arrow classes={{tooltip: classes.tooltipRoot}}>
                      <Box className={classes.favoriteIcon} style={{fontWeight: 600, fontSize: 11}}>Planning..</Box>
                    </Tooltip>
                  :
                    (!created_at && <Box style={{fontWeight: 600, fontSize: 11, minHeight: 34, width: 0}}></Box>)
                  )
                }
              </Fragment>
            }
          />}
          <CardActionArea
          component='span'
          onClick={(e) => {
            e.stopPropagation();
            if (trip_id) {

              if (openInNewWindow) {
                this.openInNewTab(trip_link)
              } else {
                this.props.history.push(trip_link)
              }

            // select destination
            } else if (travel_plan_id) {

              if (id) {
                // this.props.onChecked(id)
                this.openInNewTab(trip_link)
              } else {
                this.props.history.push(trip_link)
              }

            // open trip
            } else if (kebabCase(name) !== kebabCase(country)) {
              this.props.onSelected(
              {
                destination: destination,
                dates: {
                  nights: (travel && travel.nights_in_dest ? [travel.nights_in_dest, travel.nights_in_dest]  : null),
                  start_date: (travel && travel.start_date ? travel.start_date : null),
                  end_date: (travel && travel.end_date ? travel.end_date : null),
                  dates_type: (travel && travel.dates_type ? travel.dates_type : null),
                  travel_type: (travel && travel.search_params ? travel.search_params.travel_type : null)
                },
                to: [{
                  description: `${name}, ${country}`,
                  address: `${name}, ${country}`,
                  place: name,
                  latitude: latitude,
                  longitude: longitude,
                  country: country,
                  slug: `${slug ? slug : kebabCase(name) + ',' + kebabCase(country)}`,
                  locationFrom: `${kebabCase(locationFrom.split(',')[0])},${kebabCase(locationFrom.split(',')[1])}`
                }],
                travel_plan_id: travel_plan_id,
                group_id: group_id,
              })

            // show detail of a country
            } else if (!loading) {
              this.props.history.push(country_link)
            }
          }}
          disableRipple>
            {loading ? (
              <Skeleton variant="rect" height={415} className={classes.media} style={{maxHeight: 464, opacity: '0.5',}} />
            ) : (
              <Link to={link || (kebabCase(name) === kebabCase(country) ? country_link : trip_link)} style={{width: '0%'}} onClick={(e) => { e.preventDefault() }}>
                <CardMedia
                  component="img"
                  alt={name}
                  className={classes.media}
                  height="450"
                  image={images && images.length > 0 ? images[0].sizes.medium.url.replace('http://', '//') : '/destination-placeholder.jpg'}
                  title={name}
                />
              </Link>
            )}
            <CardContent className={loading ? classes.contentLoading : classes.content}>
              <Typography gutterBottom variant="h3" className={classes.name}>
                {travel && travel.header &&
                  <div className={classes.flight}>
                    <div className={`notranslate ${classes.price}`}>
                      {travel.price ? new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: travel.currency || 'USD'}).format(travel.price) : ''}
                    </div>
                    <small className={`notranslate ${classes.small}`}>
                      <Fragment>
                      {travel.info ? travel.info : 'per person'}
                      </Fragment>
                      {/* {travel && travel.description ?
                        <Fragment>
                          <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>{travel.icon ? travel.icon : 'calendar_today'}</Icon>
                          {travel.description}
                        </Fragment>
                        :
                        <Fragment>
                          <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>calendar_today</Icon>
                          Check cheapest dates
                        </Fragment>
                      } */}
                      {false && <Fragment>
                        {travel.travel_price > 0 && <Fragment><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>flight</Icon>{new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: travel.currency || 'USD'}).format(travel.travel_price)}</Fragment>}
                        {travel.hotel_included && <Fragment>{travel.travel_price > 0 ? ', ' : ''}<Icon className={`material-icons-outlined notranslate ${classes.icon}`}>bed</Icon>{new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: travel.currency || 'USD'}).format(Math.round(travel.price - travel.travel_price))}</Fragment>}
                      </Fragment>}
                    </small>
                  </div>
                }
                {/* <Score score={score} /> */}
                {/* {trip_id ? `Trip to ${startCase(name)}` : startCase(name)} */}
                {name}
                <small className={classes.smallCountry}>
                  {country && !country.includes('&') && !country.includes(',') && <img src={`/flags/${kebabCase(country) || 'blank'}.svg`} onError={(e) => (e.target.onerror = null, e.target.src = '/flags/blank.svg')} className={classes.flag}  alt={`flag of ${country}`} width="28" height="28"/>}
                  {country && !country.includes('&') && !country.includes(',') ? startCase(country) : country}
                </small>
              </Typography>
              <Divider className={classes.divider}/>
              {/* <Score score={score} /> */}

              {!travel_plan_id &&
                <Fragment>
                  <Typography gutterBottom variant="h3" className={classes.dates}>
                    <span className={classes.small}>
                      {travel && travel.description ?
                        <Fragment>
                          {/* <div className={classes.flight}>
                            <div className={`notranslate ${classes.price}`}>
                              {travel.price ? new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: travel.currency || 'USD'}).format(travel.price) : ''}
                            </div>
                          </div> */}
                          {/* <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>calendar_today</Icon> */}
                          {travel.description}
                        </Fragment>
                        :
                        <Fragment>
                          <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>calendar_today</Icon>
                          Check cheapest dates
                        </Fragment>
                      }
                      {weather &&
                        <span className={classes.weather}>
                          {/* • <img alt={`${name}_weather_${weather.temp}`} className={classes.weatherIcon} src={weather.icon_url.replace('http://', '//')}></img> */}
                          • {weather.temp} °C
                        </span>
                      }
                    </span>
                  </Typography>
                </Fragment>
              }
            </CardContent>
          </CardActionArea>

          {travel_plan_id && id &&
            <CardActions className={classes.actions}>
              <Typography gutterBottom variant="h3" className={classes.dates} style={{width: '100%', margin: '5px 0px -5px 0px'}}>
                <span className={classes.small} style={{textAlign: 'center', position: 'relative', height: 31}}>
                  {!checked ? (
                    <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonAdd}`} onClick={() => this.props.onChecked(id)}>
                      {/* <Icon className={`material-icons-outlined notranslate`} style={{margin: '0px 5px'}}>add</Icon> */}
                      Select
                    </Button>
                  ) : (
                    <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonAdd}`} onClick={() => this.props.onChecked(id)}>
                      <Icon className={`material-icons-outlined notranslate`} style={{margin: '0px 5px'}}>check</Icon>
                      Selected
                    </Button>
                  )}
                </span>
              </Typography>

            {/* {loading ? (
              <Fragment>
                <Skeleton height={9} />
                <Skeleton height={9}  style={{marginLeft: 0}}/>
              </Fragment>
            ) : (
              <Typography component="p" className={classes.intro}>
                {intro}
              </Typography>
            )} */}
            {/* <Button size="small" className={classes.actionSecondary}>
              <Icon className={classes.icon}>explore_outlined</Icon> Explore
            </Button> */}
            {/* <Button size="small" color="primary" className={classes.actionPrimary} onClick={(e) => {this.props.onSelected({
              latitude: latitude,
              longitude: longitude,
              address: `${name}, ${country}`,
            })}}>
              <Icon className={classes.icon}>search</Icon> Plan a trip
            </Button> */}
          </CardActions>}
        </Card>
      </Grid>
    )
  }
}

const styles = theme => ({
  card: {
    // maxWidth: 450,
    textAlign: 'left',
    // boxShadow: 'none',
    border: '1px solid #f2f2f2',
    // borderRadius: '10px',

    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
      // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      border: '1px solid #f2f2f2',
    },
  },
  cardSelected: {
    textAlign: 'left',
    border: '2px solid #02c39a',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',

    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
      // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      border: '2px solid #02c39a',
    },
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
    // height: '60vw',
    // maxHeight: 250,
    maxHeight: 300,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 250,
    },

    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
    },
  },
  intro: {
    overflow: 'hidden',
    height: 34,
    fontSize: '13px',
    padding: 5,
    // marginBottom: 10,
    color: '#555',
  },
  actions: {
    // display: 'none',
    padding: '0px 11px 8px 12px',
    marginTop: -12,
    position: 'relative',
    backgroundColor: '#fff',
  },
  small: {
    display: 'block',
    fontSize: '13px',
    color: '#828282',
    fontWeight: 600,
    marginTop: 3,
  },
  smallCountry: {
    display: 'block',
    fontSize: '13px',
    color: '#828282',
    fontWeight: 600,
    marginTop: 8,
    width: 'calc(100% - 90px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'absolute',
  },
  price: {
    minHeight: 28,
    display: 'block',
    color: '#000',
    fontWeight: 800,
    fontSize: '1.1rem',
    paddingLeft: 15,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      fontWeight: 800,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '0.98rem',
      fontWeight: 800,
    },
  },
  name: {
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px 10px',
    color: '#000',
    fontWeight: 800,
    fontSize: '1.05rem',
    minHeight: 53,

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      fontWeight: 800,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '0.98rem',
      fontWeight: 800,
    },
  },
  dates: {
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#828282',
    textAlign: 'left',
    // borderTop: '1px solid #f2f2f2',
    margin: '5px 10px -5px 10px',
    padding: '10px 0px 0px 0px',
    minHeight: 30,
  },
  flight: {
    float: 'right',
    textAlign: 'right',
  },
  actionPrimary: {
    width: '100%',
    color: '#fff',
    margin: '0px 0px 0px 4px',
    backgroundColor: '#00a1ff',
    borderRadius: '20px',
    // maxWidth: '47%',
    maxWidth: '98%',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  actionSecondary: {
    width: '47%',
    color: '#555',
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',

    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  content: {
    // position: 'absolute',
    // bottom: 0,
    // width: '100%',
    padding: '12px',
    // background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0) 100%)',

    [theme.breakpoints.down('xs')]: {
      padding: '12px 6px',
    },
  },
  contentLoading: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '60px 0px 0px 0px',
    background: 'none',
    display: 'none',
  },
  icon: {
    marginRight: 5,
    marginBottom: 3,
    fontSize: '16px',
    verticalAlign: 'middle',
    color: '#02c39a',
    fontWeight: 600,
  },
  flag: {
    width: 18,
    marginRight: 5,
    marginTop: -3,
    verticalAlign: 'middle',
  },
  weather: {
    // position: 'absolute',
    fontSize: 13,
    fontWeight: 600,
    // backgroundColor: '#0000008c',
    borderRadius: 5,
    padding:'2px 5px 0px 0px',
    color: '#828282',
    bottom: 0,
    marginLeft: 4,
    // display: 'inline-block',
    // display: 'none',
  },
  weatherIcon: {
    width: 30,
    verticalAlign: 'middle',
    marginTop: '-3px',
    marginLeft: -4,
  },
  header: {
    marginBottom: '-85px',
    zIndex: 97,
    backgroundColor: 'transparent',
    width: 'calc(100% - 32px)',
    position: 'relative',
    padding: '14px 16px',
  },
  headerAction: {
    margin: '0px -3px 0px 0px',
  },
  favoriteIcon: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: '#25082f',
    marginLeft: 5,

    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 72%)',
    },
  },
  favoriteIconSelected: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: 'rgba(255, 107, 107, 0.9)',
    marginLeft: 5,
  },
  favoriteIconSize: {
    fontSize: 20,
  },
  doneIcon: {
    borderRadius: 25,
    padding: 9,
    color: '#25082f',
    marginTop: 0,
    marginLeft: -2,
    background: '#fff',

    '& .material-icons': {
      fontSize: 20,
    },

    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 72%)',
    },
  },
  doneIconSelected: {
    borderRadius: 25,
    padding: 9,
    color: '#02c39a',
    marginTop: 0,
    marginLeft: -2,
    background: '#fff',
    fontWeight: 500,

    '& .material-icons': {
      fontSize: 20,
      color: '#02c39a',
    },

    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 72%)',
    },
  },
  flightIcon: {
    borderRadius: 12,
    backgroundColor: 'rgba(255, 166, 43, 0.9)',
    marginRight: 6,
    opacity: 1,
    height: 40,
    width: 40,
    fontSize: '15px',
    fontWeight: 600,
    color: '#fff',

    '&:hover': {
      backgroundColor: 'rgba(255, 166, 43, 0.9)',
    },
  },
  hotelIcon: {
    borderRadius: 12,
    backgroundColor: '#02c39a',
    // backgroundColor: 'rgba(255, 107, 107, 0.9)',
    marginRight: 6,
    opacity: 1,
    height: 40,
    width: 40,
    fontSize: '15px',
    fontWeight: 600,
    color: '#fff',

    '&:hover': {
      backgroundColor: '#02c39a',
    },
  },
  divider: {
    backgroundColor: '#f2f2f2',
    // width: '40%',
    marginTop: 12,
  },
  tooltipRoot: {
    fontSize: 11,
  },
  avatarSmall: {
    width: 36,
    height: 36,
    fontSize: '13px',
    fontWeight: 600,
    backgroundColor: '#fff',
    color: '#25082f',
    border: '1px solid #fff',
  },
  avatarGroup: {
    float: 'left',
  },
  buttonAdd: {
    height: 32,
    fontSize: '13px',
    fontWeight: 900,
    backgroundColor: 'transparent',
    color: '#02c39a',
    border: '2px solid transparent',
    borderRadius: 45,
    padding: '18px 10px',
    minWidth: 10,
    width: '100%',
    verticalAlign: 'super',
    marginTop: -12,
    marginBottom: 4,
    position: 'absolute',
    left: 0,
    zIndex: 99,

    [theme.breakpoints.down('xs')]: {
      padding: '18px 6px',
    },

    '&:hover': {
      color: '#fff',
      backgroundColor: '#02c39a',
      border: '2px solid #02c39a',
    }
  },
});

export default withStyles(styles)(withRouter(DestinationCard));