import React, { Component, Fragment } from 'react'
import DestinationCard from '../../components/cards/DestinationCard'
import { withRouter, Link } from 'react-router-dom'
import {
  Grid,
  Typography,
  Button,
  Icon,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

class DestinationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: props.group_name,
      title: props.title,
      description: props.description,
      destinations: props.destinations || [],
      has_next_page: props.has_next_page || false,
      link_to_all: props.link_to_all,
      page: props.page || 1,
      isLoading: props.isLoading,
      isLoadingNextPage: false,
      pageSize: props.pageSize || 4,
    }
  }

  componentWillUnmount() {
    this.setState({destinations: []})
  }

  handleNextPage = () => {
    if (!this.state.isLoadingNextPage) {
      let location_from = (this.props.search_params.from ? this.props.search_params.from[0] : null)
      let location_to = (this.props.search_params.to ? this.props.search_params.to[0] : null)
      let { dates, travelers, interests, travel_type } = this.props.search_params
      let { filters } = this.props

      this.setState({isLoadingNextPage: true})
      window.gtag('event', 'destinations', {group: this.state.title, next_page: 'true'});

      fetch(this.props.next_page_url +
            '?adults=' + travelers.adults +
            '&children=' + travelers.children +
            '&infants=' + travelers.infants +
            '&dates_type=' + dates.dates_type +
            (dates && dates.start_date ? '&start_date=' + dates.start_date : '') +
            (dates && dates.end_date ? '&end_date=' + dates.end_date : '') +
            (dates && dates.nights ? '&nights=' + dates.nights : '') +
            '&country=' + (this.props.country || (location_to && location_to.country) || '') +
            (!this.props.country && location_to ? '&country_code=' + location_to.country_code : '') +
            (interests ? '&interests=' + interests : '') +
            '&location_from=' + ((location_from && location_from.slug) || this.props.locationFrom || 'nearby') +
            (location_from && location_from.latitude ? '&latitude_from=' + location_from.latitude : '') +
            (location_from && location_from.longitude ? '&longitude_from=' + location_from.longitude : '') +
            (filters && filters.interests !== undefined ? '&interests=' + filters.interests : '') +
            '&page=' + (this.state.page + 1),
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookies.get('jwt')}`,
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.params) {
          let group = data.destination_groups[0]

          if (group) {
            let firstPage = group.page === '1'

            this.setState(prevState => ({
              key: group.key,
              description: group.description,
              destinations: firstPage ? (group.destinations || []) : [...prevState.destinations, ...(group.destinations || [])],
              has_next_page: group.has_next_page,
              link_to_all: group.link_to_all,
              page: group.page,
              isLoadingNextPage: false,
              // destinations: firstPage ? (data.destinations.sort((a, b) => (a.flight ? a.flight.price : 100000) - (b.flight ? b.flight.price : 100000)) || []) : [...prevState.destinations, ...(data.destinations.sort((a, b) => (a.flight ? a.flight.price : 100000) - (b.flight ? b.flight.price : 100000)) || [])],
            }))
          } else {
            this.setState({isLoadingNextPage: false, has_next_page: false})
          }
        } else {
          this.setState({isLoadingNextPage: false, has_next_page: false})
        }
      })
    }
  }

  render() {
    const { classes, showLinkToAll, members, member_id, checked_destinations, openInNewWindow } = this.props
    const { title, description, key, link_to_all, destinations, isLoading, isLoadingNextPage, pageSize } = this.state

    return (
      <Fragment>
        <Fragment key={key}>
          {/* <div style={{ marginTop: 40, marginBottom: 40 }}> */}
            {(this.props.showHeader !== false) && (isLoading ? (
              <Grid container spacing={2} className={classes.destinationsContainer}>
                <Typography gutterBottom variant="inherit" style={{ marginLeft: 10, padding: '10px 20px 0px 0px', fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block', width: '100%' }}>
                  <h2 className={classes.h2}>
                    <Skeleton variant="rect" height={25} width={'50%'} className={classes.skeleton} />
                    <small className={classes.subtitle}><Skeleton variant="rect" height={20} className={classes.skeleton} /></small>
                  </h2>
                </Typography>
              </Grid>
            ) : (
              <Grid container spacing={2} className={classes.destinationsContainer}>
                <Typography gutterBottom variant="inherit" style={{ marginLeft: 10, padding: '10px 20px 0px 0px', fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block', width: '100%' }}>
                  <h2 className={classes.h2}>
                    {link_to_all ? <Link to={link_to_all} className={classes.h2}>{title}</Link> : `${title}`}
                    {showLinkToAll && link_to_all && <Link to={link_to_all} className={classes.viewAll}>Explore all <Icon  className="notranslate">chevron_right</Icon></Link>}
                    <small className={classes.subtitle}>{description}</small>
                  </h2>
                </Typography>
              </Grid>
            ))}

              {(isLoading ? Array.from(new Array(pageSize)) : destinations).map((destination, i) => {
                return <DestinationCard
                  key={destination ? `${destination.name},${destination.country_id},${i}` : i}
                  destination={destination && destination.destination}
                  id={destination && destination.id}
                  name={destination && destination.name}
                  type={destination && destination.type}
                  latitude={destination && destination.coordinates && destination.coordinates.latitude}
                  longitude={destination && destination.coordinates && destination.coordinates.longitude}
                  images={destination && destination.images}
                  intro={destination && destination.intro}
                  distance={destination && destination.distance}
                  country={destination && destination.country_id}
                  score={destination && destination.score}
                  slug={destination && destination.slug}
                  travel={destination && destination.travel}
                  trip_id={destination && destination.trip_id}
                  weather={destination && destination.weather}
                  link={destination && destination.link}
                  link_to_save={destination && destination.link_to_save}
                  visited={destination && destination.visited}
                  saved={destination && destination.saved}
                  saves={destination && destination.saves}
                  checked={destination && checked_destinations && checked_destinations.includes(destination && destination.id)}
                  travel_plan_id={this.props.travel_plan_id}
                  group_id={this.props.group_id}
                  created_at={destination && destination.created_at}
                  refunded={destination && destination.refunded}
                  planned={destination && destination.planned}
                  members={members}
                  member_id={member_id}
                  showHeader={destination && !["search-history"].includes(key) ? true : false}
                  loading={(!destination)}
                  onPreview={this.props.onPreview}
                  onChecked={this.props.onChecked}
                  onSelected={this.props.onSelected}
                  locationFrom={(this.props.locationFrom ? this.props.locationFrom : 'nearby')}
                  openInNewWindow={openInNewWindow} />
              })}
              {/* {destinations.length > 0 && this.state.has_next_page &&
                <Grid item xs={12}>
                  <Button
                    component="a"
                    variant="outlined"
                    size="large"
                    className={classes.buttonShowAll}
                    onClick={this.handleNextPage}>
                    {isLoadingNextPage ? 'Loading...': 'Show more'}
                  </Button>
                </Grid>
              } */}
            {/* </Grid> */}
          {/* </div> */}
        </Fragment>
      </Fragment>
    )
  }
}

const styles = {
  destinationsContainer: {
    margin: '0px',
  },
  buttonShowAll: {
    color: '#02c39a',
    textDecoration: 'none',
    margin: '27px auto',
    display: 'block',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 600,
    padding: '13px 20px 13px 27px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '1.5px solid #02c39a',
    boxShadow: 'none',
    width: 135,

    '&:hover': {
      boxShadow: 'none',
    }
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#828282',
    display: 'block',
    marginTop: 10,
  },
  viewAll: {
    color: '#696969',
    textDecoration: 'none',
    float: 'right',
    margin: '7px 0px',
    display: 'block',
    fontSize: '60%',
    fontWeight: '500',
    padding: '7px 5px 5px 15px',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    border: '2px solid #6969699e',

    '& .material-icons': {
      padding: 0,
      margin: 0,
      verticalAlign: 'middle',
    },
  },
  h2: {
    fontSize: '20px',
    marginBottom: 10,
    fontWeight: 700,
    color: '#333',
    textDecoration: 'none',
  },
  skeleton: {
    borderRadius: 25,
    marginBottom: 15,
  }
}

export default withRouter(withStyles(styles)(DestinationList))
