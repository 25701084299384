import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { kebabCase } from 'lodash'
import {
  Chip,
  Icon,
  Popper,
} from '@material-ui/core';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  iconLabel: {
    color: '#333',
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 5,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  textfield: {
    width: '100%',
    textAlign: 'left',
    // backgroundColor: '#fff',
    borderRadius: 10,
    margin: '0px 0px',
    fontSize: '13px !important',
    fontWeight: 600,

    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },

    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
  },
  autoCompleteInputRoot: {
    fontSize: 13,
    fontWeight: 600,
    padding: '0px !important',
  },
  autoCompleteInput: {
    fontSize: 13,
    fontWeight: 600,
    padding: '6px 7px !important',
  },
  autoCompletePopper: {
    fontSize: 13,
    fontWeight: 600,
    minWidth: 300,
    textAlign: 'left',
    minHeight: 40,
  },
  autoCompleteOptions: {
    fontSize: 13,
    fontWeight: 600,
    // minWidth: 300,
    textAlign: 'left',
    minHeight: 40,
  },
  autoCompleteNoOptions: {
    fontSize: 13,
    fontWeight: 600,
    // minWidth: 300,
    textAlign: 'left',
  }
}));

export const getCountry = results => {
  // console.log(results)

  let result = results[0];
  let destination = result.address_components.filter(data => data.types.includes("locality"))[0] ? result.address_components.filter(data => data.types.includes("locality"))[0].long_name : result.address_components[0].long_name
  let country = result.address_components.filter(data => data.types.includes("country"))[0]
  let state_or_region = (['administrative_area_level_1', 'administrative_area_level_2'].includes(result.address_components[0].types[0]))
  let countryName = ''
  let countryCode = ''

  if (country === undefined) {
    country = results.filter(data => data.types.includes("country"))[0]
    countryName = country !== undefined ? country.address_components[0].long_name : ''
    countryCode = country !== undefined ? country.address_components[0].short_name : ''

  } else {
    countryName = country !== undefined ? country.long_name : ''
    countryCode = country !== undefined ? country.short_name : ''
  }

  return {
    country: countryName,
    country_code: countryCode,
    location_type: (destination === countryName ? "country" : (state_or_region ? "state_or_region" : result.types[0]))
  }
}

export const geocodeByAddress = address => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const getLatLng = result => {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
};

export const geocodeByPlaceId = placeId => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};


export default function PlaceField(props) {
  const classes = {...useStyles(), ...props.classes};
  const [value, setValue] = React.useState(undefined);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState(props.defaultValues ? props.defaultValues : []);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    window.initGoogleMaps = function() {}

    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyC8XnzT-2nvbE5iLyVb7ndKJlzqtoCwKi4&v=beta&libraries=places&language=en&callback=initGoogleMaps',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;
    let types = ['locality']; //['locality', 'country']

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? (Array.isArray(value) ? value : [value]) : []);
      return undefined;
    }

    fetch({ input: inputValue, types: types }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = (Array.isArray(value) ? value : [value])
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={`autocomplet-${props.name}`}
      multiple={props.single ? !props.single : true}
      defaultValue={props.defaultValues ? (props.single ? props.defaultValues[0] : props.defaultValues) : undefined}
      style={{ textAlign: 'left', borderRadius: (props.standalone ? 25 : 10), padding: '7px !important', fontSize: '13px !important' }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      getOptionSelected={(option, value) => option.description === value.description}
      // getOptionSelected={(option, value) => {(typeof option === 'Array' ? option.filter(item => item.description === value.description) : option.description === value.description)}}
      filterOptions={(x) => { return x }}
      options={options}
      classes={{root: classes.textfield, inputRoot: classes.autoCompleteInputRoot, input: classes.autoCompleteInput, popper: classes.autoCompletePopper, option: classes.autoCompleteOptions, noOptions: classes.autoCompleteNoOptions}}
      noOptionsText="Type country, city, or a place"
      openOnFocus={false}
      autoComplete
      includeInputInList={false}
      limitTags={props.limitTags || 1}
      filterSelectedOptions
      popupIcon={false}
      blurOnSelect={true}
      size={'small'}
      // open={true}
      disableClearable={props.single ? !props.single : true}
      // value={value}
      onChange={(event, newValue) => {
        let newValueArray = (Array.isArray(newValue) ? newValue : [newValue])
        setOptions(newValue ? [...newValueArray, ...options] : options);

        if (newValueArray.length > 0) {
          newValueArray.filter((m) => m && m.location === undefined).forEach((match) => {
            let results = []

            return geocodeByPlaceId(match.place_id)
            .then((res) => {
              results = res
              return getLatLng(res[0])
            })
            .then(({ lat, lng }) => {
              match.location = {
                description: match.description,
                address: match.description,
                place: match.structured_formatting.main_text,
                latitude: lat,
                longitude: lng,
                ...getCountry(results)
              }
              match.location.slug = (match.location.country_code === 'US' && match.description.split(', ').length > 2 ? `${kebabCase(match.description.split(', ')[0])},${kebabCase(match.description.split(', ').slice(-2)[0])},${kebabCase(match.location.country)}` : `${kebabCase(match.description.split(', ')[0])},${kebabCase(match.location.country)}`)

              return match
            })
            .catch(e => {
              // console.log(e);
            });
          })
        }

        setValue((Array.isArray(newValue) ? newValueArray : newValueArray[0]));
        props.onChange({target: {name: props.name, value: (Array.isArray(newValue) ? newValueArray : newValueArray[0])}})
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      PopperComponent={(params) => (
        <Popper {...params} placement="bottom-start"/>
      )}
      renderInput={(params) => (
        <div style={{padding: (props.standalone ? '10px 7px 5px 7px' : '10px 10px 10px')}}>
          <div className={classes.iconLabel}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>{props.icon || 'place'}</Icon>{props.label || 'To'}</div>
          <TextField {...params}
          id="origin"
          placeholder={props.placeholder || 'Where are you going'}
          variant="outlined"
          name="location"
          autoFocus={props.autofocus ? true : false}
          InputProps={{
            ...params.InputProps,
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <Icon  className="notranslate">blank</Icon>
            //   </InputAdornment>
            // ),
          }}
          />
        </div>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip style={{backgroundColor: '#07c39942', border: 'none'}} label={(typeof option === 'string' ? option : option.description)} {...getTagProps({ index })} />
        ))
      }
      renderOption={(option) => {
        if (option && option.structured_formatting) {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center" className={classes.autoCompleteOptions}>
              <Grid item>
                <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>place</Icon>
              </Grid>
              <Grid item xs style={{ textAlign: 'left' }}>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 500, fontSize: 13, textAlign: 'left' }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary" style={{fontWeight: 500, fontSize: 13, color: '#828282'}}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }
      }}
    />
  );
}