import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { PROGRAMS } from '../../utils/lists'

class PlannerPricing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      account_plan: 'free',
    }
  }

  submitRequest = () => {
    this.props.onUpdated({account_plan: this.state.account_plan})
  }

  render() {
    const { classes, user } = this.props

    return (
      <Fragment>
        <Grid item xs={12} md={8} style={{marginBottom: 10, marginTop: 10, maxWidth: 700}}>
          <List className={classes.menu}>
          {PROGRAMS.slice(0, 3).map((program, i) => {
            return <ListItem button disabled={program.disabled}
                    key={program.value}
                    onClick={() => this.setState({account_plan: program.value})}
                    classes={{root: (this.state.account_plan === program.value ? classes.listItemSelected : classes.listItem)}}
                    >
              <ListItemAvatar classes={{root: classes.listItemAvatar}}>
                <Icon className="material-icons-outlined notranslate">{this.state.account_plan === program.value ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
              </ListItemAvatar>
              <ListItemText
                primary={program.title}
                secondary={program.description}
                classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}}
              />
              <ListItemSecondaryAction onClick={() => this.setState({account_plan: program.value})} classes={{root: classes.listItemTextPrice}}>

                {<div style={{maxWidth: 150, display: 'inline-block', whiteSpace: 'normal', textAlign: 'right', fontWeight: 900, float: 'right', verticalAlign: 'top', opacity: (program.disabled ? 0.3 : 1)}}>
                  {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 2, currency: this.state.currency || 'USD'}).format(program.costs)}{program.pricePer ? ' /' + program.pricePer : ''}
                </div>}
                {program.original_price && <div style={{color: '#828282', fontSize: '97%', fontWeight: 700, maxWidth: 100, display: 'block', whiteSpace: 'normal', textAlign: 'right', verticalAlign: 'middle', textDecoration: 'line-through'}}>
                  {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 2, currency: this.state.currency || 'USD'}).format(program.original_price)}
                </div>}
              </ListItemSecondaryAction>
            </ListItem>
          })}
          </List>

          {/* <h3 className={classes.subtitle} style={{textAlign: 'center'}}>
            <Icon className="material-icons-outlined notranslate" style={{verticalAlign: 'middle', padding: '0px 5px'}}>workspace_premium</Icon>
            7-day money back guarantee
          </h3> */}
        </Grid>

        <Grid item xs={12} md={8} className={classes.bottomBar}>
          <Button disabled={!(this.state.account_plan) || this.props.isSubmitting} onClick={this.submitRequest} variant="contained" color="primary" size="large" className={classes.buttonBook}>
            {this.props.isSubmitting ?
              <Fragment>Sending...</Fragment>
              :
              <Fragment>Confirm</Fragment>
            }
          </Button>
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  subtitle: {
    fontWeight: 800,
    fontSize: '14px !important',
    // fontSize: '12px',
    color: '#02c39a',
    display: 'block',
    margin: '0px 0px 30px 0px',
  },
  listItemIcon: {
    color: '#02c39a',
  },
  listItemAvatar: {
    minWidth: 40,

    '& .material-icons': {
      color: '#02c39a',
      fontWeight: 300,
      verticalAlign: 'middle',
      margin: '-3px 0px 0px 0px',
      fontSize: '18px',
    }
  },
})

export default withRouter(withStyles(styles)(PlannerPricing))
