import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import Cookies from 'universal-cookie'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Icon,
  List,
  Hidden,
  Typography,
  Grid,
  Paper,
  withStyles,
} from '@material-ui/core';
import {
  TimelineSeparator,
  TimelineDot,
} from '@material-ui/lab'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { AvatarGroup, Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import TripHeader from './TripHeader'
import TripBookingBar from './TripBookingBar'
import TripBookingBox from './TripBookingBox'
import Itinerary from '../itinerary/Itinerary'
import SearchPanel from '../SearchPanel'
import PlannerTravel from '../../containers/planner/PlannerTravel'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

class TripItinerary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editable: false,
      isLoading: false,
      refunded: false,
      itinerary: [],
      destination: props.destination,
      search_params: null,
      planned: props.planned || false,
      isRefundable: null,
      title: null,

      rating: null,
      ratingHover: null,

      error: null,
      error_message: null,
      programIntervalId: null,
    }
  }

  componentDidMount() {
    if (!this.state.isLoading) {
      this.fetchTrip(this.props.trip_id)
    }
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }

  storeTrip(data) {
    let params = {
      title: data.trip.title,
      isLoading: false,
      isRefreshing: !(data.trip.planned || (data.trip.is_refunded && this.props.user.is_admin)),
      isRefundable: data.is_creator && (data.trip.is_refundable || false),
      isPaid: data.trip.is_paid,
      refunded: data.trip.is_refunded,
      error: data.error,
      editable: (data.is_creator ? true : false),
      planned: data.trip.planned,
      itinerary: data.itinerary,
      flight: data.trip.flight,
      hotel: data.trip.hotel,
      search_params: data.search_params,
      type: data.trip.type,
      currency: data.search_params.currency,
      destination: {
        from: data.trip.from && data.trip.from.city,
        from_airports: data.trip.from && data.trip.from.airports,
        to: data.trip && data.trip.to,
        to_airports: data.trip.to && data.trip.to.airports,
        to_longitude: data.trip.to && data.trip.to.longitude,
        to_latitude: data.trip.to && data.trip.to.latitude,
        start_date: data.destination && data.destination.start_date,
        end_date: data.destination && data.destination.end_date,
        nights_in_dest: data.destination && data.destination.nights_in_dest,
        adults: data.search_params.adults,
        children: data.search_params.children,
        infants: data.search_params.infants,
        images: data.trip && data.trip.images,
        intro: data.destination && data.destination.intro,
        score: data.destination && data.destination.score,
        saved: data.saved,
        link_to_save: data.link_to_save,
        slug: data.destination && data.destination.slug,
      },
    }

    this.props.onItineraryUpdated({ destination: params['destination'], flight: params['flight'], hotel: params['hotel'] })
    this.setState(params)
  }

  fetchTrip(trip_id) {
    this.setState({isLoading: true})
    fetch(API + `/trips/${trip_id ? trip_id : ''}?`
    ,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.trip) {
        this.storeTrip(data)
        this.setState({isLoading: false})

        if (this.props.afterPayment) {
          this.props.history.replace(`/trips`)
          this.props.history.push(this.props.sharableLink)
        }
        this.props.history.replace(this.props.sharableLink)
      } else {
        this.setState({isLoading: false, planned: true, refunded: data.refunded, error: data.error})
      }
    })
  }

  refundTrip(trip_id, feedback_message) {
    fetch(API + `/ai/planner/${trip_id}/refund`
    ,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      dataType: 'json',
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify({
        reason: feedback_message
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.refunded) {
        this.setState({refunded: data.refunded, error: data.error, error_message: data.error_message})
      } else {
        this.setState({refunded: data.refunded, error: data.error, error_message: data.error_message})
      }
    })
  }

  updateItinerary = (itinerary = {}, destination = {}, is_paid = false, planned = false, error = null) => {
    this.setState({itinerary: itinerary, destination: {...this.state.destination, ...destination}, planned: planned, isPaid: is_paid, error: error})
  }

  onUpdated = (value) => {
    const user_jwt_token = cookies.get('jwt')

    if (value.travel) {
      const travel = value.travel || {}
      const dates = travel.dates || []

      const from = (travel.from != null && travel.from.location) ? [travel.from.location] : travel.from.flat().filter(f => f.location).map(f => f.location)
      const to = (travel.to != null && travel.to.location) ? [{...travel.to.location, dates: travel.dates[0]}] : travel.to.flat().filter(f => f.location).map((f, i) => { return {...f.location, dates: travel.dates[i]} })

      if (user_jwt_token !== undefined) {
        fetch(config.api.TRAVEL_URL + `/ai/planner/${this.props.trip_id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user_jwt_token}`,
          },
          dataType: 'json',
          body: JSON.stringify({
            trip: {
              search_param_attributes: {
                ...travel.travelers,
                ...{from: from, to: (to || {}), budget: travel.budget, currency: travel.currency},
              },
            }
          })
        })
        .then(response => response.json())
        .then(data => {
          if (data.trip_id) {
            this.setState({error: null})
          }
          if (data.errors) {
            this.setState({error: data.error})
          }
        })
      }
    }
  }

  render() {
    const { classes, trip_id, user, sharableLink, hideHeader, travel_plan_id } = this.props;
    const { itinerary, destination, search_params, error, error_message, planned, isRefreshing, isPaid, title, isLoading, refunded, editable } = this.state
    const adults = (destination && destination.adults) || 1
    const children = (destination && destination.children) || 0
    const travelers = adults + children


    return(
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} id="top_trip_container" style={{margin: 'auto', paddingBottom: 70}}>

            <Fragment>
              {!hideHeader && <TripHeader
                adults={destination && destination.adults}
                children={destination && destination.children}
                destination={this.state.destination || this.state.destinationDetails}
                endDate={destination && destination.end_date}
                infants={destination && destination.infants}
                isLoading={!destination}
                nights={destination && destination.nights_in_dest}
                score={destination && destination.score}
                saved={destination && destination.saved}
                link_to_save={destination && destination.link_to_save}
                searchParams={search_params || {}}
                sharableLink={sharableLink}
                startDate={destination && destination.start_date}
                to={destination ? destination.to : this.props.locationTo}
                title={title}
                plan={this.props.plan}
                travel_plan_id={this.props.travel_plan_id}
                // hideGallery={config.env === "development"}
                hideGallery={true}
                onSelectedTab={this.handleTabSelection}
                user={this.props.user}
              />}

              <Hidden mdUp>
                {!isLoading && !refunded && <TripBookingBox
                  itinerary={this.state.itinerary}
                  start_date={this.state.destination && this.state.destination.start_date}
                  end_date={this.state.destination && this.state.destination.end_date}
                  nights={this.state.destination && this.state.destination.nights_in_dest}
                  isRefundable={this.state.isRefundable}
                  travelers={travelers}
                  currency={this.state.currency}
                  onRefund={this.refundTrip.bind(this, trip_id)}
                />}
              </Hidden>

              {/* {!isLoading && !error && !isPaid && editable && <Grid item xs={12} sm={12} md={12} id="top_container" style={{margin: 'auto'}}>
                <div className={classes.travelStyleContainer} style={{marginBottom: 0, marginTop: 0}}>
                  <Alert
                    severity="warning"
                    style={{margin: '0px auto 20px auto', textAlign: 'left', borderRadius: 15, fontWeight: 500}}
                  >
                    <AlertTitle style={{fontWeight: 700, fontSize: 15}}>Payment has not been received yet</AlertTitle>
                    <div>It might take few minutes to receive the payment.</div>
                  </Alert>
                </div>
              </Grid>} */}

              {error && <Grid item xs={12} sm={12} md={12} id="top_container" style={{margin: 'auto'}}>
                <div className={classes.travelStyleContainer} style={{marginBottom: 0, marginTop: 0}}>
                  <Alert
                    severity="error"
                    style={{margin: 'auto', textAlign: 'left', borderRadius: 15, fontWeight: 500}}
                  >
                    <AlertTitle style={{fontWeight: 700, fontSize: 15}}>{error}</AlertTitle>
                    <div>{error_message}</div>
                  </Alert>
                </div>
              </Grid>}

              <div className={classes.route}>
                {error && !refunded && !isLoading && <div style={{padding: '30px 0px 0px 0px', zIndex: 2, position: 'relative'}}>
                  <TimelineSeparator>
                    <TimelineDot classes={{root: classes.itineraryIconRoot}} style={{backgroundColor: "#fff", borderColor: "#eee"}}>
                        <Icon className={`${classes.icon} material-icons-outlined notranslate`}>place</Icon>
                    </TimelineDot>
                  </TimelineSeparator>

                  <Box className={classes.itineraryRoot}>
                    <PlannerTravel user={this.props.user} onUpdated={this.onUpdated} compact={true} multiple={true} classes={this.props.classes}
                      // travel={search_params ? search_params : null}
                    />
                  </Box>
                </div>}

                {/* <div style={{padding: '50px 0px 0px 0px', zIndex: 2, position: 'relative'}}>
                  <SearchPanel
                    planner={false}
                    center={false}
                    fieldFromMultiple={true}
                    hideFieldTo={true}
                    hideSearchButton={false}
                    show_description={true}
                    expandable={this.isSize('xs')}
                    search_params={this.props.search_params}
                    // onSearch={this.props.onSearch}
                  />
                </div> */}

                {/* {!isLoading && !refunded && <TripBookingBox
                  itinerary={this.state.itinerary}
                  start_date={this.state.destination && this.state.destination.start_date}
                  end_date={this.state.destination && this.state.destination.end_date}
                  nights={this.state.destination && this.state.destination.nights_in_dest}
                  isRefundable={this.state.isRefundable}
                  travelers={travelers}
                  currency={this.state.currency}
                  onRefund={this.refundTrip.bind(this, trip_id)}
                />} */}



                {/* Don't render itinerary when travel plan and loading */}
                {(!travel_plan_id || (travel_plan_id && !isLoading)) && ((!error && !refunded) || user.is_admin) && <Itinerary
                  // editable={this.state.editable}
                  // editable={true}
                  itinerary={itinerary}
                  itineraryType={"Trip"}
                  itineraryId={trip_id}
                  itineraryUrl={sharableLink}
                  isRefreshing={isRefreshing || false}
                  destination={destination}
                  user={user}
                  search_params={search_params}
                  itineraryLoaded={!isLoading}
                  onItineraryUpdated={this.updateItinerary}
                  onSignin={this.props.onSignin}
                  onSearch={this.props.onSearch}
                />}
              </div>

            </Fragment>
          </Grid>

          <Hidden smDown>
            <Grid item xs={12} sm={12} md={4}>


              <div className="booking-container sticky">
                {!isLoading && !refunded && <TripBookingBox
                  itinerary={this.state.itinerary}
                  start_date={this.state.destination && this.state.destination.start_date}
                  end_date={this.state.destination && this.state.destination.end_date}
                  nights={this.state.destination && this.state.destination.nights_in_dest}
                  isRefundable={this.state.isRefundable}
                  travelers={travelers}
                  currency={this.state.currency}
                  onRefund={this.refundTrip.bind(this, trip_id)}
                />}
              </div>
            </Grid>
          </Hidden>

          {/* <Hidden mdUp> */}
          {/* {itinerary.length > 0 && <TripBookingBar
              isLoading={this.state.isLoading}
              trip_id={trip_id}
              user={user}
              destination={destination}
              flight={flight}
              hotel={hotel}
              hotelBookingLink={hotel && (hotel.booking_url + (hotel.booking_url.includes("?") ? "&" : "?") + "checkIn=" + destination.start_date + "&checkOut=" + destination.end_date + "&adults=" + destination.adults + "&children=" + destination.children)}
              search_params={search_params}
            />} */}
          {/* </Hidden> */}
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  route: {
    position: 'relative',
    maxWidth: 750,

    '&::before': {
      position: 'absolute',
      zIndex: 1,
      left: 31,
      width: 2,
      content: '""',
      top: -5,
      height: '100%',
      background: '#f2f2f2',
    }
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px auto',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: 15,
    width: '100%',
    maxWidth: '600px',
    display:'block',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  icon: {
    verticalAlign: 'middle !important',
    padding: '4px !important',
    fontSize: 18,
  },
  itineraryIconRoot: {
    float: 'left',
    zIndex: 3,
    position: 'absolute',
    left: 13,
    display: 'block',
    marginTop: 10,
    color: '#333',
    backgroundColor: '#c6e8c9',
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  itineraryRoot: {
    margin: '-20px 0px 0px 0px',
    marginLeft: "65px !important",
    width: 'auto',
    padding: 5,
    position: 'relative',
    fontSize: '14px',
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      margin: '60px 0px 0px 0px',
      marginLeft: "0px !important",
    },
  },
});

export default withRouter(withStyles(styles)(withWidth()(TripItinerary)));