import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { OCCASIONS } from '../../utils/lists'
import { withStyles } from '@material-ui/core/styles'

class PlannerUpgrade extends Component {
  constructor(props) {
    super(props)

    this.state = {
      occasion: null,
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <Grid item xs={12} md={8} style={{marginBottom: 80, maxWidth: 700}} >
          {/* <h3 className={classes.h3}>Is there a special occasion ?</h3> */}
          <List className={classes.menu}>
          {OCCASIONS.map((occasion, i) => {
            return <ListItem button
                    disabled
                    key={occasion.value}
                    onClick={() => this.setState({occasion: occasion.value})}
                    classes={{root: (this.state.occasion === occasion.value ? classes.listItemSelected : classes.listItem)}}
                    >
                <ListItemIcon><Icon className={`material-icons-outlined notranslate ${classes.listItemIcon}`}>{occasion.icon}</Icon></ListItemIcon>
                <ListItemText primary={occasion.title} secondary={this.state.occasion === occasion.value ? occasion.description : null} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
                <ListItemSecondaryAction disabled onClick={() => this.setState({occasion: occasion.value})} classes={{root: classes.listItemTextPrice}}>
                  <Icon className="material-icons-outlined notranslate">{this.state.occasion === occasion.value ? 'radio_button_checked' : 'radio_button_unchecked'}</Icon>
                </ListItemSecondaryAction>
            </ListItem>
          })}
          </List>
        </Grid>
        <Grid item xs={12} md={8} className={classes.bottomBar}>
          <Button onClick={() => this.props.history.push('/pricing')} variant="contained" color="primary" size="large" className={classes.buttonBook}>
            <Fragment>Upgrade<Icon className="material-icons-outlined notranslate" style={{marginLeft: 10, marginBottom: -7}}>east</Icon></Fragment>
          </Button>
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(PlannerUpgrade))
