import React, { Component } from 'react'
import {
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { authenticate } from '../../utils/authentication';
import { config } from '../../config'

const API = config.api.TRAVEL_URL

class FacebookSignInButton extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleAuth = () => {
    authenticate({
      provider: 'facebook',
      url: `${API}/users/auth/facebook?source_url=${window.location.origin}`,
      cb: this.props.onSignin,
    })
  };

  render() {
    const { classes } = this.props;

    return(
      <div role="tab" className={classes.root}>
        <Button variant="outlined" color="primary" onClick={this.handleAuth} disableElevation className={classes.buttonWhite}>
          <img src={'/logos/f-logo.png'} width={32} height={32} style={{display: 'block', position: 'relative', marginRight: 10}}/>
          Sign in with Facebook
        </Button>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    top: '4px !important',
    // left: '1px !important',
    // textAlign: 'left',
    zIndex: 100,
  },
  buttonWhite: {
    width: '100%',
    maxWidth: 393,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 12,
    color: '#333',
    backgroundColor: '#fff',
    marginTop: 14,
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: 13,
    padding: 10,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      borderWidth: 1,
      borderColor: '#ddd',
      // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
});

export default withStyles(styles)(FacebookSignInButton);