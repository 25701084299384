import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import SelectorOption from '../../components/chat/SelectorOption'
import SliderOption from '../../components/chat/SliderOption'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import { THINGS_TO_DO, CUISINES } from '../../utils/lists'

const theme = {
  background: '#fff',
  fontFamily: 'Manrope,Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
  headerBgColor: '#02c39a',
  headerFontColor: '#333',
  headerFontSize: '15px',
  botBubbleColor: 'rgb(250, 250, 250)',
  botFontColor: '#333',
  botFontSize: '14px',
  userBubbleColor: 'rgba(7, 195, 153, 0.26)',
  userFontColor: '#000',
};

class PlannerQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preferences: props.preferences || {},
    }
  }

  submitRequest = ({ steps, values }) => {
    const test = values.reduce(((r, c) => Object.assign(r, c)), {})
    const preferences = values
    this.setState({preferences: preferences}, this.props.onUpdated({preferences: preferences}))
  }

  getUser = () => {
    return this.props.user
  }

  render() {
    const { classes } = this.props
    const { preferences } = this.state
    const steps = [

      // Accommodation Preferences
      {
        id: '11',
        message: "What's your preferred accommodation type?",
        trigger: '12',
        hideInput: true,
      },
      {
        id: '12',
        component: <SelectorOption name={"accommodation_type"} selected={preferences.accommodation_type} list={[
          {icon: 'hotel', value: 'hotel', title: 'Hotel', imageSrc: '/accommodation/hotel.jpg'},
          {icon: 'apartment', value: 'apartment', title: 'Apartment', imageSrc: '/accommodation/apartment.jpg'},
          {icon: 'single_bed', value: 'hostel', title: 'Hostel', imageSrc: '/accommodation/hostel.jpg'},
          {icon: 'beach_access', value: 'resort', title: 'Resort', imageSrc: '/accommodation/resort.jpg'},
          {icon: 'villa', value: 'villa', title: 'Villa', imageSrc: '/accommodation/villa.jpg'},
          {icon: 'cabin', value: 'cabin', title: 'Cabin', imageSrc: '/accommodation/cabin.jpg'},
          {icon: 'festival', value: 'camping', title: 'Camping', imageSrc: '/accommodation/camping.jpg'},
          {icon: 'rv_hookup', value: 'rv', title: 'RV/Campervan', imageSrc: '/accommodation/rv.jpg'}
        ]} showImages={true} multiple={true} maxSelected={6}/>,
        waitAction: true,
        delay: 0,
        trigger: '13',
        hideInput: true,
      },
      {
        id: '13',
        message: "What type of accommodation amenities are most important to you?",
        trigger: '14',
        hideInput: true,
      },
      {
        id: '14',
        component: <SelectorOption name={"accommodation_amenities"} selected={preferences.accommodation_amenities} list={[
          {icon: 'wifi', value: 'wifi', title: 'Free Wi-Fi', imageSrc: '/amenities/wifi.jpg'},
          {icon: 'pool', value: 'pool', title: 'Swimming Pool', imageSrc: '/amenities/pool.jpg'},
          {icon: 'fitness_center', value: 'gym', title: 'Fitness Center', imageSrc: '/amenities/gym.jpg'},
          {icon: 'restaurant', value: 'restaurant', title: 'On-site Restaurant', imageSrc: '/amenities/restaurant.jpg'},
          {icon: 'local_parking', value: 'parking', title: 'Free Parking', imageSrc: '/amenities/parking.jpg'},
          {icon: 'ac_unit', value: 'ac', title: 'Air Conditioning', imageSrc: '/amenities/ac.jpg'},
          {icon: 'spa', value: 'spa', title: 'Spa Services', imageSrc: '/amenities/spa.jpg'},
          {icon: 'room_service', value: 'room_service', title: 'Room Service', imageSrc: '/amenities/room_service.jpg'},
          {icon: 'local_laundry_service', value: 'laundry', title: 'Laundry Facilities', imageSrc: '/amenities/laundry.jpg'},
          {icon: 'kitchen', value: 'kitchen', title: 'In-room Kitchen/Kitchenette', imageSrc: '/amenities/kitchen.jpg'},
          {icon: 'balcony', value: 'balcony', title: 'Private Balcony/Terrace', imageSrc: '/amenities/balcony.jpg'},
          {icon: 'meeting_room', value: 'business_center', title: 'Business Center', imageSrc: '/amenities/business_center.jpg'},
          {icon: 'family_restroom', value: 'family_services', title: 'Family/Child Services', imageSrc: '/amenities/family_services.jpg'},
          {icon: 'pets', value: 'pet_friendly', title: 'Pet-Friendly', imageSrc: '/amenities/pet_friendly.jpg'},
          {icon: 'local_bar', value: 'bar', title: 'Bar/Lounge', imageSrc: '/amenities/bar.jpg'},
          {icon: 'beach_access', value: 'beach_access', title: 'Beach Access', imageSrc: '/amenities/beach_access.jpg'},
          {icon: 'local_cafe', value: 'breakfast', title: 'Free Breakfast', imageSrc: '/amenities/breakfast.jpg'},
          {icon: 'elevator', value: 'elevator', title: 'Elevator', imageSrc: '/amenities/elevator.jpg'},
          {icon: 'hot_tub', value: 'hot_tub', title: 'Hot Tub', imageSrc: '/amenities/hot_tub.jpg'},
          {icon: 'security', value: '24hr_security', title: '24-hour Security', imageSrc: '/amenities/security.jpg'}
        ]} showImages={false} multiple={true} maxSelected={8}/>,
        waitAction: true,
        delay: 0,
        trigger: '15',
        hideInput: true,
      },

      // Activities and Experiences
      {
        id: '15',
        message: "What activities are you most interested in during your trip?",
        trigger: '16',
        hideInput: true,
      },
      {
        id: '16',
        component: <SelectorOption name={"city_activities"} selected={preferences.city_activities} list={[
          {icon: 'museum', value: 'cultural', title: 'Museums & Galleries', imageSrc: '/activities/museums.jpg'},
          {icon: 'restaurant', value: 'culinary', title: 'Food Tours & Restaurants', imageSrc: '/activities/food_tours.jpg'},
          {icon: 'theater_comedy', value: 'theater', title: 'Theater & Performing Arts', imageSrc: '/activities/theater.jpg'},
          {icon: 'local_bar', value: 'nightlife', title: 'Nightlife & Bars', imageSrc: '/activities/nightlife.jpg'},
          {icon: 'shopping_bag', value: 'shopping', title: 'Shopping & Markets', imageSrc: '/activities/shopping.jpg'},
          {icon: 'architecture', value: 'architecture', title: 'Architectural Tours', imageSrc: '/activities/architecture.jpg'},
          {icon: 'directions_walk', value: 'walking_tours', title: 'Walking Tours', imageSrc: '/activities/walking_tours.jpg'},
          {icon: 'photo_camera', value: 'photography', title: 'Urban Photography', imageSrc: '/activities/urban_photography.jpg'},
          {icon: 'park', value: 'parks', title: 'City Parks & Gardens', imageSrc: '/activities/city_parks.jpg'},
          {icon: 'history', value: 'historical', title: 'Historical Sites', imageSrc: '/activities/historical_sites.jpg'},
          {icon: 'local_cafe', value: 'cafe_culture', title: 'Cafe Culture', imageSrc: '/activities/cafe_culture.jpg'},
          {icon: 'sports_soccer', value: 'sports_events', title: 'Sports Events', imageSrc: '/activities/sports_events.jpg'},
          {icon: 'festival', value: 'festivals', title: 'Urban Festivals & Events', imageSrc: '/activities/urban_festivals.jpg'},
          {icon: 'local_movies', value: 'cinema', title: 'Cinema & Film', imageSrc: '/activities/cinema.jpg'},
          {icon: 'brush', value: 'street_art', title: 'Street Art Tours', imageSrc: '/activities/street_art.jpg'},
          {icon: 'wine_bar', value: 'wine_tasting', title: 'Wine Bars & Tastings', imageSrc: '/activities/wine_bars.jpg'},
          {icon: 'directions_bike', value: 'city_biking', title: 'City Biking', imageSrc: '/activities/city_biking.jpg'},
          {icon: 'science', value: 'science_centers', title: 'Science Centers', imageSrc: '/activities/science_centers.jpg'},
          {icon: 'local_mall', value: 'malls', title: 'Shopping Malls', imageSrc: '/activities/shopping_malls.jpg'},
          {icon: 'rowing', value: 'river_activities', title: 'River/Canal Activities', imageSrc: '/activities/river_activities.jpg'},
          {icon: 'tour', value: 'popular_attractions', title: 'Popular Attractions', imageSrc: '/activities/popular_attractions.jpg'}
        ]} showImages={false} multiple={true} maxSelected={8}/>,
        waitAction: true,
        delay: 0,
        trigger: '19',
        hideInput: true,
      },
      {
        id: '19',
        message: "What type of cuisine are you most interested in experiencing?",
        trigger: '20',
        hideInput: true,
      },
      {
        id: '20',
        component: <SelectorOption name={"cuisine_preference"} selected={preferences.cuisine_preference} list={[
          {icon: 'ramen_dining', value: 'asian', title: 'Asian Cuisine'},
          {icon: 'local_pizza', value: 'mediterranean', title: 'Mediterranean Cuisine'},
          {icon: 'kebab_dining', value: 'middle_eastern', title: 'Middle Eastern Cuisine'},
          {icon: 'dinner_dining', value: 'european', title: 'European Cuisine'},
          {icon: 'lunch_dining', value: 'american', title: 'American Cuisine'},
          {icon: 'tapas', value: 'latin_american', title: 'Latin American Cuisine'},
          {icon: 'restaurant', value: 'fine_dining', title: 'Chain restaurants'},
          {icon: 'storefront', value: 'street_food', title: 'Street Food'},
          {icon: 'eco', value: 'vegetarian_vegan', title: 'Vegetarian/Vegan'},
          {icon: 'local_bar', value: 'food_and_drink', title: 'Food and Drink Tours'}
        ]} showImages={false} multiple={true} maxSelected={3}/>,
        waitAction: true,
        delay: 0,
        trigger: '23',
        hideInput: true,
      },
      {
        id: '23',
        message: "What type of souvenirs or shopping items are you most interested in?",
        trigger: '24',
        hideInput: true,
      },
      {
        id: '24',
        component: <SelectorOption name={"shopping_preference"} selected={preferences.shopping_preference} list={[
          {icon: 'palette', value: 'art_crafts', title: 'Local Art and Crafts'},
          {icon: 'wine_bar', value: 'food_drink', title: 'Local Food and Drink'},
          {icon: 'checkroom', value: 'fashion', title: 'Fashion and Clothing'},
          {icon: 'watch', value: 'jewelry', title: 'Jewelry and Accessories'},
          {icon: 'auto_stories', value: 'books', title: 'Books and Music'},
          {icon: 'home', value: 'home_decor', title: 'Home Decor'},
          {icon: 'redeem', value: 'souvenirs', title: 'Traditional Souvenirs'},
          {icon: 'storefront', value: 'markets', title: 'Local Markets'},
          {icon: 'smartphone', value: 'electronics', title: 'Electronics'},
          {icon: 'do_not_touch', value: 'no_shopping', title: 'Not interested in shopping'}
        ]} showImages={false} multiple={true} maxSelected={3}/>,
        waitAction: true,
        delay: 0,
        trigger: '25',
        hideInput: true,
      },

      // Transportation and Logistics
      {
        id: '25',
        message: "How do you prefer to get around at your destination?",
        trigger: '26',
        hideInput: true,
      },
      {
        id: '26',
        component: <SelectorOption name={"transportation"} selected={preferences.transportation} list={[
          {icon: 'directions_walk', value: 'walking', title: 'Walking', imageSrc: '/transportation/walking.jpg'},
          {icon: 'directions_bike', value: 'cycling', title: 'Cycling', imageSrc: '/transportation/cycling.jpg'},
          {icon: 'directions_bus', value: 'public_transport', title: 'Public Transport', imageSrc: '/transportation/public_transport.jpg'},
          {icon: 'directions_car', value: 'rental_car', title: 'Rental Car', imageSrc: '/transportation/rental_car.jpg'},
          {icon: 'local_taxi', value: 'taxi', title: 'Taxi/Ride-sharing', imageSrc: '/transportation/taxi.jpg'},
          {icon: 'tour', value: 'guided_tours', title: 'Guided Tours', imageSrc: '/transportation/guided_tours.jpg'},
          {icon: 'motorcycle', value: 'motorcycle', title: 'Motorcycle', imageSrc: '/transportation/motorcycle.jpg'},
        ]} showImages={false} multiple={true} maxSelected={4}/>,
        waitAction: true,
        delay: 0,
        trigger: '41',
        hideInput: true,
      },
      {
        id: '41',
        message: 'Thank you for completing our comprehensive travel preferences quiz! This information will help us personalize your perfect trip to a single destination.',
        delay: 300,
        hideInput: true,
        end: true,
      },
    ];

    const roadTripSteps = [
      // Trip Basics
      {
        id: '1',
        message: "How long would you like your road trip to be?",
        trigger: '2',
        hideInput: true,
      },
      {
        id: '2',
        component: <SelectorOption
          name="trip_duration"
          selected={preferences.trip_duration}
          list={[
            {icon: 'weekend', value: 'weekend', title: 'Weekend (2-3 days)'},
            {icon: 'date_range', value: 'short', title: 'Short Trip (4-7 days)'},
            {icon: 'calendar_month', value: 'medium', title: 'Medium Trip (8-14 days)'},
            {icon: 'event_available', value: 'long', title: 'Long Trip (15+ days)'}
          ]}
          showImages={false}
          multiple={false}
        />,
        trigger: '3',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },

      // Vehicle Preferences
      {
        id: '3',
        message: "What's your preferred mode of transport?",
        trigger: '4',
        hideInput: true,
      },
      {
        id: '4',
        component: <SelectorOption
          name="vehicle_type"
          list={[
            {icon: 'directions_car', value: 'car', title: 'Personal Car'},
            {icon: 'rv_hookup', value: 'rv', title: 'RV/Campervan'},
            {icon: 'motorcycle', value: 'motorcycle', title: 'Motorcycle'},
            {icon: 'directions_car_filled', value: 'rental', title: 'Rental Vehicle'}
          ]}
          showImages={true}
        />,
        trigger: '5',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },

      // Route Style
      {
        id: '5',
        message: "What's your preferred route style?",
        trigger: '6',
        hideInput: true,
      },
      {
        id: '6',
        component: <SelectorOption
          name="route_style"
          list={[
            {icon: 'speed', value: 'direct', title: 'Direct Route (Fastest)'},
            {icon: 'route', value: 'scenic', title: 'Scenic Routes'},
            {icon: 'explore', value: 'flexible', title: 'Flexible/Improvised'},
            {icon: 'attractions', value: 'attractions', title: 'Attractions-based'}
          ]}
        />,
        trigger: '7',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },

      // Stops & Activities
      {
        id: '7',
        message: "What kind of stops interest you most?",
        trigger: '8',
        hideInput: true,
      },
      {
        id: '8',
        component: <SelectorOption
          name="stop_preferences"
          multiple={true}
          maxSelected={5}
          list={[
            {icon: 'park', value: 'nature', title: 'Nature Spots'},
            {icon: 'restaurant', value: 'food', title: 'Local Eateries'},
            {icon: 'museum', value: 'cultural', title: 'Cultural Sites'},
            {icon: 'photo_camera', value: 'photo_ops', title: 'Photo Opportunities'},
            {icon: 'storefront', value: 'shopping', title: 'Shopping Areas'},
            {icon: 'local_cafe', value: 'cafes', title: 'Cafes & Rest Stops'},
            {icon: 'festival', value: 'events', title: 'Local Events'},
            {icon: 'hiking', value: 'outdoor', title: 'Outdoor Activities'}
          ]}
        />,
        trigger: '9',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },

      // Accommodation
      {
        id: '9',
        message: "Where do you prefer to stay overnight?",
        trigger: '10',
        hideInput: true,
      },
      {
        id: '10',
        component: <SelectorOption
          name="overnight_stays"
          multiple={true}
          maxSelected={3}
          list={[
            {icon: 'hotel', value: 'hotels', title: 'Hotels/Motels'},
            {icon: 'camping', value: 'camping', title: 'Camping'},
            {icon: 'house', value: 'airbnb', title: 'Vacation Rentals'},
            {icon: 'rv_hookup', value: 'rv_parks', title: 'RV Parks'},
            {icon: 'cottage', value: 'cabins', title: 'Cabins'},
            {icon: 'hotel', value: 'boutique', title: 'Boutique Hotels'}
          ]}
        />,
        trigger: '11',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },

      // Conclusion
      {
        id: '11',
        message: "Great! We'll use your preferences to plan the perfect road trip route and stops!",
        delay: 300,
        hideInput: true,
        end: true,
      }
    ];

    const adventureTripSteps = [
      {
        id: '1',
        message: "What type of adventure activities are you interested in?",
        trigger: '2',
        hideInput: true,
      },
      {
        id: '2',
        component: <SelectorOption
          name="adventure_activities"
          selected={preferences.adventure_activities}
          list={[
            {icon: 'hiking', value: 'hiking', title: 'Hiking'},
            {icon: 'kitesurfing', value: 'water_sports', title: 'Water Sports'},
            {icon: 'downhill_skiing', value: 'skiing', title: 'Skiing/Snowboarding'},
            {icon: 'directions_bike', value: 'biking', title: 'Biking'},
            {icon: 'paragliding', value: 'paragliding', title: 'Paragliding'},
            {icon: 'surfing', value: 'surfing', title: 'Surfing'},
            {icon: 'kayaking', value: 'kayaking', title: 'Kayaking'},
            {icon: 'rock_climbing', value: 'rock_climbing', title: 'Rock Climbing'}
          ]}
          showImages={true}
          multiple={true}
          maxSelected={5}
        />,
        trigger: '3',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },
      {
        id: '3',
        message: "What level of difficulty do you prefer for your adventure activities?",
        trigger: '4',
        hideInput: true,
      },
      {
        id: '4',
        component: <SelectorOption
          name="difficulty_level"
          list={[
            {icon: 'easy', value: 'easy', title: 'Easy'},
            {icon: 'moderate', value: 'moderate', title: 'Moderate'},
            {icon: 'hard', value: 'hard', title: 'Hard'},
            {icon: 'extreme', value: 'extreme', title: 'Extreme'}
          ]}
          showImages={false}
          multiple={false}
        />,
        trigger: '5',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },
      {
        id: '5',
        message: "What type of accommodation do you prefer during your adventure trip?",
        trigger: '6',
        hideInput: true,
      },
      {
        id: '6',
        component: <SelectorOption
          name="adventure_accommodation"
          list={[
            {icon: 'camping', value: 'camping', title: 'Camping'},
            {icon: 'cabin', value: 'cabin', title: 'Cabin'},
            {icon: 'hotel', value: 'hotel', title: 'Hotel'},
            {icon: 'hostel', value: 'hostel', title: 'Hostel'},
            {icon: 'rv_hookup', value: 'rv', title: 'RV/Campervan'}
          ]}
          showImages={true}
          multiple={true}
          maxSelected={3}
        />,
        trigger: '7',
        waitAction: true,
        delay: 0,
        hideInput: true,
      },
      {
        id: '7',
        message: "Great! We'll use your preferences to plan the perfect adventure trip!",
        delay: 300,
        hideInput: true,
        end: true,
      }
    ];

    const stepsMap = {
      'road_trip': roadTripSteps,
      'activity_trip': adventureTripSteps,
      // Add more trip types here
    };

    const selectedSteps = stepsMap[this.props.tripType] || steps;

    return (
      <Fragment>
        <div className={classes.chatContainer}>
          {/* <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}> */}
            {/* <Grid item xs={12} className={classes.showcaseDestinations}> */}
              <ThemeProvider theme={theme}>
                <ChatBot
                  handleEnd={this.submitRequest}
                  steps={selectedSteps}
                  botAvatar="/apple-touch-icon.png"
                  enableSmoothScroll={true}
                  hideHeader={true}
                  hideUserAvatar={true}
                  hideSubmitButton={true}
                  botDelay={1000}
                  userDelay={300}
                  width={'100%'}
                  style={{boxShadow: 'none', height: '100%'}}
                  avatarStyle={{boxShadow: 'none', alignItems: 'flex-start'}}
                  contentStyle={{height: (isWidthUp('sm', this.props.width) ? (isWidthUp('md', this.props.width) ? 'calc(100vh - 255px)' : 'calc(100vh - 255px)') : 'calc(100vh - 182px)')}}
                  bubbleStyle={{fontSize: '15px', fontWeight: 700, boxShadow: 'none', background: 'none', maxWidth: '80%'}}
                  bubbleOptionStyle={{fontFamily: theme.fontFamily, fontSize: '13px', fontWeight: 600, backgroundColor: 'rgba(7, 195, 153, 0.26)'}}
                  customStyle={{boxShadow: 'none', padding: '16px 0px', borderRadius: 0, borderBottom: '1px solid #ddd'}}
                />
              </ThemeProvider>
            {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  showcaseDestinations: {
    margin: '10px 0px 0px 0px',
  },
  chatContainer: {
    padding: '0px',
    paddingTop: 0,
    minHeight: 'calc(100vh - 455px)',
    backgroundColor: '#fff',
    width: 'inherit',
    borderTop: '1px solid #ddd',
    maxWidth: 700,
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(withWidth()(PlannerQuiz)))
