import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Avatar,
  IconButton,
  Button,
  Box,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  TextareaAutosize,
} from '@material-ui/core'
import {
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import DatesFilter from '../../components/filters/DatesFilter.js'
import TravelersFilter from '../../components/filters/TravelersFilter.js'
import PlaceField from '../../components/forms/PlaceField.js'
import BudgetFilter from '../../components/filters/BudgetFilter.js'
import { compact, merge } from 'lodash'

const date_defaults = {
  dates_type: 'cheapest',
  start_date: null,
  end_date: null,
  nights: [2, 4],
}


class PlannerTravel extends Component {
  constructor(props) {
    super(props)

    const travel_defaults = {
      travelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      from: [],
      dates: Array.from({length: (props.multiple ? 1 : 1)}, () => date_defaults),
      to: Array.from({length: (props.multiple ? 1 : 1)}, () => ({id: Math.random().toString(36).substr(2, 9)})),
      budget: 500,
      currency: props.user && props.user.currency || 'USD',
      number_of_destinations: props.multiple ? 1 : 1,
    }

    this.state = merge({
      travelStyles: props.user && props.user.travel_styles ? props.user.travel_styles : [],
      travelStyle: props.user && props.user.travel_styles ? props.user.travel_styles[0].id : 0,
    }, travel_defaults, props.travel || {})
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.user.travel_styles) !== JSON.stringify(this.props.user.travel_styles)) {
      this.setState({
        currency: this.props.user && this.props.user.currency || 'USD',
        travelStyles: this.props.user && this.props.user.travel_styles ? this.props.user.travel_styles : [],
        travelStyle: this.props.user && this.props.user.travel_styles ? this.props.user.travel_styles[0].id : 0,
      })
    }
  }

  handleChange = (event, index = false) => {
    if (index !== false) {
      let state = this.state[event.target.name]
      state[index] = merge(event.target.value, {id: state[index].id})
      this.setState({[event.target.name]: state});
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  addDestination = () => {
    let dates = this.state.dates
    let to = this.state.to
    dates.push(date_defaults)
    to.push({id: Math.random().toString(36).substr(2, 9)})

    this.setState({dates: dates, to: to, number_of_destinations: this.state.number_of_destinations + 1});
  }

  removeDestination = (index) => {
    let dates = this.state.dates
    let to = this.state.to
    dates.splice(index, 1);
    to.splice(index, 1);

    this.setState({dates: dates, to: to, number_of_destinations: this.state.number_of_destinations - 1});
  }

  editTravelStyle = () => {
    let state = this.state
    this.setState(state, this.props.onUpdated({travel: state}, '/planner/travel_style'))
  }

  submitRequest = () => {
    let state = this.state
    // state.to = state.to.map((to) => {
    //   delete to.id;
    //   return to;
    // })
    // state.to = state.to.filter((object) => object.description !== undefined)
    // console.log(state)
    this.props.onUpdated({travel: state})
  }

  render() {
    const { classes, user, multiple, tripType, isSubmitting, compact } = this.props
    const { travelers, dates, from, to, budget, number_of_destinations, contact, travelStyles } = this.state

    return (
      <Fragment>
      <Grid container className={classes.searchWrapper}>
        <Grid item xs={8} md={8} className={classes.fromContainer}>
        <PlaceField
          name="from"
          label="Where from? *"
          autofocus={false}
          placeholder={from && from.length > 0 ? "Where from?" : "Enter location"}
          limitTags={3}
          single={false}
          standalone={true}
          defaultValues={from}
          onChange={(event) => this.handleChange(event)}
          classes={{ iconLabel: classes.iconLabel }}
        />
        </Grid>
        <Grid item xs={4} md={4} className={classes.travelersContainer}>
        <TravelersFilter
          name="travelers"
          adults={travelers && travelers.adults}
          children={travelers && travelers.children}
          infants={travelers && travelers.infants}
          onChange={(event) => this.handleChange(event)}
          classes={{ iconLabel: classes.iconLabel }}
        />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} style={{ maxWidth: 700 }}>
        <Box className={classes.boxFieldTo}>
        {!compact && <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ padding: 10 }}>
          <Grid item xs={12} md={12} style={{ paddingTop: 5, borderRadius: 10, padding: '5px 0px', display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth variant="outlined" className={classes.travelStyleSelect}>
            <Select
              labelId='travelStyle_label'
              name="travelStyle"
              id="travelStyle"
              startAdornment={<Icon className={`material-icons-outlined notranslate`} style={{ marginRight: 5 }}>interests</Icon>}
              value={this.state.travelStyle || 0}
              onChange={(event) => this.handleChange(event)}
            >
            {travelStyles.length < 1 && <MenuItem value={0} className={classes.travelStyleMenuItem}>Create travel style</MenuItem>}
            <Divider />
            {travelStyles.map((style) => (
              <MenuItem key={style.id} value={style.id} className={classes.travelStyleMenuItem}>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <span>{style.name}</span>
              </Box>
              </MenuItem>
            ))}
            </Select>
          </FormControl>
          {(this.state.travelStyle && this.state.travelStyle !== 0) ? (
            <Button onClick={this.editTravelStyle} variant="outlined" size="small" className={classes.editTravelStyleBtn}>
              <Icon className={`material-icons-outlined notranslate`} style={{ marginRight: 5 }}>edit</Icon>
              Edit
            </Button>
          ) : null }
          </Grid>
        </Grid>}
        {to && to.map((el, index) => {
          return <Grid container key={el.id}>
          <Grid item xs={number_of_destinations > 1 ? 11 : 12} md={number_of_destinations > 1 ? 11 : 12} style={{ paddingTop: 5 }}>
            <Grid container className={classes.searchField}>
            <Grid item xs={8} md={8} style={{ paddingTop: 5 }}>
              <PlaceField
                name={`to`}
                label="Where to? *"
                autofocus={false}
                placeholder={" "}
                defaultValues={to[index] && to[index].place_id ? [to[index]] : null}
                limitTags={3}
                single={true}
                standalone={true}
                onChange={(event) => this.handleChange(event, index)}
                classes={{ iconLabel: classes.iconLabel }}
              />
            </Grid>
            <Grid item xs={4} md={4} style={{ padding: '5px 5px 5px 5px', borderLeft: '1px solid #ccc' }}>
              <DatesFilter
                name={`dates`}
                dates_type={dates[index] && dates[index].dates_type}
                start_date={dates[index] && dates[index].start_date}
                end_date={dates[index] && dates[index].end_date}
                nights={dates[index] && dates[index].nights}
                onChange={(event) => this.handleChange(event, index)}
                classes={{ iconLabel: classes.iconLabel }}
              />
            </Grid>
            </Grid>
          </Grid>
          {number_of_destinations > 1 && <Grid item xs={1} md={1} style={{ margin: 'auto' }}>
            <IconButton style={{ marginLeft: 5, padding: 5 }} onClick={() => this.removeDestination(index)}>
              <Icon className={`material-icons-outlined notranslate ${classes.iconDelete}`}>delete</Icon>
            </IconButton>
          </Grid>}
          </Grid>
        })}
        {multiple && number_of_destinations < 10 && <Button variant="contained" color="primary" size="small" disableElevation className={classes.buttonPrimary} onClick={this.addDestination} style={{ marginRight: '10px' }}>
          <Icon className={`material-icons-outlined notranslate`}>add</Icon> Add destination
        </Button>}
        </Box>
      </Grid>
      <Grid item xs={12} md={8} className={classes.bottomBar}>
        {to && from && <Button
          disabled={!(from.map(item => item.description).some(location => location !== undefined)) || !(to.map(item => item.description).some(location => location !== undefined)) || isSubmitting}
          onClick={this.submitRequest}
          variant="contained"
          color="primary"
          size="large"
          className={classes.buttonBook}
        >
        {isSubmitting
          ? <Fragment>Sending...</Fragment>
          : <Fragment>{this.state.travelStyle === 0 ? "Continue" : "Plan it"}<Icon className="material-icons-outlined notranslate" style={{ marginLeft: 10, marginBottom: -7 }}>{this.state.travelStyle === 0  ? 'east' : 'auto_awesome'}</Icon></Fragment>
        }
        </Button>}
      </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  boxFieldTo: {
    padding: 10,
    backgroundColor: "#f7f7f7",
    borderRadius: 25,
    zIndex: 1,
    position: 'relative',
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '7px 15px 7px 10px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  searchField: {
    border: '1px solid #ccc',
    borderRadius: 20,
    marginBottom: 10,
    backgroundColor: '#fff',
    zIndex: 1,
    position: 'relative',
  },
  searchWrapper: {
    marginBottom: 30,
    backgroundColor: '#fafafa',
    border: '1px solid #eee',
    borderRadius: 40,
    paddingLeft: 10,
    zIndex: 1,
    position: 'relative',
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconDelete: {
    fontSize: 20,
    fontWeight: 400,
    verticalAlign: 'middle',
  },
  textfieldArea: {
    width: 'calc(100% - 30px)',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '15px 10px 0px 5px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 10px',
    border: '1px solid #ddd',
    fontFamily: 'inherit',
    minHeight: 20,
    maxWidth: '100%',
    minWidth: '88%',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
  travelStyleSelect: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
      fontSize: 14,
      fontWeight: 600,

      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#02c39a',
      }
    },
    '& .MuiSelect-select': {
      padding: '10px 15px',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      }
    }
  },
  travelStyleMenuItem: {
    fontSize: 14,
    fontWeight: 600,
    padding: '8px 15px',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
    '&.Mui-selected': {
      backgroundColor: '#e6f7f4',
      color: '#02c39a',
      '&:hover': {
        backgroundColor: '#d9f2ee',
      }
    },
    '& .material-icons-outlined': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out'
    },
    '&:hover .material-icons-outlined': {
      opacity: 1
    }
  },
  editTravelStyleBtn: {
    marginLeft: 10,
    borderColor: '#ccc',
    color: '#666',
    textTransform: 'none',
    borderRadius: 10,
    padding: '5px 12px',
    minWidth: 'auto',
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: 'transparent',

    '&:hover': {
      color: '#333',
      borderColor: '#999',
      backgroundColor: 'transparent',
    },

    '& .material-icons-outlined': {
      fontSize: 18,
      marginRight: 5,
      verticalAlign: 'middle',
      marginTop: -2
    }
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(PlannerTravel))
