import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { kebabCase, startCase } from 'lodash'
import Cookies from 'universal-cookie'
import moment from 'moment'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActionArea,
  Chip,
  Collapse,
  Divider,
  Hidden,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Grid,
  Paper,
  Tabs,
  Tooltip,
  Typography,
  withStyles,
  ListItemIcon,
} from '@material-ui/core'
import {
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert'
// import SwipeableViews from 'react-swipeable-views'
import HotelCard from '../components/itinerary/hotel/HotelCard'
import FlightCard from '../components/itinerary/flight/FlightCard'
import TripHeader from '../components/trip/TripHeader'
import TripBookingBox from '../components/trip/TripBookingBox'
import AppTopBar from '../components/AppTopBar'
import DatesFilter from '../components/filters/DatesFilter'
import TravelersFilter from '../components/filters/TravelersFilter'
import Itinerary from '../components/itinerary/Itinerary'
import TripItinerary  from '../components/trip/TripItinerary'
import SearchPanel from '../components/SearchPanel'
import Helmet from 'react-helmet'
import { routeStopIcon } from '../utils/helpers'
import ReactPixel from 'react-facebook-pixel'
import './App.css'
import { config } from '../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} style={{padding: "0px"}}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class Trip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trip_id: this.props.trip_id,
      editable: true,
      activities: [],
      address: props.locationTo,
      destination: props.location.state ? props.location.state.destination : null,
      destinationDetails: null,
      programNearby: null,
      programWalking: null,
      isProgramSample: false,
      itinerary: [],
      isEditingItineraryItemId: null,
      flight: props.location.state ? props.location.state.flight : null,
      travel_search_params: {
        sort: 'best',
      },
      hotel: null,
      hotel_search_params: {
        sort: 'best',
        accommodation_type: '',
      },
      images: [],
      isLoading: false,
      loadingMessage: null,
      isUpdatingTrip: false,
      route: [],
      expanded: null,
      openedLocationBar: false,
      saved: this.props.saved || false,
      openedSigninDialog: false,
      openedSigninDialogType: null,
      openedBookingLinks: false,
      selectedTab: this.props.selectedTab || 'overview',
      explore: {
        selected: 'activities',
      },

      backButtonPath: props.backButtonPath,
    }
  }

  // componentDidMount() {
  //   if (!this.state.isLoading) {
  //     if (!this.state.trip_id) {
  //       // this.fetchDestination(this.props.locationTo)
  //     }
  //     this.fetchTrip(this.state.trip_id, false)
  //   }
  //   ReactPixel.pageView()
  // }

  storeTrip(data) {
    let params = {
      trip_id: data.trip.id,
      plan_confirmed_by_user: data.trip.planned,
      editable: (data.is_creator ? true : false),
      hotel: data.trip.hotel,
      hotel_price_from: data.trip.hotel_price_from,
      hotel_options: data.trip.hotel_options,
      recommended_hotels: data.trip.recommended_hotels,
      flight: data.trip.flight,
      travel_options: data.trip.travel_options,
      activities: data.activities,
      activity_options: data.activity_options,
      currency: data.search_params.currency,
      isLoading: false,
      itinerary: data.itinerary,
      destination: {
        from: data.trip.from && data.trip.from.city,
        from_airports: data.trip.from && data.trip.from.airports,
        to: data.trip.to && data.trip.to.address,
        to_airports: data.trip.to && data.trip.to.airports,
        to_longitude: data.trip.to && data.trip.to.longitude,
        to_latitude: data.trip.to && data.trip.to.latitude,
        start_date: data.destination && data.destination.start_date,
        end_date: data.destination && data.destination.end_date,
        nights_in_dest: data.destination && data.destination.nights_in_dest,
        dates_type: 'specific',
        adults: data.search_params.adults,
        children: data.search_params.children,
        infants: data.search_params.infants,
        images: this.state && data.destination ? [...(this.state.destinationDetails ? this.state.destinationDetails.images : []), ...(data.destination.images || [])] : [],
        intro: data.destination && data.destination.intro,
        score: data.destination && data.destination.score,
        saved: data.saved,
        link_to_save: data.link_to_save,
        slug: data.trip && data.trip.slug,
        weather: data.destination && data.destination.weather,
      },
      travel_search_params: data.search_params.travel_filters || {sort: 'best'},
      hotel_search_params: data.search_params.hotel_filters || {sort: 'best', accommodation_type: ''},
      saved: data.saved,
      route: data.trip.route,
      openedLocationBar: data.different_from,
      explore: {
        selected: 'activities',
      }
    }

    this.setState(params)
  }

  fetchTrip(trip_id, stay_only = false) {
    let location_from = (this.props.search_params.from ? this.props.search_params.from[0] : null)
    let location_to = (this.props.search_params.to ? this.props.search_params.to[0] : null)
    let { dates, travelers, interests, travel_type } = this.props.search_params

    let link = `/trip${(this.props.locationFrom && this.props.locationFrom !== 'nearby') ? '/' + this.props.locationFrom : ''}/to/${this.state.destination ? this.state.destination.slug : this.props.locationTo}/${this.state.trip_id}`

    window.gtag('event', 'trip', {link: link, to: this.props.locationTo, from: this.props.locationFrom, id: trip_id});

    this.setState({isLoading: true})
    fetch(API + `/trips/${trip_id ? trip_id : ''}?` +
                'location_from=' + (location_from && location_from.slug || this.props.locationFrom || 'nearby') +
                (location_from && location_from.latitude ? '&latitude_from=' + location_from.latitude : '') +
                (location_from && location_from.longitude ? '&longitude_from=' + location_from.longitude : '') +
                '&location_to=' + (location_to && location_to.slug || this.props.locationTo) +
                (location_to && location_to.place ? '&place=' + location_to.place : '') +
                (location_to && location_to.country ? '&country=' + location_to.country : '') +
                (location_to && location_to.country_code ? '&country_code=' + location_to.country_code : '') +
                (location_to && location_to.latitude ? '&latitude=' + location_to.latitude : '') +
                (location_to && location_to.longitude ? '&longitude=' + location_to.longitude : '') +
                '&adults=' + travelers.adults +
                '&children=' + travelers.children +
                '&infants=' + travelers.infants +
                '&dates_type=' + dates.dates_type +
                (dates && dates.start_date ? '&start_date=' + dates.start_date : '') +
                (dates && dates.end_date ? '&end_date=' + dates.end_date : '') +
                (dates && dates.nights ? '&nights=' + dates.nights : '') +
                (travel_type ? '&travel_type=' + travel_type : '') +
                (interests ? '&interests=' + interests : '') +
                (this.props.travel_plan_id ? '&travel_plan_id=' + this.props.travel_plan_id : '') +
                (this.props.group_id ? '&group_id=' + this.props.group_id : '') +
                (this.props.user ? '&curr=' + this.props.user.currency : '')
    ,{
      method: trip_id ? 'GET' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.trip) {
        this.storeTrip(data)

        if (this.props.trip_id && this.props.trip_id !== data.trip.id) {
          if (this.props.after_payment) {
            this.props.history.push(`/trips`)
          }
          this.props.history.push(`/trip${data.trip.from.address ? '/' + (kebabCase(data.trip.from.address.split(',')[0]) + ',' + kebabCase(data.trip.from.address.split(',').slice(-1)[0])) : ''}/to/${this.props.locationTo}/${data.trip.id}`)
        }
      } else {
        this.setState({isLoading: false})
      }
    })
  }

  fetchDestination(destination_slug) {
    let location_to = (this.props.search_params.to ? this.props.search_params.to[0] : null)

    fetch(API + `/destination/${destination_slug}?` +
      (location_to && location_to.place ? '&place=' + location_to.place : '') +
      (location_to && location_to.country ? '&country=' + location_to.country : '') +
      (location_to && location_to.country_code ? '&country_code=' + location_to.country_code : '') +
      (location_to && location_to.latitude ? '&latitude=' + location_to.latitude : '') +
      (location_to && location_to.longitude ? '&longitude=' + location_to.longitude : '')
    ,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        destinationDetails: {
          images: data.images,
          intro: data.intro,
        },
        address: (data.country ? `${data.name}, ${data.country.name}` : this.state.address),
      })
    })
  }

  fetchProgram(example) {
    this.setState({isLoading: true})
    fetch(API + `/trips/${this.state.trip_id}/program${example === true ? '?example=' + example : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        isLoading: false,
        itinerary: data.itinerary,
      })
    })
  }

  updateItinerary = (params = {}) => {
    this.setState(prevState => ({...prevState, ...params}))
  }

  updateTrip = (search_params = {}) => {
    this.setState({isLoading: true, isUpdatingTrip: true, loadingMessage: 'Updating travel details for your trip ...', showBookingMobileDialog: false})

    let params = search_params.target ? search_params.target.value : search_params
    let update_program = (params.hasOwnProperty('dates_type') || params.hasOwnProperty('start_date') || params.hasOwnProperty('nights'))

    if (params.nights) {
      params.nights = Array.isArray(params.nights) ? params.nights : [params.nights]
    }

    fetch(API + `/trips/${this.state.trip_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {
      if (data.trip) {
        this.storeTrip(data)
      }

      this.setState({isLoading: false, isUpdatingTrip: false, loadingMessage: null})
    })
  }

  scrollCarousel(direction) {
    let width = this.carouselEl.offsetWidth

    if (direction === 'left') {
      width = width * -1
    }

    this.carouselEl.scrollBy({
      left: width,
      behavior: 'smooth'
    });
  }

  handleHomeRedirect = () => {
    // this.props.onLocationCleared()
    this.setState({
      isLoading: false,
    }, this.props.history.push('/'))
  }

  updateUserPreferences = (property, params) => {
    let search_params = {...this.state[property], ...params}

    // console.log({ [property]: search_params })

    this.setState({ [property]: search_params })
    this.updateTrip({ [property]: search_params })
  }

  handleTabSelection = (event, value) => {
    this.changeTab(value);
  };

  saveTravel = (flight) => {
    const element = document.getElementById("travel")
    this.setState({flight: flight, selectedTab: 'stay', plan_confirmed_by_user: (this.state.hotel || flight)}, element.scrollIntoView({behavior: 'smooth'}))

    let data = {}

    if (flight !== null) {
      data = { travel_id: flight.id }
    } else {
      data = { without_travel: true }
    }

    fetch(API + `/trips/${this.state.trip_id}/travel`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      body: JSON.stringify(data)
    })
  };

  saveHotel = (hotel) => {
    const element = document.getElementById("stay")
    this.setState({hotel: hotel, selectedTab: 'overview', plan_confirmed_by_user: (hotel || this.state.flight)}, element.scrollIntoView({behavior: 'smooth'}))

    let data = {}

    if (hotel !== null) {
      data = { hotel_id: hotel.hotel_id }
    } else {
      data = { without_hotel: true }
    }

    fetch(API + `/trips/${this.state.trip_id}/stay`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      body: JSON.stringify(data)
    })
  };

  saveActivity = (activity, action) => {
    if (action === 'add') {
      activity.saved = true

      this.setState(prevState => ({
        activities: [...prevState.activities, activity]
      }))

    } else if (action === 'remove') {
      activity.saved = false

      this.setState(prevState => ({
        activities: prevState.activities.filter(function(a) {
          return a.id !== activity.id
        }),
        activity_options: prevState.activity_options.map(el => (el.id === activity.id ? activity : el))
      }))
    }

    fetch(API + `/trips/${this.state.trip_id}/activities`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      body: JSON.stringify({
        activity_id: activity.id
      })
    })
  }

  changeTab = (value) => {
    const element = document.getElementById("trip-container")
    this.setState({selectedTab: value}, element.scrollIntoView({behavior: 'smooth'}))
  };

  openBookActivityLink = (activity) => {
    window.gtag('event', 'Open_Booking_Link', {
      transaction_id: `activity_${this.state.trip_id}_${activity.id}`,
      value: 1,
      currency: 'USD',
      items: [{
        item_id: activity.id,
        item_name: activity.name,
        item_brand: 'GetYourGuide',
        item_category: 'Activity',
        price: 1,
      }]
    });
    ReactPixel.track('Open_Booking_Link', {
      currency: 'USD',
      value: 1
    })
  }

  renderProgramIcon(title) {
    switch(title) {
      case 'breakfast':
        return 'emoji_food_beverage';
      case 'coffee':
        return 'local_cafe';
      case 'lunch':
        return 'fastfood';
      case 'dinner':
        return 'restaurant';
      default:
        return 'place';
    }
  }

  handleChange = (event, name) => {
    this.setState({expanded: name});
  };

  handleLocationBarClose = () => {
    this.setState({openedLocationBar: false});
  }

  handleSigninChange = (flag, dialogType = null) => {
    this.setState({
      openedSigninDialog: flag,
      openedSigninDialogType: dialogType,
    })
  }

  render() {
    const { classes } = this.props;
    const { destination, destinationDetails, flight, expanded, weather, itineraryItemEdited } = this.state;
    let start_date = destination && destination.start_date && new Date(destination.start_date).toISOString().slice(0, 10)
    let end_date = destination && destination.end_date && new Date(destination.end_date).toISOString().slice(0, 10)

    let travel = (this.state.flight || (this.state.travel_options && this.state.travel_options[0]))
    let hotel = (this.state.hotel || (this.state.hotel_options && this.state.hotel_options[0]))
    const adults = (destination && destination.adults) || 1
    const children = (destination && destination.children) || 0
    const travelers = adults + children

    const { selectedTab, itinerary } = this.state

    const sharableLink = `/trip${(this.props.locationFrom && this.props.locationFrom !== 'nearby') ? '/' + this.props.locationFrom : ''}/to/${destination && destination.slug ? destination.slug : this.props.locationTo}${this.state.trip_id ? '/' + this.state.trip_id : ''}`
    const pageTitle = !destination ? startCase(this.state.address) : `Things to do in ${startCase(destination.to)} ${(destination.nights_in_dest + 1) + ' day itinerary'} ${'from ' + startCase(destination.from)}`

    const flightContainer = (departure_from, departure_type, arrival_to, arrival_type, travelers, customize) => { return <div className="flights-container" id="travel">
      <Grid
        container
        alignItems={'center'}
        justify={'center'}
      >
        {(customize || this.state.flight || (this.state.travel_options && this.state.travel_options.length > 0)) && <Paper className={`${classes.paper} ${classes.itineraryRail}`} elevation={0} style={{boxShadow: 'none', backgroundColor: '#fff'}}>
          <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.header}>
            {/* <Grid item xs={12}>
              {false && travelPrice > 0 && <div className={classes.columnRight}>
                <IconButton aria-label="more" style={{float: 'right', margin: '4px 4px -4px 5px'}}>
                  <Icon className="notranslate">more_vert</Icon>
                </IconButton>

                <Typography className={classes.sectionPrice}>
                  {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.state.currency || 'USD'}).format(Math.round(travelPrice / travelers))}
                  <small> / person</small>
                </Typography>
              </div>}

              <Typography className={classes.section}>
                <Icon className={classes.icon}>local_play{routeStopIcon(departure_type)}</Icon>
                {departure_from}
                Travel
              </Typography>
            </Grid> */}

            {customize && <Grid item xs={12} className={classes.actions}>
              <Fragment>
                <Tabs
                  value={false}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto"
                  classes={{flexContainer: classes.tabsContainer}}
                >
                  <CustomTab>
                  <ToggleButtonGroup size="small" disabled={this.state.isLoading}>
                    <ToggleButton disableRipple disabled value="icon" className={classes.buttonOutlined} style={{padding: '0px 0px 0px 10px'}}>
                      <Icon className="notranslate material-icons-outlined">sort</Icon>
                    </ToggleButton>
                    <ToggleButton disableRipple disabled={this.state.isLoading} value="best" selected={this.state.travel_search_params.sort === 'best'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {sort: "best"})}>Best</ToggleButton>
                    <ToggleButton disableRipple disabled={this.state.isLoading} value="cheapest" selected={this.state.travel_search_params.sort === 'cheapest'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {sort: "cheapest"})}>Cheapest</ToggleButton>
                    <ToggleButton disableRipple disabled={this.state.isLoading} value="fastest" selected={this.state.travel_search_params.sort === 'fastest'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {sort: "fastest"})}>Fastest</ToggleButton>
                  </ToggleButtonGroup>

                  <ToggleButtonGroup size="small" disabled={this.state.isLoading}>
                    <ToggleButton disableRipple disabled={this.state.isLoading} value="one-way" className={`${classes.buttonOutlined} ${this.state.travel_search_params.direction === 'one_way' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {direction: "one_way"})}>
                      <Icon className="notranslate material-icons-outlined">arrow_right_alt</Icon> One-way
                    </ToggleButton>
                    <ToggleButton disableRipple disabled={this.state.isLoading} value="return" className={`${classes.buttonOutlined} ${this.state.travel_search_params.direction === 'return' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {direction: "return"})}>
                      <Icon className="notranslate material-icons-outlined">sync_alt</Icon> Return
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {/* <ToggleButtonGroup size="small">
                    <ToggleButton disabled value="icon" className={classes.buttonOutlined} style={{padding: '0px 0px 0px 10px'}}><Icon className="notranslate material-icons-outlined">airline_seat_recline_extra</Icon></ToggleButton>
                    <ToggleButton value="$" className={`${classes.buttonOutlined} ${this.state.travel_search_params.price_category === 'one' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {price_category: "one"})}>$</ToggleButton>
                    <ToggleButton value="$$" className={`${classes.buttonOutlined} ${this.state.travel_search_params.price_category === 'two' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {price_category: "two"})}>$$</ToggleButton>
                    <ToggleButton value="$$$" className={`${classes.buttonOutlined} ${this.state.travel_search_params.price_category === 'three' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {price_category: "three"})}>$$$</ToggleButton>
                    <ToggleButton value="$$$$" className={`${classes.buttonOutlined} ${this.state.travel_search_params.price_category === 'four' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'travel_search_params', {price_category: "four"})}>$$$$</ToggleButton>
                  </ToggleButtonGroup> */}
                  </CustomTab>
                </Tabs>
              </Fragment>
            </Grid>}
          </Grid>

          <Grid item xs={12}>
            {/* Recommended */}
            {/* <Typography className={classes.sectionSubheader} style={{marginTop: -20}}>
              Recommended based on cheapest dates
            </Typography> */}

            {(this.state.flight || (this.state.travel_options && this.state.travel_options.length > 0)) && <FlightCard onSelected={customize ? this.saveTravel : () => {}} travelers={customize ? travelers : null} flight={this.state.flight || this.state.travel_options[0]} currency={this.state.currency} classes={{root: classes.root}} readonly/>}

            {destination && !this.state.flight && !this.state.travel_options && !this.state.isLoading &&
            <Grid container className={classes.notFound}>
              <Typography style={{fontWeight: 600, fontSize: 14}}>No available travel to this destination. Try to change filters.</Typography>
            </Grid>}

            {!this.state.flight && !this.state.travel_options && this.state.isLoading &&
              <Grid container className={classes.notFound}>
                <Typography style={{fontWeight: 600, fontSize: 14}}>
                  <Icon className="notranslate material-icons-outlined" style={{padding: '0px 5px 0px 0px', color: '#02c39a', fontSize: 35}}>flight</Icon>
                  <Icon className="notranslate material-icons-outlined" style={{padding: '0px 5px 0px 0px', color: '#02c39a', fontSize: 35}}>directions_bus</Icon>
                  <Icon className="notranslate material-icons-outlined" style={{padding: '0px 5px 0px 0px', color: '#02c39a', fontSize: 35}}>directions_car</Icon> <br/>
                  Searching for travel options to this destination...
                  <br/>
                  Please wait a moment
                </Typography>
              </Grid>
            }
            {false && !customize && <Button onClick={this.changeTab.bind(this, "travel")} variant="outlined" color="primary" size="medium" className={classes.buttonAdd} style={{display: 'block', margin: '15px auto 10px auto', backgroundColor: 'transparent'}} rel="noopener noreferrer">
              <Icon className="notranslate material-icons-outlined" style={{padding: '0px 5px 0px 0px', color: '#02c39a', fontSize: 15}}>edit</Icon>
              Change flight
            </Button>}
          </Grid>

          {customize &&
            <Fragment>
              <Grid item xs={12}>
                <Typography className={classes.sectionSubheader}>
                  Other options
                </Typography>
              </Grid>

              <Grid item xs={12} style={{marginBottom: 20}}>
                <div className={classes.root} style={{padding: 20, fontSize: 15, fontWeight: 500}}>
                  <Button onClick={this.saveTravel.bind(this, null)} variant="contained" color="primary" size="large" className={classes.buttonBook} style={{float: 'right', padding: '8px 22px', margin: '-10px -7px', width: 'auto'}} rel="noopener noreferrer">
                    Select
                  </Button>
                  <Icon className={`material-icons-outlined notranslate`} style={{fontSize: 25, margin: '-5px 5px -10px 0px'}}>remove_circle_outline</Icon>
                  I don't need travel
                </div>
              </Grid>

              {this.state.travel_options && this.state.travel_options.length > 0 && <Box>
                {this.state.travel_options.slice(!this.state.flight ? 1 : 0).map(flight => {
                  return <Grid item xs={12} key={flight.id}><FlightCard key={flight.id} onSelected={this.saveTravel} travelers={travelers} flight={flight} currency={this.state.currency} classes={{root: classes.root}} readonly/></Grid>
                })}
              </Box>}
            </Fragment>}

          {/* <Grid item xs={12} className={classes.header}>
            <Typography className={classes.footer}>
              <Icon className={classes.icon}>{routeStopIcon(arrival_type)}</Icon>
              {arrival_to}
            </Typography>
          </Grid> */}
        </Paper>}
      </Grid>
    </div>}

    let hotelContainer = (title, customize) => { return <div className="hotels-container" id="stay">
      <Grid
        container
        alignItems={'center'}
        justify={'center'}
      >
        <Paper className={`${classes.paper} ${classes.itineraryRail}`} elevation={1} style={{boxShadow: 'none', backgroundColor: '#fff'}}>
          <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.header}>
            {/* <Grid item xs={12}>
              {false && stayPricePerNight > 0 && <div className={classes.columnRight}>
                <IconButton aria-label="more" style={{float: 'right', margin: '4px 4px -4px 5px'}}>
                  <Icon className="notranslate">more_vert</Icon>
                </IconButton>

                <Typography className={classes.sectionPrice}>
                  {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.state.currency || 'USD'}).format(Math.round(stayPricePerNight))}
                  <small> / night</small>
                </Typography>
              </div>}

              <Typography className={classes.section}>
                <Icon className={classes.icon}>hotel</Icon>
                Stay
              </Typography>
            </Grid> */}

            {customize && destination && <Grid item xs={12} className={classes.actions}>
              {this.state.editable && <Fragment>
                <Tabs
                  value={false}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto"
                  classes={{flexContainer: classes.tabsContainer}}
                >
                  <CustomTab>
                    <ToggleButtonGroup size="small" disabled={this.state.isLoading}>
                      <ToggleButton disableRipple disabled value="icon" className={classes.buttonOutlined} style={{padding: '0px 0px 0px 10px'}}>
                        <Icon className="notranslate material-icons-outlined">sort</Icon>
                      </ToggleButton>
                      <ToggleButton disableRipple disabled={this.state.isLoading} value="best" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.sort === 'best' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {sort: "best"})}>
                        Best
                      </ToggleButton>
                      <ToggleButton disableRipple disabled={this.state.isLoading} value="cheapest" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.sort === 'cheapest' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {sort: "cheapest"})}>
                        Cheapest
                      </ToggleButton>
                      <ToggleButton disableRipple disabled={this.state.isLoading} value="closest" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.sort === 'closest' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {sort: "closest"})}>
                        Closest
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup size="small" disabled={this.state.isLoading}>
                      <ToggleButton disableRipple disabled={this.state.isLoading} className={`${classes.buttonOutlined} ${this.state.hotel_search_params.accommodation_type === 'hostel' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {accommodation_type: "hostel"})} value={this.state.hotel_search_params.accommodation_type || "hostel"}>
                        <Icon className="notranslate material-icons-outlined">single_bed</Icon> Shared
                      </ToggleButton>
                      <ToggleButton disableRipple disabled={this.state.isLoading} className={`${classes.buttonOutlined} ${this.state.hotel_search_params.accommodation_type === 'hotel' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {accommodation_type: "hotel"})} value={this.state.hotel_search_params.accommodation_type || "hotel"}>
                        <Icon className="notranslate material-icons-outlined">bed</Icon> Hotel
                      </ToggleButton>
                      <ToggleButton disableRipple disabled={this.state.isLoading} className={`${classes.buttonOutlined} ${this.state.hotel_search_params.accommodation_type === 'vacation_rental' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {accommodation_type: "vacation_rental"})} value={this.state.hotel_search_params.accommodation_type || "vacation_rental"}>
                        <Icon className="notranslate material-icons-outlined">night_shelter</Icon> Apartment / House
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* <ToggleButtonGroup size="small">
                      <ToggleButton disabled  value="icon" className={classes.buttonOutlined} style={{padding: '0px 0px 0px 10px'}}>
                        <Icon className="notranslate material-icons-outlined">hotel_class</Icon>
                      </ToggleButton>
                      <ToggleButton value="$" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.price_category === 'one' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {price_category: "one"})}>$</ToggleButton>
                      <ToggleButton value="$$" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.price_category === 'two' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {price_category: "two"})}>$$</ToggleButton>
                      <ToggleButton value="$$$" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.price_category === 'three' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {price_category: "three"})}>$$$</ToggleButton>
                      <ToggleButton value="$$$$" className={`${classes.buttonOutlined} ${this.state.hotel_search_params.price_category === 'four' &&  classes.buttonOutlinedSelected}`} onClick={this.updateUserPreferences.bind(this, 'hotel_search_params', {price_category: "four"})}>$$$$</ToggleButton>
                    </ToggleButtonGroup> */}
                  </CustomTab>
                </Tabs>
              </Fragment>}
            </Grid>}
          </Grid>

          <Grid item xs={12}>
            {/* Recommended */}
            {((this.state.hotel_options && this.state.hotel_options.length > 0) || (this.state.recommended_hotels && this.state.recommended_hotels.length > 0)) &&
            <Typography className={classes.sectionSubheader} style={{marginTop: -20}}>
              Recommended for your style
            </Typography>}

            {(this.state.hotel) &&
              <HotelCard onSelected={this.saveHotel} travelers={customize ? travelers : null} hotel={this.state.hotel || this.state.hotel_options[0]}  adults={adults} children={children} startDate={start_date} endDate={end_date} currency={this.state.currency} search_params={this.props.search_params} destination={destination} classes={{root: classes.root}} readonly smallImages/>
            }

            {(!this.state.hotel && !customize && this.state.recommended_hotels && this.state.recommended_hotels.length > 0) &&
              <Grid container>
                {this.state.recommended_hotels.map((hotel, i) => {
                  return <Grid key={i} item xs={12} style={{marginBottom: 10}}><HotelCard hotel={hotel} adults={adults} children={children} startDate={start_date} endDate={end_date} currency={this.state.currency} search_params={this.props.search_params} destination={destination} classes={{root: classes.root}} readonly smallImages/></Grid>
                })}
            </Grid>}

            {destination && !this.state.hotel && (!this.state.hotel_options || this.state.hotel_options.length < 1) && (!this.state.recommended_hotels || this.state.recommended_hotels.length < 1) &&
            <Grid container className={classes.notFound}>
              <Typography className={classes.sectionSubheader} style={{marginTop: -20}}>No available accommodation{customize ? '. Try to change filters' : ''}</Typography>
            </Grid>}

            {/* {false && !customize && <Button onClick={this.changeTab.bind(this, "stay")} variant="outlined" color="primary" size="medium" className={classes.buttonAdd} style={{display: 'block', margin: '15px auto 10px auto', backgroundColor: 'transparent'}} rel="noopener noreferrer">
              <Icon className="notranslate material-icons-outlined" style={{margin: '-1px 5px 0px 0px', color: '#02c39a', fontSize: 15, verticalAlign: 'middle'}}>edit</Icon>
              Change hotel
            </Button>} */}
          </Grid>

          {customize && <Fragment>
            <Grid item xs={12}>
              <Typography className={classes.sectionSubheader}>
                Other options
              </Typography>
            </Grid>

            <Grid item xs={12} style={{marginBottom: 20}}>
              <div className={classes.root} style={{padding: 20, fontSize: 15, fontWeight: 500}}>
                <Button onClick={this.saveHotel.bind(this, null)} variant="contained" color="primary" size="large" className={classes.buttonBook} style={{float: 'right', padding: '8px 22px', margin: '-10px -7px', width: 'auto'}} rel="noopener noreferrer">
                  Select
                </Button>
                <Icon className={`material-icons-outlined notranslate`} style={{fontSize: 25, margin: '-5px 5px -10px 0px', verticalAlign: 'middle', padding: '0px 11px 5px 5px', color: '#e0e0e0'}}>remove_circle_outline</Icon>
                I don't need a place
              </div>
            </Grid>

            {this.state.hotel_options && this.state.hotel_options.length > 0 &&
              <Box>
                {this.state.hotel_options.slice(!this.state.hotel ? 1 : 0).map((hotel, i) => {
                  return <Grid key={i} item xs={12} style={{marginBottom: 20}}><HotelCard onSelected={this.saveHotel} travelers={travelers} hotel={hotel} adults={adults} children={children} startDate={start_date} endDate={end_date} currency={this.state.currency} search_params={this.props.search_params} destination={destination} classes={{root: classes.root}} readonly smallImages/></Grid>
                })}
            </Box>}
          </Fragment>}
        </Paper>
      </Grid>
    </div>}

    // const travelPlanContainer = <div className={classes.route}>
    //   {this.state.route && this.state.route.map((route, i) => {
    //     return (
    //       <Fragment key={i}>

    //         {/* {i === 0 && <div className={(i === 0 ? classes.routeStartEnd : classes.routeStop)}>
    //           <Icon className={`${classes.routeIcon} notranslate`}>{routeStopIcon(route.from.type)}</Icon>
    //           <Typography gutterBottom variant="h6" component="h3" className={classes.routeTitle}>
    //             {['hotel'].includes(route.from.type) && startCase(route.from.city)}
    //             {!['hotel'].includes(route.from.type) && startCase(route.from.name || route.from.city)}
    //             {route.from.type === 'aircraft' && ' Airport'}
    //           </Typography>
    //         </div>} */}

    //         <div className={classes.routeDrivingInfo}>
    //           <Card className={classes.routeDrivingCard} variant="outlined">
    //             <CardHeader classes={{root: classes.routeDrivingCardHeader, title: classes.routeDrivingCardTitle, subheader: classes.routeDrivingCardSubtitle}}
    //               avatar={
    //                 <Avatar aria-label="icon" className={classes.routeIconSmall}>
    //                   <Icon className={`${classes.routeDrivingIcon} notranslate`}>directions_car</Icon>
    //                 </Avatar>
    //               }
    //               subheader={<Fragment>
    //                 {/* {['hotel'].includes(route.from.type) && startCase(route.from.city)}
    //                 {!['hotel'].includes(route.from.type) && startCase(route.from.name || route.from.city)}
    //                 {route.from.type === 'aircraft' && ' Airport'}
    //                 {` -> `}
    //                 {['hotel'].includes(route.to.type) && startCase(route.to.city)}
    //                 {!['hotel'].includes(route.to.type) && startCase(route.to.name || route.to.city)}
    //                 {route.to.type === 'aircraft' && ' Airport'} */}
    //                 <div className={classes.routeDrivingTime}>
    //                   {`Est. `}
    //                   {Math.floor(route.driving_time_in_min / 60) > 0 && `${Math.floor(route.driving_time_in_min / 60)} hr, `}
    //                   {Math.floor(route.driving_time_in_min / 60) > 0 ? route.driving_time_in_min - Math.floor(route.driving_time_in_min / 60) * 60 : Math.round(route.driving_time_in_min)} min
    //                   {' '}&bull;{' '}
    //                   {`${Math.ceil(route.driving_distance / 1000)} km`}
    //                 </div>
    //               </Fragment>}
    //             />
    //           </Card>
    //         </div>

    //         {/* <div className={classes.routeStartEnd}>
    //           <Icon className={`${classes.routeIcon} notranslate`}>{routeStopIcon(route.to.type)}</Icon>
    //           <Typography className={classes.routeTitle}>
    //             {['hotel'].includes(route.to.type) && startCase(route.to.city)}
    //             {!['hotel'].includes(route.to.type) && startCase(route.to.name || route.to.city)}
    //             {route.to.type === 'aircraft' && ' Airport'}
    //           </Typography>
    //         </div> */}

    //         {/* Show transportation details if route at airport/station */}
    //         {['aircraft', 'bus', 'train'].includes(route.to.type) &&
    //           <div>
    //             {flightContainer(
    //               `${route.to.name ? route.to.name : route.to.city} ${route.to.type === 'aircraft' ? 'Airport' : ''}`,
    //               route.to.type,
    //               `${this.state.route[i+1].from.name ? this.state.route[i+1].from.name : this.state.route[i+1].from.city} ${this.state.route[i+1].from.type === 'aircraft' ? 'Airport' : ''}`,
    //               this.state.route[i+1].from.type)}
    //           </div>}

    //         {/* Show transportation details if route at airport/station */}
    //         {['hotel'].includes(route.to.type) &&
    //           <div>
    //             {hotelContainer(route.to.name)}
    //           </div>}

    //         {/* {this.state.route.length === i + 1 && <div className={(this.state.route.length === i + 1 ? classes.routeStartEnd : classes.routeStop)}>
    //           <Icon className={`${classes.routeIcon} notranslate`}>{routeStopIcon(route.to.type)}</Icon>
    //           <Typography className={classes.routeTitle}>
    //             {['hotel'].includes(route.to.type) && startCase(route.to.city)}
    //             {!['hotel'].includes(route.to.type) && startCase(route.to.name || route.to.city)}
    //             {route.to.type === 'aircraft' && ' Airport'}
    //           </Typography>
    //         </div>} */}
    //       </Fragment>)
    //   })}
    // </div>

    // let programContainer = <div className="destination-container">
    //   {<Grid item xs={12} sm={12} md={12} className={classes.paper} style={{boxShadow: 'none', border: '1px solid #eee', marginTop: -20}}>
    //     {((this.props.user.is_registered && !this.state.isLoading && ((this.state.programWalking && this.state.programWalking.length > 0) || (this.state.programNearby && this.state.programNearby.length > 0))) || !this.props.user.is_registered) && <Accordion TransitionProps={{ unmountOnExit: true }} expanded={false} elevation={0}>
    //       <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" style={{cursor: 'default'}}>
    //         <Grid container direction="row" justify="flex-end" alignItems="center">
    //           <Grid item xs={12}>
    //             <div className={classes.columnRight}>
    //               {this.props.user.is_registered ?
    //                 ((this.state.programWalking || this.state.programNearby) && (this.state.programWalking || this.state.programNearby).length > 0) ?
    //                   <Typography className={classes.bookingItemSecondary} style={{marginTop: 8}}></Typography>
    //                 :
    //                   !this.state.isLoading && <Button variant="outlined" color="primary" size="medium" className={classes.buttonAdd} style={{margin: 0}} onClick={this.fetchProgram.bind(this, false)}>Refresh</Button>
    //               :
    //                 ((!this.state.programWalking || this.state.programWalking.length < 1) && (!this.state.programNearby || this.state.programNearby.length < 1) && !this.state.isLoading) && <Button variant="outlined" color="primary" size="medium" className={classes.buttonAdd} style={{margin: 0}} onClick={this.fetchProgram.bind(this, true)}>Show sample</Button>
    //               }
    //             </div>
    //             <Typography className={classes.programHeader}>
    //               {(this.state.programNearby && this.state.programNearby.length > 0) ? 'Drive around tour' : 'Walking tour'}
    //             </Typography>
    //           </Grid>
    //         </Grid>

    //       </AccordionSummary>
    //     </Accordion>}

    //     {(!this.props.user.is_registered && !this.state.isLoading && (!this.state.programWalking || this.state.programWalking.length < 1) && (!this.state.programNearby || this.state.programNearby.length < 1)) ?
    //       <Fragment>
    //         <Accordion elevation={0}>
    //           <AccordionDetails style={{padding: 0}}>
    //             <Grid className={classes.signupBanner} container alignItems={'center'} justify={'center'} >
    //               <Grid item xs={12} sm={8} md={8}>
    //                 <h2 className={classes.signupHeadline}>
    //                   <strong className="main-title" style={{margin: '0px', display: 'block', fontWeight: 800, color: '#333'}}>
    //                   Explore self guided tour before booking a trip
    //                   </strong>
    //                   <br/>
    //                   <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={this.handleSigninChange.bind(this, true, 'signup')} style={{margin: '0px 5px 0px 0px'}}>Get started</Button>
    //                 </h2>
    //               </Grid>
    //               <Grid item xs={12} sm={4} md={4} style={{padding: 20}}>
    //                 <CardMedia
    //                   component="img"
    //                   className={classes.signupImage}
    //                   image={'/discover-weekly-image.png'}
    //                   srcSet="/discover-weekly-image.png 2x,
    //                           /discover-weekly-image@2x.png 3x"
    //                   title="Sign up"
    //                 />
    //               </Grid>
    //             </Grid>
    //           </AccordionDetails>
    //         </Accordion>
    //       </Fragment>
    //     :
    //       <Fragment>
    //         {!this.state.programWalking && !this.state.programNearby && this.state.isLoading && <Accordion elevation={0}>
    //           <AccordionSummary>
    //             <Grid item xs={3} sm={2}>
    //               <Skeleton variant="rect" height={80} width={'100%'} className={classes.skeleton} style={{marginTop: 0}}/>
    //             </Grid>
    //             <Grid item xs={9} sm={10}>
    //               <Skeleton variant="rect" height={25} width={150} className={classes.skeleton} style={{marginLeft: 20}}/>
    //               <Skeleton variant="rect" height={15} width={'90%'} className={classes.skeleton} style={{marginLeft: 20, marginTop: 10}}/>
    //               <Skeleton variant="rect" height={15} width={'90%'} className={classes.skeleton} style={{marginLeft: 20, marginTop: 10}}/>
    //             </Grid>
    //           </AccordionSummary>
    //         </Accordion>}
    //         {!this.state.isLoading && ((!this.state.programWalking || this.state.programWalking.length < 1) && (!this.state.programNearby || this.state.programNearby.length < 1)) && <Grid container alignItems={'center'} justify={'center'} spacing={2} style={{marginBottom: 10, marginTop: 10}}>
    //           <Grid item xs={12} className={classes.header}>
    //             <Typography className={classes.sectionSubheader} style={{textAlign: 'center'}}>
    //             No self guided tour found for this destination
    //             </Typography>
    //           </Grid>
    //         </Grid>}
    //         {this.state.isLoading && ((!this.state.programWalking || this.state.programWalking.length < 1) && (!this.state.programNearby || this.state.programNearby.length < 1)) && <Grid container alignItems={'center'} justify={'center'} spacing={2} style={{marginBottom: 10, marginTop: -20}}>
    //           <Grid container className={classes.notFound}>
    //             <Typography style={{fontWeight: 600, fontSize: 14}}>
    //               <Icon className="notranslate material-icons-outlined" style={{padding: '0px 5px 0px 0px', color: '#02c39a', fontSize: 35}}>search</Icon> <br/>
    //               Searching for recommended self guided tour...
    //               <br/>
    //               Please wait a moment
    //             </Typography>
    //           </Grid>
    //         </Grid>}




    //         {((this.state.programWalking || this.state.programNearby) && this.state.isProgramSample) && <Accordion elevation={0} style={{backgroundColor: '#fafafa'}}>
    //           <AccordionSummary>
    //             <Grid item xs={12}>
    //               <Typography variant="body2" component="div" style={{padding: '5px', fontWeight: 500, fontSize: 13}}>
    //                 This is example of self guided tour for 3 days trip in Rome
    //               </Typography>
    //             </Grid>
    //           </AccordionSummary>
    //         </Accordion>}
    //         <Grid style={{whiteSpace: 'nowrap', overflow: 'auto', backgroundColor: '#fff', padding: '3px 15px'}}>
    //           {((this.state.programWalking && this.state.programWalking.length > 0 && this.state.programWalking) || (this.state.programNearby && this.state.programNearby.length > 0 && this.state.programNearby) || []).map((day, i) => {
    //             return <Chip
    //                     key={i}
    //                     classes={{root: classes.chipRoot, colorPrimary: classes.chipSelected}}
    //                     clickable={true}
    //                     onClick={(e) => { this.handleChange(e, `diy-tour-walking-${i}`) }}
    //                     color={(expanded === `diy-tour-walking-${i}`) ? "primary" : "default"}
    //                     label={`Day ${i+1}`}
    //                   />
    //           })}
    //           </Grid>
    //         {((this.state.programWalking && this.state.programWalking.length > 0 && this.state.programWalking) || (this.state.programNearby && this.state.programNearby.length > 0 && this.state.programNearby) || []).map((day, i) => {
    //           return (
    //             (expanded === `diy-tour-walking-${i}`) && <Accordion key={`walk-${i}`} TransitionProps={{ unmountOnExit: true }} elevation={0}>
    //               {/* <AccordionSummary
    //                 expandIcon={<ExpandMoreIcon />}
    //                 aria-controls="panel1a-content"
    //                 id="panel1a-header"
    //                 classes={{expanded: classes.programDayExpanded}}
    //               >
    //                 <Avatar className={classes.programSummaryIcon} alt={`Day Walk ${i+1}`}>{i+1}</Avatar>
    //                 <div>
    //                   <Typography className={classes.heading}>Day {i+1}</Typography>
    //                 </div>
    //                 <Typography className={classes.secondaryHeading}>
    //                   <Hidden xsDown><span style={{color: '#999'}}>{moment(day.date).format('dddd')}</span>, </Hidden>{moment(day.date).format('MMMM DD')}
    //                   <Hidden smUp><br/><span style={{color: '#999'}}>{moment(day.date).format('dddd')}</span></Hidden>
    //                 </Typography>
    //               </AccordionSummary> */}
    //               <AccordionDetails>
    //                 <Grid container spacing={2} className={classes.programContainer}>
    //                   <List className={classes.programList}>
    //                   {day.itinerary_items.map((item, i) => {
    //                     return (
    //                       <Fragment key={`walk-${day.date}-${i}`}>
    //                         {item.title !== "" && <ListSubheader disableSticky={true} className={classes.subHeader}>{startCase(item.title)}</ListSubheader>}
    //                         <ListItem alignItems="flex-start" className={classes.programListItem}>
    //                           <ListItemAvatar>
    //                             {item.poi.images.length > 0 ?
    //                               <Avatar variant="rounded" alt={item.poi.title} src={item.poi.images[0].sizes.medium.url} className={classes.programItemImage}/>
    //                             :
    //                               <Avatar variant="rounded" alt={item.poi.title}>
    //                                 <Icon  className="notranslate">{this.renderProgramIcon(item.title)}</Icon>
    //                               </Avatar>
    //                             }
    //                           </ListItemAvatar>
    //                           <ListItemText
    //                             primary={
    //                               <Fragment>
    //                                 <Typography component="span" variant="h5" className={classes.programItemName} color="textPrimary">
    //                                   <Hidden smUp>
    //                                     <Button size="small"
    //                                       component="a"
    //                                       className={classes.buttonMap}
    //                                       rel="noopener noreferrer"
    //                                       target="_blank"
    //                                       href={`https://www.google.com/maps/search/${item.poi.name}, ${item.poi.location_id}`}
    //                                     >
    //                                       <Icon className={`material-icons-outlined notranslate`}>map</Icon> map
    //                                     </Button>
    //                                   </Hidden>

    //                                   {item.poi.name}
    //                                 </Typography>
    //                               </Fragment>}
    //                             secondary={
    //                               <Fragment>
    //                                 <Typography component="span" variant="body2" className={classes.programItemDescription} color="textPrimary">
    //                                   {item.poi.snippet}
    //                                 </Typography>
    //                               </Fragment>
    //                             }
    //                           />
    //                           <Hidden xsDown>
    //                             <ListItemSecondaryAction>
    //                               <Button size="small"
    //                                 component="a"
    //                                 className={classes.buttonMap}
    //                                 rel="noopener noreferrer"
    //                                 target="_blank"
    //                                 href={`https://www.google.com/maps/search/${item.poi.name}, ${item.poi.location_id}`}
    //                               >
    //                                 <Icon className={`material-icons-outlined notranslate`}>map</Icon> map
    //                               </Button>
    //                             </ListItemSecondaryAction>
    //                           </Hidden>
    //                         </ListItem>
    //                       </Fragment>)
    //                   })}
    //                   </List>
    //                 </Grid>
    //               </AccordionDetails>
    //             </Accordion>
    //           )
    //         })}
    //       </Fragment>
    //     }
    //   </Grid>}
    // </div>

    // let exploreContainer = (customize) => { return <div className="explore-container" id="itinerary">
    //   {destination && <Fragment>
    //     <Grid
    //       container
    //       alignItems={'center'}
    //       justify={'center'}>
    //       <Paper className={classes.paper} elevation={1} id="explore" style={{boxShadow: 'none', backgroundColor: '#fff'}}>
    //         <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.header}>
    //           <Grid item xs={12}>
    //             <Typography className={classes.section}>
    //               About
    //             </Typography>
    //           </Grid>

    //           {destination.intro && <Grid item xs={12} style={{padding: "0px 20px 30px 20px", minHeight: 125}}>
    //             {weather && <div className={classes.columnRight}>
    //               <Typography className={classes.sectionPrice} style={{textAlign: 'center', padding: '0px 0px 20px 20px', marginTop: -30}}>
    //                 <img src={weather.icon_url} style={{clear: 'both', float: 'none', display: 'block', width: 70}}/>
    //                 {weather.temp_c} °C, {weather.temp_f} °F
    //                 <br/>
    //                 <small>{moment(weather.datetime).format('MMMM DD')}</small>
    //               </Typography>
    //             </div>}
    //             <Typography style={{fontWeight: 500, fontSize: 14, lineHeight: '24px', }}>
    //               {(destinationDetails && destinationDetails.intro) || destination.intro}
    //             </Typography>
    //           </Grid>}

    //           {/* <Grid item xs={12} style={{textAlign: 'left', marginBottom: 40, marginTop: 15}}>
    //             <ToggleButtonGroup size="large">
    //               <ToggleButton value="activities" selected={this.state.explore.selected === 'activities'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => this.setState({explore: {selected: 'activities'}})}>
    //                 <Icon className="notranslate material-icons-outlined">directions_walk</Icon>
    //                 Activities
    //               </ToggleButton>
    //               <ToggleButton value="self-guided" selected={this.state.explore.selected === 'self-guided'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => this.setState({explore: {selected: 'self-guided'}})}>
    //                 <Icon className="notranslate material-icons-outlined">tour</Icon>
    //                 Self guided tour
    //               </ToggleButton>
    //             </ToggleButtonGroup>
    //           </Grid> */}
    //         </Grid>

    //         {/* <Grid item xs={12} style={{padding: "10px"}}>
    //           {this.state.explore.selected === 'activities' && activityContainer(customize)}
    //           {this.state.explore.selected === 'self-guided' && (this.state.flight || this.state.hotel || destination) && programContainer}

    //           {false && !customize && <Button onClick={this.changeTab.bind(this, "explore")} variant="outlined" color="primary" size="medium" className={classes.buttonAdd} style={{display: 'block', margin: '15px auto 10px auto', backgroundColor: 'transparent'}} rel="noopener noreferrer">
    //             <Icon className="notranslate material-icons-outlined"  style={{margin: '-1px 5px 0px 0px', color: '#02c39a', fontSize: 15, verticalAlign: 'middle'}}>edit</Icon>
    //             Change activities
    //           </Button>}
    //         </Grid> */}
    //       </Paper>
    //     </Grid>
    //   </Fragment>}
    // </div>}

    return (
      <Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          {/* <link rel="canonical" href={`https://www${config.api.DOMAIN}/trip${(this.props.locationFrom && this.props.locationFrom !== 'nearby') ? '/' + this.props.locationFrom : ''}/to/${destination ? destination.slug : this.props.locationTo}`} /> */}
          <meta property="og:title" content={pageTitle + ' | Travelities.com'} />
          <meta property="og:image" content={destination && (destination.images && destination.images[0] ? destination.images[0].sizes.medium.url.replace('http://', '//') : "https://www.travelities.com/travelities-preview.jpg")} />
          <meta property="og:url" content={`${config.env !== 'development' ? 'https://www' + config.api.DOMAIN : 'http://localhost:5200'}${sharableLink}`} />
          <meta property="og:description" content={`Self guided Itinerary • ${destination && destination.nights_in_dest} nights`}/>
        </Helmet>
        <AppTopBar
          hideSearchBar={true}
          hideMenu={false}
          // hideLogo={isWidthUp('md', this.props.width) ? false : true}
          hideCurrency={true}
          hideBottomMenu={true}
          showBackButton={true}
          backButtonPath={this.state.backButtonPath}
          openedSigninDialog={this.state.openedSigninDialog}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
        />

        <div className="trip-container" id="trip-container">

          {this.state.trip_id ?
            <TripItinerary
              trip_id={this.state.trip_id}
              user={this.props.user}
              currency={this.state.currency}
              sharableLink={sharableLink}
              destination={destination}
              onSignin={this.props.onSignin}
              onItineraryUpdated={this.updateItinerary}
              hideHeader={false}
              search_params={this.props.search_params}
              afterPayment={this.props.afterPayment}
            />
          :
            <Fragment>
              {<TripHeader
                adults={destination && destination.adults}
                children={destination && destination.children}
                destination={destination}
                endDate={destination && destination.end_date}
                infants={destination && destination.infants}
                isLoading={false}
                nights={destination && destination.nights_in_dest}
                score={destination && destination.score}
                saved={destination && destination.saved}
                link_to_save={destination && destination.link_to_save}
                startDate={destination && destination.start_date}
                to={destination ? destination.to : this.props.locationTo}
                searchParams={this.props.search_params}
                sharableLink={sharableLink}
              />}
              <Itinerary
                itinerary={[]}
                search_params={this.props.search_params}
              />
            </Fragment>
          }

        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  // routeStartEnd: {
  //   marginLeft: 15,
  // },
  // routeStop: {
  //   marginLeft: 5,
  //   position: 'relative',

  //   '&::before': {
  //     position: 'absolute',
  //     zIndex: 0,
  //     left: 16,
  //     width: 2,
  //     content: '""',
  //     top: -5,
  //     height: 11,
  //     background: '#f2f2f2',
  //   },
  //   '&::after': {
  //     position: 'absolute',
  //     zIndex: 0,
  //     left: 16,
  //     width: 2,
  //     content: '""',
  //     bottom: -5,
  //     height: 11,
  //     background: '#f2f2f2',
  //   },
  // },
  // routeIcon: {
  //   padding: '5px 14px 5px 5px',
  //   verticalAlign: 'middle',
  // },
  // routeDrivingInfo: {
  //   position: 'relative',
  //   padding: '15px 0px 15px 10px',
  //   color: '#999',

  //   '&::before': {
  //     position: 'absolute',
  //     zIndex: 1,
  //     left: 31,
  //     width: 2,
  //     content: '""',
  //     top: -5,
  //     height: '108%',
  //     background: '#f2f2f2',
  //   },
  // },
  // routeDrivingCard: {
  //   maxWidth: 500,
  //   // border: '1px solid rgba(0, 0, 0, 0.08)',
  //   border: 'none',
  //   borderRadius: 25,
  //   // backgroundColor: '#fafafa',
  //   fontSize: 600,
  //   position: 'relative',
  //   marginLeft: '-2px',
  //   zIndex: 1,
  //   // display: 'none',
  // },
  // routeDrivingCardHeader: {
  //   padding: '0px 8px',
  // },
  // routeDrivingCardTitle: {
  //   fontWeight: 600,
  //   fontSize: 13,
  // },
  // routeDrivingCardSubtitle: {
  //   fontWeight: 600,
  //   fontSize: 13,
  //   color: '#999',
  // },
  // routeDrivingTime: {
  //   color: '#555',
  // },
  // routeDrivingIcon: {
  //   fontSize: '1.3rem',
  // },
  // routeTitle: {
  //   fontSize: 14,
  //   fontWeight: 800,
  //   display: 'inline',
  // },
  // routeIconSmall: {
  //   width: theme.spacing(4),
  //   height: theme.spacing(4),
  //   backgroundColor: 'transparent',
  //   color: '#c1c1c1',
  //   marginRight: '-6px',
  // },
  section: {
    fontSize: 18.5,
    fontWeight: 700,
    lineHeight: '18px',
    padding: '20px 20px',

    '& small': {
      color: '#999',
      fontSize: '80%',
    }
  },
  footer: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '18px',
    padding: '12px 5px',
    borderTop: '1px solid #eee',

    '& small': {
      color: '#999',
      fontSize: '80%',
    }
  },
  tripTitle:{
    fontSize: 17,
    fontWeight: 600,
    padding: 15,
    lineHeight: 1.2,

    '& small': {
      color: '#999',
      fontSize: '80%',
      fontWeight: 500,
    }
  },
  button: {
    float: 'right',
    color: '#02c39a',
    textTransform: 'capitalize',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginTop: 20,
    padding: '0px',

    '& a': {
      textDecoration: 'none',
      color: '#02c39a',
      padding: '8px 19px',
      display: 'block',
    }
  },
  iconSmall: {
    fontSize: 20,
    padding: '0px 5px 0px 0px !important',
  },
  actions: {
    padding: '0px 0px 0px 7px',
    marginTop: -5,
    marginBottom: 40,
  },
  tabsContainer: {
    // justifyContent: 'flex-end',
  },
  header: {
    padding: '0px !important',
  },
  buttonBook: {
    color: '#fff',
    margin: '10px 10px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: 12,
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonReserve: {
    float: 'right',
    color: '#fff',
    margin: '10px 10px 10px 5px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonAdd: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#fff',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    border: '2px solid #02c39a',

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: '2px solid #02c39a',
    }
  },
  buttonMap: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#fff',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,
    border: 'none',
    fontSize: 13,
    padding: '4px 10px',

    '& .material-icons': {
      fontSize: 20,
      marginRight: 5,
    },

    '&:hover': {
      backgroundColor: '#fafafa',
      border: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '-5px -6px 0px 10px',
      float: 'right',
    },
  },
  buttonClose: {
    float: 'right',
    color: '#333',
    margin: 10,
    backgroundColor: '#fff',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 600,
    padding: 9,
    minWidth: 50,

    '& .material-icons': {
      fontSize: 20,
      marginRight: 5,
    },

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: 'none',
      boxShadow: 'none',
    }
  },
  buttonCancel: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,

    '&:hover': {
      backgroundColor: '#e2e2e2',
      border: 'none',
    }
  },
  buttonOutlined: {
    float: 'left',
    marginLeft: 10,
    padding: '10px',
    borderRadius: 25,
    fontWeight: 500,
    border: '1px solid #f5f5f5',
    color: '#333',
    marginTop: 6,
    backgroundColor: '#f5f5f5',

    '& .material-icons': {
      marginRight: 5,
      color: '#777',
      fontWeight: 500,
      padding: 0,
    },

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: '1px solid #f2f2f2',
    },

    '&:not(:first-child)': {
      marginLeft: 0,
    },

    '&:hover .material-icons': {
      color: '#333',
    },
  },
  buttonOutlinedSelected: {
    backgroundColor: '#f5f5f5 !important',
    fontWeight: 800,
    color: '#333 !important',
    padding: '5px 7px',

    '& .material-icons': {
      color: '#02c39a !important',
    },

    '&:hover': {
      color: '#333',
    },

    '&:hover .material-icons': {
      color: '#333',
    },

    '& > .MuiToggleButton-label': {
      borderRadius: 25,
      backgroundColor: '#fff',
      padding: '4px 10px',
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  buttonCustomize: {
    float: 'right',
    marginLeft: 10,
    textTransform: 'none',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#eee',
    }
  },
  buttonWide: {
    width: '98%',
    boxShadow: 'none',
    margin: '5px auto',
    backgroundColor: '#1bb746',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#19a941',
    }
  },
  card: {
    // width: '32%',
    // minWidth: 138,
    // maxWidth: 300,
    // float: 'left',
    // marginRight: '1%',
    // marginBottom: '1%',
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    boxShadow: 'none',
    border: '1px solid #eee',

    // [theme.breakpoints.down('xs')]: {
    //   // width: '48.3%',
    // },
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  cardTitle: {
    maxHeight: 44,
    minHeight: 44,
    overflow: 'hidden',
    color: '#333',
    fontSize: 14,
    fontWeight: 600,
  },
  cardContent: {
    maxHeight: 113,
    overflow: 'hidden',
    fontSize: 12,
    // padding: '100px 10px 5px 10px !important',
    color: '#fff',
    // marginTop: '-191px',
    // background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.56) 57%,rgba(0,0,0,0.65) 66%)',
    // filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#a6000000",GradientType=0 )',
  },
  content: {
    float: 'left',
    margin: '0px !important',
    // display: 'flex',
    // padding: '10px 0px 10px 0px !important',
    // overflowX: 'scroll',
    width: '100%',
  },
  cardActions: {
    padding: 0,
  },
  notFound: {
    textAlign: 'center',
    padding: 50,
    borderRadius: 15,
    display: 'block',
    backgroundColor: '#fff',
    border: 'none',
    width: '100%',
  },
  carousel: {
    position: 'relative',
    padding: '12px 0px 0px 12px !important',
  },
  carousel_arrow_left: {
    display: 'none',
    position: 'absolute',
    left: -0,
    top: '40%',
    zIndex: 10,
    backgroundColor: '#fff',
    border: 'solid 1px #eee',
    padding: '8px 8px 3px',
    borderRadius: 20,
    cursor: 'pointer',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',

    '&:hover': {
      color: '#1bb746',
    },
  },
  carousel_arrow_right: {
    display: 'none',
    position: 'absolute',
    right: -0,
    top: '40%',
    zIndex: 10,
    backgroundColor: '#fff',
    border: 'solid 1px #eee',
    padding: '8px 8px 3px',
    borderRadius: 20,
    cursor: 'pointer',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',

    '&:hover': {
      color: '#1bb746',
    },
  },
  appBar: {
    backgroundColor: '#fff',
    zIndex: 10,
    display: 'block',
    position: 'relative',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.04), 0px 1px 10px 0px rgba(0, 0, 0, 0.02)',
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',

    [theme.breakpoints.down('sm')]: {
      margin: '-8px',
    },
  },
  progressPrimary: {
    backgroundColor: '#e0e0e0',
    height: '2px'
  },
  progressBarColor: {
    backgroundColor: '#00bc30'
  },
  progress: {
    flexGrow: 1,
    width: '100%',
    float: 'left',
    clear: 'both',
    margin: '0px',
  },
  menuButton: {
    color: '#333',
  },
  interestsContainer: {
    textAlign: 'left',
    padding: '20px 40px 0px 20px',
    fontSize: '15px',
    color: '#777',
  },
  paper: {
    // border:' 1px solid #f2f2f2',
    width: '100%',
    margin: '6px 0px',
    borderRadius: 15,
    // boxShadow: 'none',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',

    '&:hover': {
      // border: ' 1px solid #eee',
      boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    },
  },
  paperHeader: {
    width: '100%',
    margin: '12px',
    border: ' 1px solid #eee',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  paperNotice: {
    width: '100%',
    margin: '0px 12px 10px 12px',
    padding: '20px 20px 15px 20px',
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: 16,
    border: 'none',
    color: '#00bc30',
    backgroundColor: '#fafafa',
  },
  root: {
    marginTop: 10,
    // marginLeft: 45,
    // boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    // border: '1px solid #eee',
    width: 'auto',
    // boxShadow: 'none',
    // borderRadius: '15px !important',

    '&:before': {
      backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
    },
  },
  bookingDetails: {
    borderTop: '1px solid #f2f2f2',
  },
  bookingPartner: {
    margin: 10,
    float: 'left',
    padding: '10px 10px 7px 10px',
  },
  imagesRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  iconSection: {
    verticalAlign: 'middle',
    marginRight: 10,
  },
  icon: {
    verticalAlign: 'middle !important',
    padding: '5px 5px 5px 5px !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '70%',
    color: '#777',
    display: 'block',
  },
  programItemLarge: {
    height: 150,
    width: '100%',
    borderRadius: 10,
    marginRight: 0,
  },
  programItemSmall: {
    height: 50,
    width: 50,
    borderRadius: 8,
    marginRight: 10,
  },
  smallAvatar: {
    height: 26,
    width: 26,
    fontWeight: 600,
    fontSize: '27px !important',
    margin: '4px 0px 4px 0px',
    background: 'transparent',
    color: '#5ad076',
    padding: '3px',
  },
  heading: {
    fontWeight: 700,
    margin: '9px 10px 9px 20px',
    fontSize: 14,
  },
  secondaryHeading: {
    color: '#333',
    margin: '9px 20px',
    fontSize: 12,
    fontWeight: 600,
    float: 'right',
    position: 'absolute',
    right: 40,
    textAlign: 'right',

    [theme.breakpoints.down('xs')]: {
      margin: '0px 20px',
    },
  },
  column: {
    flexBasis: '13.33%',
    minWidth: '130px',
  },
  programList: {
    // overflow: 'scroll',
    // maxHeight: '75vh',
    width: '100%',
  },
  programListItem: {
    paddingRight: 120,

    [theme.breakpoints.down('xs')]: {
      padding: '10px 5px',
    },
  },
  programHeader: {
    marginLeft: 0,
    marginBottom: 10,
    marginTop: 10,
    lineHeight: '20px',
    fontWeight: 800,
    // borderBottom: '1px solid #eee',
    minHeight: 28,
    fontSize: '15px',
    padding: '0px 0px',
  },
  programDayExpanded: {
    backgroundColor: '#fafafa',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  programItemName: {
    fontSize: 15,
    fontWeight: 700,
    paddingBottom: 5,
    display: 'block',
  },
  programItemDescription: {
    fontSize: 13,
    fontWeight: 500,
    color: '#555',
  },
  programItemImage: {
    width: 250,
    height: 180,
    marginRight: 16,
    borderRadius: 15,

    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 150,
    },

    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
  programSummaryIcon: {
    width: 17,
    height: 16,
    fontSize: 10,
    fontWeight: 900,
    margin: 'auto 0px',
    backgroundColor: '#02c39a',
  },
  subHeader: {
    width: '100%',
    backgroundColor: '#fff',
    top: 78,
    borderBottom: '1px solid #f2f2f2',
    color: '#999',
    fontWeight: 700,
  },
  contentTourItem: {
    maxHeight: 64,
    padding: '10px 15px',

    '& > h6': {
      fontSize: 15,
      fontWeight: 700,
    },
  },
  divider: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    marginTop: 10,
  },
  skeleton: {
    borderRadius: 10,
  },
  skeletonFilters: {
    borderRadius: 10,
    margin: '10px 0px 10px 15px',
    float: 'left',
  },
  alertLocationMessage: {
    width: '100%',
  },
  columnRight: {
    float: 'right'
  },
  signupBanner: {
    textAlign: 'left',
    maxWidth: 1130,
    minHeight: 200,
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150%',
    backgroundPosition: '-5px -5px',
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    marginTop: 0,

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      backgroundSize: 'inherit',
    },
  },
  signupImage: {
    position: 'relative',
    // right: '-47px',
    // top: '-65px',
    display: 'block',
    // marginBottom: -125,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 250,
      marginLeft: 'auto',
      marginRight: 'auto',
      top: 10,
      right: 0,
      marginBottom: 0,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      top: 0,
      right: 0,
      marginBottom: 0,
      paddingBottom: 10,
    },
  },
  signupHeadline: {
    padding: '5px 20px 4px 40px',
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      padding: '5px 20px 4px 40px',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '5px 20px 4px 20px',
    },
  },
  signupIcon: {
    padding: '3px 8px 3px 0px !important',

    '& .material-icons': {
      verticalAlign: 'middle',
      color: '#02c39a',
      marginRight: 8,
      padding: '3px 8px'
    }
  },
  signupText: {
    padding: '3px 8px 3px 18px !important',
  },
  signupSubtitle: {
    fontWeight: 600,
    fontSize: '59% !important',
    // fontSize: '12px',
    color: 'rgba(130, 130, 130, 0.9)',
    margin:'0px',
    display: 'block',
    lineHeight: 2.2
  },
  sectionSubheader: {
    padding: '10px 20px',
    fontSize: 15,
    fontWeight: 500,
    color: '#888',
  },
  activityHeader: {
    marginBottom: '-66px',
    zIndex: 9,
    backgroundColor: 'transparent',
    width: 'calc(100% - 32px)',
    position: 'relative',
    padding: '14px 16px',
  },
  activityHeaderAction: {
    margin: '0px -3px 0px 0px',
  },
  favoriteIcon: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: '#25082f',
  },
  favoriteIconSelected: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: 'rgba(255, 107, 107, 0.9)',
  },
  favoriteIconSize: {
    fontSize: 20,
  },
  chipSelected: {
    backgroundColor: 'rgba(7, 195, 153, 0.26) !important',
    boxShadow: 'none !important',
    border: '2px solid rgba(7, 195, 153, 0.26) !important',
  },
  chipRoot: {
    padding: '20px 10px',
    borderRadius: 50,
    marginRight: 10,
    marginBottom: 10,
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    fontWeight: 700,
    fontSize: 12,
    border: '2px solid #f5f5f5',
    backgroundColor: '#fff',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  missingOppositionRoot: {
    '&:before': {
      display: 'none',
      flex: 0,
      padding: 0,
      minHeight: 0,
    }
  },
  itineraryRail: {
    '&::before': {
      position: 'absolute',
      zIndex: 0,
      left: 31,
      width: 2,
      content: '""',
      top: 30,
      height: '97%',
      background: '#f2f2f2',
    },
  },
  itineraryDay: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '18px',
    padding: '20px 20px',
    backgroundColor: '#f8f8f8',
    zIndex: 1,
    position: 'relative',
    display: 'inline-block',
    borderRadius: 50,
    margin: '20px 0px',
  },
})

export default withStyles(styles)(withRouter(withWidth()(Trip)));
